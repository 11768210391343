import {Link} from "react-router-dom";
import photo from './assets/images/404.png'

const NotFound = () => {
    return (
        <div style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '15px'
        }}>
            <img src={photo} alt=""/>
            <span style={{fontSize: '24px', fontWeight: '600', lineHeight: '36px'}}>404 Not Found</span>
            <span
                style={{fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: 'rgba(255,255,255,0.48'}}>Sorry, we can’t find that page.</span>
            <Link to={'/'} style={{
                background: '#33bf7f',
                color: 'black',
                borderRadius: '3000px',
                padding: '8px 16px',
                fontSize: '18px',
                fontWeight: '600'
            }}>
                Back to Home Page
            </Link>
        </div>
    )
}

export default NotFound;