// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-card {
    border-radius: 16px;
    background: #E3F5FF;
    color: rgb(var(--primary-bg-color));
    padding: 24px;
    width: 100%;
}

.projects-card > div:first-child {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
}

.projects-card__count {
    font-size: 24px;
    font-weight: 600;
}

.projects-card__progress {
    font-size: 12px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/scenes/dashboard/global/ProjectCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,mCAAmC;IACnC,aAAa;IACb,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".projects-card {\n    border-radius: 16px;\n    background: #E3F5FF;\n    color: rgb(var(--primary-bg-color));\n    padding: 24px;\n    width: 100%;\n}\n\n.projects-card > div:first-child {\n    margin-bottom: 8px;\n    font-size: 14px;\n    font-weight: 700;\n}\n\n.projects-card__count {\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.projects-card__progress {\n    font-size: 12px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
