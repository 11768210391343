import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ placeholder, initialValue, onUpdate }) => {
    const [editorHtml, setEditorHtml] = useState('');

    useEffect(() => {
        // Set the initial value when the component mounts
        setEditorHtml(initialValue);
    }, [initialValue]);

    const handleChange = (html) => {
        setEditorHtml(html);
        // Notify the parent component about the change
        onUpdate(html);
    };

    return (
        <div>
            <ReactQuill
                onChange={handleChange}
                value={editorHtml}
                modules={Editor.modules}
                formats={Editor.formats}
                bounds=".app"
                placeholder={placeholder}
            />
        </div>
    );
};

Editor.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
    },
};

Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
];

Editor.propTypes = {
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    onUpdate: PropTypes.func,
};

export default Editor;
