import React from 'react';
import '../assets/css/Modal.css'; // Assuming you have a corresponding CSS file for styling

const Modal = ({ isOpen, onClose, children, handleSubmit, isLoading }) => {
    if (!isOpen) {
        document.body.style.overflowY = "scroll"
        return null
    }
    document.body.style.overflow = "hidden"


    return (
        <div className="modal-overlay">
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                {children}
                <button className="modal-close-button" onClick={onClose}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 490 490"
                        fill="#aaa"
                    >
                        <path d="M456.851 0L245 212.564 33.149 0 .708 32.337l211.961 212.667L.708 457.678 33.149 490 245 277.443 456.851 490l32.441-32.322-211.961-212.674L489.292 32.337z"></path>
                    </svg>
                </button>
                <div style={{display: "flex", justifyContent: 'flex-end'}}>
                {isLoading ?
                    <span style={{ padding: '20px 20px 10px', fontSize: '18px', fontWeight: 700 }}>Saving...</span>
                    :
                    <button style={{maxWidth: '140px', margin: '10px 0 0 0'}} className="add-poll__button" onClick={handleSubmit}>Save</button>
                }
                </div>
            </div>
        </div>
    );
};

export default Modal;
