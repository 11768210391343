import {useEffect, useState} from "react";
import axios from 'axios'
import Select from "react-select";
//style
import './SignIn.css'
import './global/auth-global-style.css'
//images
import logo from '../../assets/images/sign-in-logo.svg'
import googleIcon from '../../assets/images/google-icon.svg'
import faceBook from '../../assets/images/facebook-icon.svg'
import {Container} from 'react-bootstrap';
import users from "../dashboard/Users";
import BASE_URL from "../../api/api";
import {useNavigate} from "react-router-dom";


const SignIn = () => {
    const [phone, setPhone] = useState('');
    const [preCode, setPreCode] = useState('');
    const [error, setError] = useState('');
    const [options, setOptions] = useState([])
    const navigate = useNavigate()
    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        valueContainer: (styles) => {
            return {
                ...styles,
                height: '100%',
                position: 'relative'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
            };
        },

    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            border: '1px solid #5f615d',
            boxShadow: '0 0 7px 1px black'
        }
    },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0'
            })
        },
        placeholder: (styles) => {
            return ({
                ...styles,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)'
            })
        },
        input: (styles) => {
            return ({
                ...styles,
color: 'white',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0',
                alignItems: 'flex-end',
                display: 'flex',
            })
        }
    }

    const handleEmailChange = (e) => {
        if (e.nativeEvent.data === 'e')
            setPhone(phone)
        else
            setPhone(e.target.value);
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.globalvalidity.app/auth/login/otp', {
                "phone": {
                    "country_id": preCode,
                    "number": phone
                },
                "type": "PHONE"
            }, {
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json"
                }
            });
            localStorage.clear()
            localStorage.setItem(`user-id`, response.data.data.id)
            navigate(`/verification/${response.data.data.id}&${phone.slice(-4)}`)
        } catch (error) {
            setError('Invalid email or password');
            console.error(error);
        }
    };

    useEffect(() => {
        axios.get(`${BASE_URL}/auth/countries`).then(res => {
            setOptions(res.data.data.map(item => {
                return {value: item.id, label: `${item.code} ${item.pre_code}`}
            }))
        })
    }, [])

    const preCodeHandler = (e) => {
        setPreCode(e.value)
    }
    return (
        <div className={`sign-in-container`}>
            <Container className={`d-flex align-items-center justify-content-end flex-column-reverse flex-lg-row`}>
                <div className={`sign-in__logo-container`}>
                    <img src={logo} className={`sing-in__logo`} alt=""/>
                </div>
                <form className={`auth-form`} onSubmit={handleSignIn}>
                    <h3>Sign In</h3>
                    <div className={`w-100 d-flex align-items-center justify-content-start gap-2 my-3`}>
                        <label className={`sing-in__phone-number-precode`}>
                            <Select placeholder={`Code`} className={`pre-code-select`} options={options}
                                    name="pre_code" onChange={preCodeHandler} styles={selectStyle}/>
                        </label>
                        <label className={`sing-in__phone-number`}>
                            <input type="number" placeholder="Phone Number" value={phone}
                                   onChange={(e) => handleEmailChange(e)}
                                   onKeyDown={(e) => {
                                       if (e.key === 'e') {
                                           e.preventDefault()
                                       }
                                   }}/>
                        </label>
                    </div>
                    <button type="submit">Sign In</button>
                </form>
            </Container>
        </div>
    )
}
export default SignIn;