import TopBar from "./global/TopBar";
//style
import './Verification.css'
//images
import mobileIcon from '../../assets/images/mobile-icon.svg'
import {useNavigate, useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";

const Verification = () => {
    const [digitValue1, setDigitValue1] = useState('')
    const [digitValue2, setDigitValue2] = useState('')
    const [digitValue3, setDigitValue3] = useState('')
    const [digitValue4, setDigitValue4] = useState('')
    const [digitValue5, setDigitValue5] = useState('')
    const [digitValue6, setDigitValue6] = useState('')
    const phone4 = useParams().verificationUrl.slice(-4)
    const otp_id = useParams().verificationUrl.slice(0, useParams().verificationUrl.length-5);
    const [enteredCode, setEnteredCode] = useState([])
    const navigate = useNavigate()

    const inputLengthController = (e) => {
        if (e.key === 'e') e.preventDefault()
    }

    useEffect(() => {
        setEnteredCode(digitValue1 + digitValue2 + digitValue3 + digitValue4 + digitValue5 + digitValue6)
    }, [digitValue1, digitValue2, digitValue3, digitValue4, digitValue5, digitValue6])

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${BASE_URL}/auth/login/otp/verify`, {
            "code": `${enteredCode}`,
            "otp_id": `${otp_id}`
        }, {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            localStorage.setItem(`user-token`, res.data.data.access_token)
            setTimeout(() => {
                navigate('/');
            }, 500)
        })
    }

    return (
        <>
            <main className={`auth-main`}>
                <form className={`auth-form verification-form`} action="">
                    <img src={mobileIcon} className={`mobile-icon`} alt=""/>
                    <h3>Two Step Verification</h3>
                    <span className={`color-grey`}>Enter the verification code we sent to</span>
                    <span className={`phone-number`}>******{phone4}</span>
                    <span className={`verification-desc`}>Type your 6 digit security code</span>
                    <div className={`verification-code-container`}>
                        <input value={digitValue1} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue1(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue1(prevState => {
                                           return digitValue1
                                       })
                                   } else {
                                       setDigitValue1(e.target.value)
                                       if (e.target.value !== "") {
                                           e.target.nextSibling.focus()
                                       }
                                   }
                               }}/>
                        <input value={digitValue2} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue2(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue2(prevState => {
                                           return digitValue2
                                       })
                                   } else {
                                       setDigitValue2(e.target.value)
                                       if (e.target.value !== "") {
                                           e.target.nextSibling.focus()
                                       }
                                   }
                               }}/>
                        <input value={digitValue3} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue3(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue3(prevState => {
                                           return digitValue3
                                       })
                                   } else {
                                       setDigitValue3(e.target.value)
                                       if (e.target.value !== "") {
                                           e.target.nextSibling.focus()
                                       }
                                   }
                               }}/>
                        <input value={digitValue4} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue4(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue4(prevState => {
                                           return digitValue4
                                       })
                                   } else {
                                       setDigitValue4(e.target.value)
                                       if (e.target.value !== "") {
                                           e.target.nextSibling.focus()
                                       }
                                   }
                               }}/>
                        <input value={digitValue5} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue5(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue5(prevState => {
                                           return digitValue5
                                       })
                                   } else {
                                       setDigitValue5(e.target.value)
                                       if (e.target.value !== "") {
                                           e.target.nextSibling.focus()
                                       }
                                   }
                               }}/>
                        <input value={digitValue6} type="number"
                               onKeyDown={inputLengthController}
                               onChange={(e) => {
                                   setDigitValue6(e.target.value)
                                   if (e.target.value.length > 1) {
                                       setDigitValue6(prevState => {
                                           return digitValue6
                                       })
                                   } else {
                                       setDigitValue6(e.target.value)
                                   }
                               }}/>
                    </div>
                    <button type={"submit"} onClick={(e) => submitHandler(e)}>Submit</button>
                    <div><span className={`color-grey`}>Didn’t get the code? </span>
                        <button className={`form-navigation-button`}>Resend</button>
                        <span className={`color-grey`}> or </span>
                        <button className={`form-navigation-button`}>Log in</button>
                    </div>
                </form>
            </main>
        </>
    )
}

export default Verification;
