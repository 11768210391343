import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useContext, useState} from "react";
import "./Poll.css";

import {useEffect} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import dragIcon from "../../assets/images/drag-icon.png"
import trashIcon from "../../assets/images/trash-icon.svg"
import ClickOutsideDetector from '../../components/ClickOutsideDetector'
import {UpdateContext} from "../../context";
import button from "bootstrap/js/src/button";
import Editor from "../../Editor";
import {Link} from "react-router-dom";


const Poll = () => {
    const {setUpdatePolls} = useContext(UpdateContext)
    const [birthdate, setBirthdate] = useState({value: null, label: null})
    const [exp, setExp] = useState({value: null, label: null})
    const [gender, setGender] = useState({value: null, label: null})
    const [jobId, setJobId] = useState({value: null, label: null})
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState({value: null, label: null})
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState('649b37ad7f7ed483f911b03f')
    const [title, setTitle] = useState([])
    const [reward, setReward] = useState([])
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState({value: null, label: null})
    const [cities, setCities] = useState([])
    const [cityId, setCityId] = useState({value: null, label: null})
    const [maritalStatus, setMaritalStatus] = useState({value: null, label: null})
    const [jobsList, setJobsList] = useState([])
    const [showQue, setShowQue] = useState(false)
    const [pollId, setPollId] = useState(undefined)
    const [activePoll, setActivePoll] = useState(false)
    const [closedPoll, setClosedPoll] = useState(false)
    const [formCounter, setFormCounter] = useState(0)
    const [showSubmitedsuccessfully, setShowSubmitedsuccessfully] = useState(false)
    const [showResponsesField, setShowResponsesField] = useState()
    const [langs, setLangs] = useState([])
    const [languages, setLanguages] = useState([])
    const [titleTranslations, setTitleTranslations] = useState([])
    const [degrees, setDegrees] = useState([])
    const [degree, setDegree] = useState([])
    const [focusedOnTitle, setFocusedOnTitle] = useState(false)
    const [jobsInput, setJobsInput] = useState('')

    // translations variables:
    const [haveTranslateToEn, setHaveTranslateToEn] = useState(false)


    // const initRate = {from: 0, to: 10, result: 'teste'}
    const [ratesData, setRatesData] = useState([])

    const addFieldHandler = () => {
        setRatesData([...ratesData, {from: 0, to: 0, result: ''}])
    }
    const initForm = (order) => ({
        order: order,
        question: '',
        translations: {},
        required: true,
        scoring_factor: '',
        type: '',
        inputs: [
            {inputValue: '', order: 1, rate: ``, inputId: `${order}-0`, inputType: '', inputTranslations: {}},
            {inputValue: '', order: 2, rate: ``, inputId: `${order}-1`, inputType: '', inputTranslations: {}}
        ],
        id: `form-${order}`
    });

    const handleEditorUpdate = (html, index) => {
        // Update the data array with the modified HTML
        const newData = [...ratesData];
        newData[index].result = html;
        setRatesData(newData);
    };

    const [forms, setForms] = useState([initForm(formCounter)]);
    let dateobj = new Date()
    let birthdateYear = []
    for (let i = 1900; i <= dateobj.getFullYear(); i++) {
        birthdateYear.push(i)
    }
    let expYears = []
    for (let i = 1; i <= 30; i++) {
        expYears.push({value: i, label: i})
    }
    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                marginTop: `15px`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        valueContainer: (styles) => {
            return {
                ...styles,
                height: '100%',
                position: 'relative'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
            };
        },

        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0'
            })
        },
        input: (styles) => {
            return ({
                ...styles,
                color: 'white',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0',
            })
        }
    }
    const deleteQue = (formIndex) => {
        setFormCounter(prevState => prevState - 1)
        const updatedForms = [...forms]
        updatedForms.splice(formIndex, 1);
        updatedForms.forEach((form, index) => {
            form.order = index
            form.id = `form-${index}`
            form.inputs.forEach((input, inputIndex) => {
                input.inputId = `${index}-${inputIndex}`
            })
        })
        setForms(updatedForms);
    }
    const deleteChoice = (formIndex, inputIndex) => {
        const updatedForms = [...forms]
        updatedForms[formIndex].inputs.splice(inputIndex, 1);
        updatedForms[formIndex].inputs.forEach((input, index) => {
            input.inputId = `${formIndex}-${index}`
        })
        setForms(updatedForms);
    }
    const handleJobsInputChange = (inputValue) => {
        setJobsInput(inputValue)
    }
    const requiredHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].required = e.target.checked;
        setForms(updatedForms);
    };
    const submitQueHandler = () => {
        forms.map(({order, question, required, scoring_factor, type, inputs, translations}) => {
            axios.post(`${BASE_URL}/admin/poll/${pollId}/question`, {
                order: order + 1,
                question,
                required,
                scoring_factor: +scoring_factor,
                type,
                translations
            }, {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
                langs.forEach((lang, index) => {
                    if (lang.id != '6533c65a7b8ccffa90a73450' && translations[lang.id] != undefined)
                        axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${res.data.data.poll_question.id}/translations`, {
                            "language_id": `${lang.id}`,
                            "question": translations[lang.id]
                        }, {
                            headers: {
                                "Accept": "*/*",
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            }
                        }).then(res => {
                            setShowSubmitedsuccessfully(true)
                            setTimeout(() => {
                                setShowSubmitedsuccessfully(false)
                            }, 2000)
                        }).catch(err => {
                            // console.log(err)
                        })
                })
                inputs.map(({inputValue, order, rate, inputType, inputTranslations}) => {
                    let queId = res.data.data.poll_question.id
                    axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${res.data.data.poll_question.id}/choice`, {
                        answer: inputValue,
                        order: +order,
                        rate: +rate,
                        type: inputType
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        langs.forEach((lang, index) => {
                            if (lang.id != '6533c65a7b8ccffa90a73450' && inputTranslations[lang.id] != undefined) {
                                console.log(lang.id)
                                axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${queId}/choice/${res.data.data.poll_question_choice.id}/translations`, {
                                    "choice": inputTranslations[lang.id],
                                    "language_id": `${lang.id}`
                                }, {
                                    headers: {
                                        "Accept": "*/*",
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                    }
                                }).then(res => console.log(res))
                            }
                        })
                        setShowSubmitedsuccessfully(true)
                        setTimeout(() => {
                            setShowSubmitedsuccessfully(false)
                        }, 2000)
                    }).catch(err => {
                        // console.log(err)
                    })
                })
            })


            console.log(inputs)

        })
        // axios.patch(`${BASE_URL}/admin/poll/${pollId}`, {
        //     "personality_scores": ratesData
        // }, {
        //     headers: {
        //         "Accept": "*/*",
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
        //     }
        // }).then(res => console.log(res))
        //     .catch(err => console.log(err))
    }
    const typeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].type = e.value;
        setForms(updatedForms);
    }
    const inputTypeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs.map(item => {
            item.inputType = e.value
        })

        setForms(updatedForms);
    }
    useEffect(() => {
        if (pollCategoryId.label === 'Personality Tests') {
            setShowResponsesField(true)
        } else {
            setShowResponsesField(false)
        }
    }, [pollCategoryId])
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/entities/languages`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            let languages = res.data.data.languages
            let temp = languages[0]
            languages[0] = languages[1]
            languages[1] = temp
            setLangs(res.data.data.languages)
            let langIdContainer = {}
            res.data.data.languages.forEach(item => {
                if (item.id !== '6533c65a7b8ccffa90a73450')
                    langIdContainer[item.id] = ''
            })
            // console.log(res.data.data.languages)
            // setTitleTranslations(langIdContainer)
        }).catch(err => {
            console.log(err)
        })
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`${BASE_URL}/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        }).catch(err => {
            // console.log(err)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })


        axios.get(`${BASE_URL}/entities/languages`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setLanguages(res.data.data.languages))

        axios.get(`${BASE_URL}/entities/degrees`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            let degrees = res.data.data.degrees.map(degree => ({
                value: degree.id, label: degree.title
            }))
            setDegrees(degrees)
        })


    }, [])
    useEffect(() => {
        if (countryId.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (stateId.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states/${stateId.value}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            })
        }
    }, [stateId])

    useEffect(() => {
        axios.get(`${BASE_URL}/entities/jobs?title=${jobsInput}&page=1&limit=500`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.data.data.jobs === null) {
                setJobsList([])
            } else {
                setJobsList(res.data.data.jobs)
            }
        })
    }, [jobsInput])
    const addPoll = (e) => {
        let criteria = {}

        if (birthdate.value || exp.value || gender.value || maritalStatus.value || cityId.value || stateId.value || countryId.value || jobId.value || degree.value) {

            if (birthdate.value) {
                criteria["birthdate_year"] = birthdate.value
            }

            if (exp.value) {
                criteria["experience"] = {
                    "over_30_years": (exp === 0),
                    "years": exp === undefined ? null : exp.value
                }
            }

            if (gender.value) {
                criteria["gender"] = gender.value
            }
            if (degree.value) {
                criteria["degree_id"] = degree.value
            }

            if (maritalStatus.value) {
                criteria["marital_status"] = maritalStatus.value
            }

            if (cityId.value || stateId.value || countryId.value) {
                criteria["location"] = {
                    "country_state_city_id": cityId === undefined ? null : cityId.value,
                    "country_state_id": stateId === undefined ? null : stateId.value,
                    "country_id": countryId === undefined ? null : countryId.value,
                }
            }

            if (jobId.value) {
                criteria["job_id"] = jobId.value
            }

        } else {
            criteria = {}
        }
        let data = {
            "active": activePoll,
            "closed": closedPoll,
            "criteria": criteria,
            "description": desc === undefined ? null : desc,
            "poll_category_id": pollCategoryId === '' ? null : pollCategoryId.value,
            "reward": reward === undefined ? 0 : +reward,
            "language_id": "6533c65a7b8ccffa90a73450",
            "title": title === undefined ? null : title,
        }
        axios.post('https://api.globalvalidity.app/admin/poll', data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                if (titleTranslations != []) {
                    langs.forEach(item => {
                        if (item.id != '6533c65a7b8ccffa90a73450' && titleTranslations[item.id] != undefined) {
                            axios.post(`${BASE_URL}/admin/poll/${res.data.data.poll.id}/translations`, {
                                "language_id": `${item.id}`,
                                "title": `${titleTranslations[item.id]}`
                            }, {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        }
                    })
                }
                setUpdatePolls(prev => !prev)
                setPollId(res.data.data.poll.id)
                setShowQue(true)
                setShowSubmitedsuccessfully(true)
                setTimeout(() => {
                    setShowSubmitedsuccessfully(false)
                }, 2000)
            }
        }).catch(err => {
            // console.log(err)
        })
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;


        const items = Array.from(forms);

        if (result.type === "forms") {
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            items.forEach((item, index) => {
                item.order = index
            })
            setForms(items);
        } else {
            const formIndex = result.destination.droppableId[result.destination.droppableId.length - 1];
            const copiedForm = {...forms[formIndex]};
            const copiedInputs = [...copiedForm.inputs];
            const [reorderedInput] = copiedInputs.splice(result.source.index, 1);
            copiedInputs.splice(result.destination.index, 0, reorderedInput);

            copiedForm.inputs = copiedInputs;

            items[formIndex] = copiedForm;

            setForms(items);
        }
    }

    const addPollHandler = () => {
        setForms([...forms, initForm(formCounter + 1)])
        setFormCounter(prevState => prevState + 1)
    }

    const addChoiceHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs = [...updatedForms[formIndex].inputs, {
            inputValue: ``,
            order: updatedForms[formIndex].inputs.length,
            rate: '',
            inputId: `${updatedForms[formIndex].order}-${updatedForms[formIndex].inputs.length + 1}`,
            inputType: updatedForms[formIndex].inputs[0].inputType,
            inputTranslations: {}
        }]
        setForms(updatedForms);
    }

    const formInputHandler = (e, formIndex, inputIndex, translate = false) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputValue = e.target.value;
        setForms(updatedForms);
    };
    const formInputTranslationHandler = (e, formIndex, inputIndex, lang = false) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputTranslations[lang.id] = e.target.value;
        setForms(updatedForms);
    };

    const formInputRateHandler = (e, formIndex, inputIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].rate = e.target.value;
        setForms(updatedForms);
    };
    const queHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].question = e.target.value;
        setForms(updatedForms);
    };
    const queTranslationHandler = (e, formIndex, lang) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].translations[lang.id] = e.target.value;
        setForms(updatedForms);
    };

    const scoringFactorHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].scoring_factor = e.target.value;
        setForms(updatedForms);
    }

    const titleTranslationHandler = (e, lang) => {
        let tempt = []
        tempt = {...titleTranslations}
        tempt[lang.id] = e.target.value
        setTitleTranslations(tempt)
    }

    return (
        <>
            <div className={`add-user d-block`}>
                <div>
                    <form onSubmit={e => e.preventDefault()}>
                        <div>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <span>{focusedOnTitle ? `فارسی:` : `Title:`}</span>
                            </div>
                            <label>
                                <input type="text" name={`title`} value={title}
                                       onChange={(e) => setTitle(e.target.value)}
                                       onFocus={() => setFocusedOnTitle(true)}
                                />
                            </label>
                            {focusedOnTitle ? langs.map(lang => {
                                if (lang.id !== "6533c65a7b8ccffa90a73450") {
                                    return (
                                        <label key={lang.id}>
                                            {lang.name}: <input value={titleTranslations[lang.id]} type="text"
                                                                onChange={(e) => {
                                                                    titleTranslationHandler(e, lang)
                                                                }}/>
                                        </label>
                                    )
                                }
                            }) : null}
                        </div>
                        <div>
                            Birthdate:
                            <Select isClearable={true}
                                    options={birthdateYear.map(item => ({value: item, label: item}))}
                                    styles={selectStyle}
                                    onChange={(e) => setBirthdate({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    value={birthdate}/>
                        </div>
                        <div>
                            Experience:
                            <Select styles={selectStyle} isClearable={true}
                                    onChange={(e) => setExp({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })} options={
                                [...expYears, {label: 'Over 30 years', value: 'over_30_years'}]
                            } value={exp}/>
                        </div>
                        <div>
                            Job:
                            <Select styles={selectStyle}
                                    onInputChange={handleJobsInputChange}
                                    onChange={(e) => setJobId({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    isClearable={true}
                                    options={
                                        jobsList.map((item) => {
                                            return ({
                                                value: item.id, label: item.title
                                            })
                                        })
                                    } value={jobId}/>

                        </div>
                        <div>
                            Gender:
                            <Select isClearable={true}
                                    options={[{label: 'Male', value: 'MALE'}, {
                                        label: 'Female',
                                        value: 'FEMALE'
                                    }]}
                                    onChange={e => setGender({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    value={gender} styles={selectStyle}/>
                        </div>
                        <div>
                            Marital Status: <Select isClearable={true}
                                                    options={[{label: 'Single', value: 'SINGLE'}, {
                                                        label: 'Married',
                                                        value: 'MARRIED'
                                                    }, {label: 'Divorced', value: 'DIVORCED'}, {
                                                        label: 'Widowed',
                                                        value: 'WIDOWED'
                                                    }]}
                                                    onChange={e => setMaritalStatus({
                                                        value: e === null ? e : e.value,
                                                        label: e === null ? e : e.label
                                                    })}
                                                    value={maritalStatus} styles={selectStyle}/>
                        </div>
                        <div style={{gridRow: 'span 3', gap: '12px', display: 'flex', flexDirection: 'column'}}>
                            <label>
                                Country:
                                <Select isClearable={true}
                                        onChange={(e) => setCountryId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={countries.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={countryId}/>
                            </label>
                            <label>
                                State:
                                <Select className={(states && states.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => setStateId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={states.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={stateId}/>
                            </label>
                            <label>
                                City:
                                <Select className={(cities && cities.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => setCityId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={cities.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={cityId}/>
                            </label>
                        </div>
                        {/*<label>*/}
                        {/*    Description:*/}
                        {/*    <textarea name="description" onChange={(e) => setDesc(e.target.value)} value={desc}/>*/}
                        {/*</label>*/}

                        <div>
                            Degree:
                            <Select isClearable={true}
                                    onChange={(e) => setDegree(e)}
                                    options={degrees}
                                    styles={selectStyle} value={degree}/>
                        </div>
                        <div>
                            Poll Category:
                            <Select isClearable={true}
                                    onChange={(e) => setPollCategoryID({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    options={pollCategories.map(item => ({
                                        value: item.id, label: item.name
                                    }))}
                                    styles={selectStyle} value={pollCategoryId}/>
                        </div>
                        <label>
                            Reward:
                            <input type="text" name={`reward`}
                                   onChange={(e) => setReward(parseFloat(e.target.value))}
                                   value={reward}/>
                        </label>
                        <div className={`d-flex`} style={{gridColumn: `1/3`}}>
                            <label className={`d-flex align-items-center justify-content-center flex-row`}>
                                <input type="checkbox" checked={activePoll} value={activePoll} onChange={(e) => {
                                    setActivePoll(prevState => !prevState)
                                }}
                                       style={{margin: 0, height: '13px', marginRight: '10px'}}/>
                                <span>Is Active</span>
                            </label>
                            <label className={`d-flex align-items-center justify-content-center flex-row`}>
                                <input type="checkbox" checked={closedPoll} value={closedPoll} onChange={(e) => {
                                    setClosedPoll(prevState => !prevState)
                                }}
                                       style={{margin: 0, height: '13px', marginRight: '10px', marginLeft: '20px'}}/>
                                <span>Is Closed</span>
                            </label>
                        </div>
                        <div style={{gridColumn: '1/3', display: 'flex', justifyContent: 'center'}}>
                            <button className={`add-poll__button`} type={`submit`} onClick={() => addPoll()}
                                    style={{width: `50%`,}}>
                                Add Poll
                            </button>
                        </div>
                    </form>

                </div>
            </div>


            <div className={showQue ? null : 'd-none'} style={{gridColumn: `1/13`}}>
                {pollId ?
                    <Link className={`add-poll__button`} style={{textAlign: 'center'}}
                          to={`/edit-poll/${pollId}`}>Manage Questions</Link>
                    : null}
            </div>
            <div style={{gridColumn: `1/13`}}>
            </div>
            <div className={`success-alert ${showSubmitedsuccessfully ? '' : 'hide'}`}>
                Poll Added Successfully!
            </div>
        </>
    )
        ;

};

export default Poll