import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import rejectIcon from '../../assets/images/reject-icon.png'
import editIcon from '../../assets/images/edit-icon.png'
import addIcon from '../../assets/images/add-icon.png'

const selectStyle = {
    container: (styles) => {
        return ({
            ...styles,
            marginBottom: `22px`,
            width: '100%'
        })
    },
    control: (styles) => {
        return ({
            ...styles,
            border: `1px solid #5f615d`,
            backgroundColor: `#2a2c29`,
            borderRadius: `8px`,
            color: `white`,
            padding: `2px 8px`,
            outline: `none`,
            boxShadow: `none`,
            marginTop: `15px`,
            height: `42px`,
            ':hover': {cursor: `pointer`}
        })
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            background: `#2c2e2b`,
            color: `white`,
            ':hover': {background: `#3e3f3c`, cursor: `pointer`},
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            border: '1px solid #5f615d',
            boxShadow: '0 0 7px 1px black'
        }
    },
    input: (styles) => {
        return {
            ...styles,
            color: 'white'
        }
    },
    menuList: (styles) => {
        return {
            ...styles,
            background: 'red',
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#4b4d48"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }
    },
    noOptionsMessage: (styles) => {
        return ({
            ...styles,
            background: `#2c2e2b`,
        })
    },
    singleValue: (styles) => {
        return ({
            ...styles,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            color: `white`,
        })
    }
}

const JobsSettings = () => {
    const [jobs, setJobs] = useState([])
    const [jobId, setJobId] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [title, setTitle] = useState('')
    const [showAddSuccess, setShowAddSuccess] = useState(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
    const [showEditeSuccess, setShowEditSuccess] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [updateJobs, setUpdateJobs] = useState(false)
    const [defaultValue, setDefaultValue] = useState()
    useEffect(() => {
        axios.get(`${BASE_URL}/entities/jobs`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setJobs(res.data.data.jobs)
        })
    }, [updateJobs])
    const submitJobHandler = () => {
        if (jobId && jobId.value) {
            axios.put(`${BASE_URL}/admin/job/${jobId.value}`, {
                title
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setJobId(prevState => ({value: prevState.value, label: title}))
                    setUpdateJobs(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/job`, {
                title
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDefaultValue({value: '', label: ''})
                    setUpdateJobs(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const deleteJobHandler = () => {
        if (jobId.value)
            axios.delete(`${BASE_URL}/admin/job/${jobId.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateJobs(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setJobId('')
                    setTimeout(() => {
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
    }

    const editJobHandler = () => {
        if (jobId.value)
            setShowForm(true)
    }

    const AddJobHandler = () => {
        setShowForm(true)
        setJobId(undefined)
    }

    return (
        <div className={'jobs-settings'}>
            <div className={`d-flex align-items-center`}>
                <Select options={jobs.map(({id, title}) => ({label: title, value: id}))} styles={selectStyle}
                        onChange={e => setJobId(e)} value={jobId}/>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editJobHandler} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={AddJobHandler}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
            </div>
            <div className={showForm ? '' : 'd-none'}>
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder={'Job Title'}/>
                <button onClick={submitJobHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <div className={`success-alert ${showAddSuccess ? '' : 'hide'}`}>
                Job Added Successfully!
            </div>
            <div className={`success-alert ${showDeleteSuccess ? '' : 'hide'}`}>
                Job Deleted Successfully!
            </div>
            <div className={`success-alert ${showEditeSuccess ? '' : 'hide'}`}>
                Job Edited Successfully!
            </div>
            <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={deleteJobHandler}>Yes</button>
                    <button onClick={() => setShowDeleteAlert(false)}>No</button>
                </div>
            </div>
        </div>
    )
}


export default JobsSettings;