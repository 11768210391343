import {Link, useNavigate} from "react-router-dom";
//style
import './Sidebar-left.css'
//images
import dropdownAngle from '../../../assets/images/dropdown-angle.svg'
import surveyIcon from '../../../assets/images/surveys.svg'
import userIcon from '../../../assets/images/user.svg'
import dashboardIcon from '../../../assets/images/dashboard.svg'
import settingIcon from '../../../assets/images/setting-icon.svg'
import logsIcon from '../../../assets/images/logs-icon.png'
import logOut from '../../../assets/images/logs-icon.png'
import noProfile from '../../../assets/images/profile-user-icon-white.png'
import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../../api/api";

const SidebarLeft = ({isOpen}) => {
    const navigate = useNavigate()
    const [fullName, setFullName] = useState('')
    const [avatar, setAvatar] = useState('')
    const [thisPage, setThisPage] = useState('')
    const avatarHandler = () => {
        navigate("user");
    }
    useEffect(() => {
        if (window.location.href.includes('users-documents')) {
            setThisPage('users-documents')
        } else if (window.location.href.includes('users-avatars')) {
            setThisPage('users-avatars')
        } else if (window.location.href.includes('users')) {
            setThisPage('users')
            if (window.location.href.includes('users-logs')) {
                setThisPage('users-logs')
            }
        } else if (window.location.href.includes('manage-surveys')) {
            setThisPage('manage-surveys')
        } else if (window.location.href.includes('add-poll')) {
            setThisPage('add-poll')
        } else if (window.location.href.includes('edit-poll')) {
            setThisPage('manage-surveys')
        } else if (window.location.href.includes('survey-categories')) {
            setThisPage('survey-categories')
        } else if (window.location.href.includes('polls-logs')) {
            setThisPage('polls-logs')
        } else if (window.location.href.includes('app-settings')) {
            setThisPage('app-settings')
        } else if (window.location.href.includes('locations-settings')) {
            setThisPage('locations-settings')
        } else if (window.location.href.includes('jobs-settings')) {
            setThisPage('jobs-settings')
        } else if (window.location.href.includes('educations-settings')) {
            setThisPage('educations-settings')
        } else if (window.location.href.includes('document-settings')) {
            setThisPage('document-settings')
        } else {
            setThisPage('')
        }
    }, [window.location.href])
    useEffect(() => {
        document.querySelectorAll('.side-bar--left__ul').forEach(item => {
            item.style.maxHeight = item.scrollHeight + 'px'
        })
    })
    const fetchUserProfile = async (userId) => {
        try {
            const response = await axios.get(
                `https://api.globalvalidity.app/entities/users/profiles/${userId}/photo`,
                {
                    responseType: "arraybuffer",
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            );

            // Convert the received binary data to a Data URL
            const blob = new Blob([response.data], {type: "image/png"});
            return URL.createObjectURL(blob);

        } catch (error) {
            return null;
        }
    };

    const dropdownHandler = (e) => {
        e.target.closest('.side-bar--left__dropdown-container').classList.toggle('closed')
    }
    useEffect(() => {
        // axios.get(`${BASE_URL}/user/profile/media/`)
        axios.get(`${BASE_URL}/user/profile`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                setFullName(res.data.data.profile.firstname + ' ' + res.data.data.profile.lastname)
                if (res.data.data.profile.photo_media_id)
                    fetchUserProfile(res.data.data.profile.user_id).then(res => {
                        setAvatar(res)
                    })
            }).catch(err => console.log(err))
    }, [])
    return (
        <aside className={`side-bar--left ${(isOpen) ? null : 'close'}`}>
            <div className={`avatar-container`} onClick={avatarHandler}>
                <img src={avatar || noProfile} alt="" style={{width: '30px', height: '30px', borderRadius: '50%'}}/>
                <span style={{width: `100%`, overflow: 'hidden', textOverflow: 'ellipsis'}}>{fullName}</span>
            </div>
            <div>
                <div>
                    <Link to={`/`} className={`side-bar--left__dropdown-button`}>
                        <img src={dropdownAngle} className='dropdown-angle' style={{opacity: `0`}}/>
                        <img src={dashboardIcon} alt=""/>
                        <span>Dashboard</span>
                    </Link>
                    {/*<span className={`sidebar--left__title`}>Users</span>*/}
                    <div className={`side-bar--left__dropdown-container`}>
                        <button className={`side-bar--left__dropdown-button`} onClick={dropdownHandler}>
                            <img src={dropdownAngle} className='dropdown-angle'/>
                            <img src={userIcon} alt=""/>
                            <span>Users</span>
                        </button>
                        <ul className={`side-bar--left__ul closed`}>
                            <li className={thisPage === `users` ? 'active' : null}><Link to={`users`}>Manage</Link></li>
                            <li className={thisPage === `users-avatars` ? 'active' : null}><Link
                                to={`users-avatars`}>Avatars</Link></li>
                            <li className={thisPage === `users-documents` ? 'active' : null}><Link
                                to={`users-documents`}>Documents</Link></li>
                        </ul>
                    </div>
                    <div className={`side-bar--left__dropdown-container`}>
                        {/*<span className={`sidebar--left__title`}>Dashbaords</span>*/}
                        <button className={`side-bar--left__dropdown-button`} onClick={dropdownHandler}>
                            <img src={dropdownAngle} className='dropdown-angle'/>
                            <img src={surveyIcon} alt=""/>
                            <span>Surveys</span>
                        </button>
                        <ul className={`side-bar--left__ul closed`}>
                            <li className={thisPage === `manage-surveys` ? 'active' : null}><Link
                                to={`manage-surveys`}>Manage</Link></li>
                            <li className={thisPage === `add-poll` ? 'active' : null}><Link to={`add-poll`}>Add</Link>
                            </li>
                            <li className={thisPage === `survey-categories` ? 'active' : null}><Link
                                to={`survey-categories`}>Categories</Link></li>
                            {/*<li><Link to={`edit-poll`}>Edit</Link></li>*/}

                            {/*<li><Link to={`add-poll`}>Add</Link></li>*/}
                        </ul>
                    </div>
                    <div className={`side-bar--left__dropdown-container`}>
                        {/*<span className={`sidebar--left__title`}>Dashbaords</span>*/}
                        <button className={`side-bar--left__dropdown-button`} onClick={dropdownHandler}>
                            <img src={dropdownAngle} className='dropdown-angle'/>
                            <img src={logsIcon} alt="" style={{width: `20px`, filter: `brightness(0) invert(1)`}}/>
                            <span>Logs</span>
                        </button>
                        <ul className={`side-bar--left__ul closed`}>
                            <li className={thisPage === `users-logs` ? 'active' : null}><Link to={`users-logs`}>Users
                                Logs</Link></li>
                            <li className={thisPage === `polls-logs` ? 'active' : null}><Link to={`polls-logs`}>Polls
                                Logs</Link></li>
                        </ul>
                    </div>
                    <div className={`side-bar--left__dropdown-container`}>
                        {/*<span className={`sidebar--left__title`}>Dashbaords</span>*/}
                        <button className={`side-bar--left__dropdown-button`} onClick={dropdownHandler}>
                            <img src={dropdownAngle} className='dropdown-angle'/>
                            <img src={settingIcon} alt="" style={{width: `20px`, filter: `brightness(0) invert(1)`}}/>
                            <span>Settings</span>
                        </button>
                        <ul className={`side-bar--left__ul closed`}>
                            <li className={thisPage === `app-settings` ? 'active' : null}><Link to={`app-settings`}>App
                                Settings</Link></li>
                            <li className={thisPage === `locations-settings` ? 'active' : null}><Link
                                to={`locations-settings`}>Locations</Link></li>
                            <li className={thisPage === `jobs-settings` ? 'active' : null}><Link
                                to={`jobs-settings`}>Jobs</Link></li>
                            <li className={thisPage === `educations-settings` ? 'active' : null}><Link
                                to={`educations-settings`}>Educations</Link></li>
                            <li className={thisPage === `document-settings` ? 'active' : null}><Link
                                to={`document-settings`}>Documents</Link></li>
                        </ul>
                    </div>
                    <Link to={`login`} className={`side-bar--left__dropdown-button`}
                          onClick={() => localStorage.clear()}>
                        <img src={dropdownAngle} className='dropdown-angle' style={{opacity: `0`}}/>
                        <img src={logOut} alt="" style={{width: `20px`, filter: `brightness(0) invert(1)`}}/>
                        <span>Log Out</span>
                    </Link>
                </div>
            </div>
        </aside>
    )
}
export default SidebarLeft;
