//hooks
import {useState, useEffect, useRef} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
//libraries
import axios from "axios";
import editIcon from '../../assets/images/edit-icon.png'
//styles
import './global/Table.css';

import acceptIcon from '../../assets/images/accept-icon.png'
import rejectIcon from '../../assets/images/reject-icon.png'
import angleLeft from "../../assets/images/angle-left.svg";
import angleRight from "../../assets/images/angle-right.svg";
import BASE_URL from "../../api/api";
import Select from "react-select";
import filterIcon from "../../assets/images/filter-icon.svg";
import searchIcon from "../../assets/images/search-icon.svg";

const PollsTable = ({children, className}) => {
    const [showFilter, setShowFilter] = useState(false)

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [checkedCounter, setCheckedCounter] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const history = useNavigate()
    const [birthdate, setBirthdate] = useState()
    const [exp, setExp] = useState()
    const [gender, setGender] = useState()
    const [jobId, setJobId] = useState()
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState([])
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState([])
    const [title, setTitle] = useState([])
    const [reward, setReward] = useState([])
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState([])
    const [cities, setCities] = useState([])
    const [cityId, setCityId] = useState([])
    const [maritalStatus, setMaritalStatus] = useState([])
    const [pages, setPages] = useState([])
    const [selectedPolls, setSelectedPolls] = useState()
    const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] = useState(false)
    const [updateToggler, setUpdateToggler] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [waitForReq, setWaitForReq] = useState(true)
    const [lastPage, setLastPage] = useState()

    const columnHelper = createColumnHelper();
    const navigate = useNavigate()

    const deletePoll = (pollId) => {
        axios.get(`${BASE_URL}/admin/poll/${pollId}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            // console.log(res)
        })
    }
    const deleteRowsHandler = () => {
        if (table.getRowModel().rows.length === table.getSelectedRowModel().rows.length) {
            setPage(prevState => prevState - 1)
        }
        table.getSelectedRowModel().rows.forEach(row => {
            axios.delete(`${BASE_URL}/admin/poll/${row.original.id}`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setWaitForReq(true)
                    setShowDeleteSuccessAlert(true)
                    setUpdateToggler(prev => !prev)
                    setShowDeleteAlert(false)
                    setTimeout(() => {
                        setShowDeleteSuccessAlert(false)
                        setWaitForReq(false)
                    }, 2000)
                }
            })
        })
    }

    //API request
    useEffect(() => {
        if (lastPage < page) {
            setPage(prevState => prevState - 1)
        }
        axios.get(`https://api.globalvalidity.app/admin/polls?page=${page}&limit=10`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                if (res.data.data.polls === undefined) setPage(prevState => prevState - 1)
                setData(res.data.data.polls)
                setWaitForReq(false)
                setWaitForReq(false)
            })
            .catch(error => {
                setPage(2)
                console.error("Error fetching data:", error);
            });
        axios.get(
            `https://api.globalvalidity.app/admin/polls?count_only=true`,
            {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }
        ).then(res => {
            table.resetRowSelection()
            let pagesLimit = Math.ceil((res.data.data.count / 10))
            setLastPage(pagesLimit)
            let pagesArray = []
            if (pagesLimit <= 5 || page <= 3) {
                for (let i = 1; i <= pagesLimit && i <= 5; i++) {
                    pagesArray.push(i)
                }
            } else {
                for (let i = 1; i <= pagesLimit && pagesArray.length < 5; i++) {
                    if (i >= pagesLimit.length - 5)
                        pagesArray = [pagesLimit - 4, pagesLimit - 3, pagesLimit - 2, pagesLimit - 1, pagesLimit]
                    if (((i >= page - 2) && i <= page + 2))
                        pagesArray.push(i)
                }
            }
            setPages(pagesArray)
        })
    }, [page, updateToggler]);

    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })
    }, [])
    useEffect(() => {
        if (typeof (countryId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (typeof (stateId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states/${stateId}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            })
        }
    }, [stateId])
    // select tables page
    const tablePageController = (selectedPage) => {
        setWaitForReq(true)
        if (selectedPage === `next`) setPage(prevState => prevState + 1)
        else if (selectedPage === `prev`) setPage(prevState => prevState - 1)
        else setPage(selectedPage)
    }

    function IndeterminateCheckbox({indeterminate, className = "", ...rest}) {
        const ref = useRef(null)

        useEffect(() => {
            if (typeof indeterminate === "boolean") {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
            setCheckedCounter(document.querySelectorAll(`td .cursor-pointer[checked]`).length)
        }, [ref, indeterminate])

        return (<input
            type="checkbox"
            ref={ref}
            className={className + " cursor-pointer"}
            {...rest}
        />)
    }

    const columns = [{
        id: 'select', header: ({table}) => (<IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler()
            }}
        />), cell: ({row}) => (<div className="px-1">
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler()
                }}
            />
        </div>)
    }, columnHelper.accessor("title", {
        header: "title",
    }), columnHelper.accessor("active", {
        header: "active",
        cell: ({row}) => {
            return <div className={`d-flex align-items-center justify-content-center`}>
                <img src={row.original.active ? acceptIcon : rejectIcon} style={{width: '20px'}}/>
            </div>
        }
    }),
        , columnHelper.accessor("description", {
            header: "description",
        }), columnHelper.accessor("reward", {
            header: "reward",
        }), columnHelper.accessor("closed", {
            header: "closed",
            cell: ({row}) => {
                return <div className={`d-flex align-items-center justify-content-center`}>
                    <img src={row.original.closed ? acceptIcon : rejectIcon} style={{width: '20px'}}/>
                </div>
            }
        }), columnHelper.accessor("delete", {
            header: "",
            cell: ({row}) => {
                return (
                    <button className={`d-flex align-items-center justify-content-center`}
                            onClick={() => {
                                navigate(`/edit-poll/${row.original.id}`)
                            }}>
                        <img src={editIcon} style={{width: '20px', filter: 'brightness(0) invert(1)'}} alt=""/>
                    </button>
                )
            }
        })];

    const table = useReactTable({
        data, columns, getCoreRowModel: getCoreRowModel(), enableRowSelection: true, getRowSelectionState: (row) => {
            return true;
        }
    });
    const addPoll = (e) => {
        let data = {
            "description": `${desc}`,
            "poll_category_id": `${pollCategoryId}`,
            "reward": +reward,
            "title": `${title}`,
            "criteria": {
                "birthdate": +birthdate,
                "experience_years": `${exp}`,
                "marital_status": `${maritalStatus}`,
                "location": {
                    "country_state_city_id": `${cityId}`,
                    "country_state_id": `${stateId}`,
                    "country_id": `${countryId}`,
                },
                "job_id": `${jobId}`,
                "gender": `${gender}`
            },
        }
        axios.post('https://api.globalvalidity.app/admin/poll', data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            // console.log(res)
        }).catch(err => {
            // console.log(err)
        })
    }
    const birthdateHandler = (e) => {

    }
    return (<div className={className}>
        <div className={`table-controllers`} style={{background: 'transparent'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {/*<button style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}*/}
                {/*        onClick={addUserHandler}><img src={plusIcon} alt=""/></button>*/}
                <div style={{position: 'relative'}}>
                    {/*<div className={`filters-container ${showFilter ? '' : 'd-none'}`}>*/}
                    {/*    <div></div>*/}
                    {/*    <button>Set Filters</button>*/}
                    {/*</div>*/}
                    {/*<button style={{display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0'}}*/}
                    {/*        onClick={() => setShowFilter(prevState => !prevState)}><img src={filterIcon}*/}
                    {/*                                                                    alt=""/>*/}
                    {/*</button>*/}
                </div>
                <span>
                                            {checkedCounter ? <div>{`${checkedCounter} selected`}
                                                <button className={`delete-table-item`} onClick={() => {
                                                    setShowDeleteAlert(true)
                                                }}>
                                                    Delete Selected
                                                </button>
                                            </div> : null}
                                                </span>
            </div>
        </div>
        <div style={{width: `100%`, overflowX: 'scroll'}} className={`scrollable-table`}>
            <table>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (<tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (<th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>))}
                </tr>))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => (<tr key={row.id} onClick={(e) => {
                    if (e.target.nodeName !== 'BUTTON')
                        row.toggleSelected()
                }}>
                    {row.getVisibleCells().map((cell) => (<td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>))}
                </tr>))}
                </tbody>
            </table>
        </div>
        <div className={`table-pagination`}>
            {page === 1 ? null :
                <button onClick={() => tablePageController(`prev`)}><img src={angleLeft} alt=""/>
                </button>
            }
            {pages.map((item, index) => {
                return <button key={index} className={page === item ? 'active' : null}
                               onClick={() => tablePageController(item)}>{item}</button>
            })}
            {page === pages[pages.length - 1] ? null :
                <button onClick={() => tablePageController(`next`)}><img src={angleRight} alt=""/></button>}
        </div>
        <div className={`add-user table__add-user-modal ${showModal ? 'show-modal' : null}`}>
            <div>
                <form>
                    birthdate:
                    <input type="number" name={`birthdate`}
                           onKeyDown={(e) => {
                               if (e.key === 'e') e.preventDefault()
                           }}
                           onChange={(e) => setBirthdate(e.target.value)}/>
                    exp years:
                    <select name="experience_years" onChange={(e) => setExp(e.target.value)}>
                        <option value="1-30">1-30</option>
                    </select>
                    <div>
                        gender:
                        <div className={`radio-buttons-container`}>
                            <label>
                                <input type="radio" value={`FEMALE`} name={`gender`}
                                       onClick={(e) => setGender(e.target.value)}/> Female
                            </label>
                            <label>
                                <input type="radio" value={`MALE`} name={`gender`}
                                       onClick={(e) => setGender(e.target.value)}/> Male
                            </label>
                        </div>
                    </div>
                    job id:
                    <input type="text" name={`job_id`} onChange={(e) => setJobId(e.target.value)}/>
                    country id:
                    <select name={`country_id`} onChange={(e) => setCountryId(e.target.value)}>
                        {countries.map((item, number) => {
                            return <option key={number} value={item.id}>{item.code}, {item.pre_code}</option>
                        })}
                    </select>
                    country state:
                    <select name={`country_state_id`} onChange={(e) => setStateId(e.target.value)}>
                        {states.map((item, number) => {
                            return <option key={number} value={item.id}>{item.name}</option>
                        })}
                    </select>
                    country state city:
                    <select name={`country_state_city_id`} onChange={(e) => setCityId(e.target.value)}>
                        {cities.map((item, number) => {
                            return <option key={number} value={item.id}>{item.name}</option>
                        })}
                    </select>
                    <div>
                        marital status:
                        <div className={`radio-buttons-container`}>
                            <label>
                                <input type="radio" value={`SINGLE`} name={`maritalStatus`}
                                       onClick={(e) => setMaritalStatus(e.target.value)}/> Single
                            </label>
                            <label>
                                <input type="radio" value={`MARRIED`} name={`maritalStatus`}
                                       onClick={(e) => setMaritalStatus(e.target.value)}/> Married
                            </label>
                        </div>
                    </div>
                    desc:
                    <textarea name="description" cols="30" rows="10" onChange={(e) => setDesc(e.target.value)}/>
                    poll id:
                    <select name={`poll_id`} onChange={(e) => setPollCategoryID(e.target.value)}>
                        {pollCategories.map((item, number) => {
                            return <option key={number} value={item.id}>{item.name}</option>
                        })}
                    </select>
                    reward:
                    <input type="text" name={`reward`} onChange={(e) => setReward(parseFloat(e.target.value))}/>
                    title:
                    <input type="text" name={`title`} onChange={(e) => setTitle(e.target.value)}/>
                    <span type={`submit`} onClick={() => addPoll()}>add poll</span>
                </form>
            </div>
        </div>
        <div className={`success-alert ${showDeleteSuccessAlert ? '' : 'hide'}`}>
            User Deleted Successfully!
        </div>
        <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
            <span>Are you sure?</span>
            <div className={`delete-alert__buttons-container`}>
                <button onClick={deleteRowsHandler}>Yes</button>
                <button onClick={() => setShowDeleteAlert(false)}>No</button>
            </div>
        </div>
        {waitForReq ? <div style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: '0',
            left: '0',
            background: 'rgba(0,0,0,0.3',
            backdropFilter: 'blur(2px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            wait...
        </div> : null}
    </div>);
};

export default PollsTable;
