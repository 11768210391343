import {useState} from "react";
import axios from "axios";
import button from "bootstrap/js/src/button";
import BASE_URL from "../../api/api";

const UserManagement = () => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [showMissMatch, setShowMissMatch] = useState(false)
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)

    const changePassHandler = () => {
        if (repeatedPassword === newPass) {
            axios.put(`${BASE_URL}/user/change-password`,
                {
                    'new_password': newPass,
                    'old_password': oldPass
                }, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                }).then(res => {
                setShowAlertSuccess(true)
                setTimeout(() => {
                    setShowAlertSuccess(false)
                }, 2000)
            })
        } else {
            setShowMissMatch(true)
            setTimeout(() => {
                setShowMissMatch(false)
            }, 2000)
        }
    }

    return (
        <div style={{gridColumn: '1/13'}}>
            <div className={`change-pass-container`}>
                <label>
                    Enter current password:
                    <input type="password" value={oldPass} onChange={e => setOldPass(e.target.value)}/>
                </label>
                <label>
                    Enter the new password:
                    <input type="password" value={newPass} onChange={e => setNewPass(e.target.value)}/>
                </label>
                <label>
                    Repeat the new password:
                    <input type="password" value={repeatedPassword}
                           onChange={e => setRepeatedPassword(e.target.value)}/>
                </label>
            </div>
            <button onClick={changePassHandler} className={'change-pass-button'}>Change Password</button>
            <div className={`success-alert ${showAlertSuccess ? '' : 'hide'}`} style={{width: '200px'}}>
                Done!
            </div>
            <div className={`miss-match-alert ${showMissMatch ? '' : 'hide'}`} style={{width: '200px'}}>
                Passwords Doesn't Match!
            </div>
        </div>
    )
}

export default UserManagement