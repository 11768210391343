import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import BASE_URL from "../../api/api";
import axios from "axios";

const EditUser = () => {
    const {userId} = useParams();


    return (
        <div className={`d-flex align-items-start`} style={{gridColumn: `1/13`}}>
            <div>

            </div>

        </div>
    );
};

export default EditUser;
