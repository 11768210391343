import {useState, useEffect} from "react";
import TopBar from "./global/TopBar";
import PasswordInput from "../../components/PasswordInput";

//style
import './global/auth-global-style.css'

//images
import googleIcon from "../../assets/images/google-icon.svg";
import faceBook from "../../assets/images/facebook-icon.svg";

const SignUn = () => {
    const [pwDiff, setPwDiff] = useState(true);
    const [termsAccept, setTermsAccept] = useState(false)
    useEffect(() => {
    }, [termsAccept, pwDiff]);
    return (
        <>
            <TopBar/>
            <main className={`auth-main`}>
                <form className={`auth-form`} action="">
                    <h3>Sign Up</h3>
                    <span className={`color-grey`}>Your Social Campaigns</span>
                    <div className={`sing-in-with`}>
                        <a href="#"><img src={googleIcon} alt=""/> Sign Up with Google</a>
                        <a href="#"><img src={faceBook} alt=""/>Sign Up with Facebook</a>
                    </div>
                    <div className={`hr-with-text`}><span>Or with Email</span></div>
                    <label>
                        <input type="email" placeholder={`Email`}/>
                    </label>
                    <PasswordInput setPwDiff={setPwDiff}/>

                    <label className="accept-terms">
                        <input type="checkbox" onChange={() => setTermsAccept(prevState => !prevState)}
                               checked={termsAccept} className={termsAccept ? 'checked' : ''}/>
                        <span>
                        <span className="color-grey">I Accept the </span><a href="#">Terms</a></span>
                    </label>

                    <button type="submit" className={((pwDiff === true) || (termsAccept === false)) ? 'disabled' : null}>Sign Up</button>
                    <div><span className={`color-grey`}>Already have an Account? </span>
                        <button className={`form-navigation-button`}>Sign In</button>
                    </div>
                </form>
            </main>
        </>
    )
}
export default SignUn;