//hooks
import {useState, useEffect, useRef} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
//libraries
import axios from "axios";

//styles
import './global/Table.css';
import SidebarLeft from "./global/Sidebar-left";
import SidebarRight from "./global/Sidebar-right";

const PollsTable = ({children, className}) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [checkedCounter, setCheckedCounter] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const history = useNavigate()
    const [birthdate, setBirthdate] = useState()
    const [exp, setExp] = useState()
    const [gender, setGender] = useState()
    const [jobId, setJobId] = useState()
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState([])
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState([])
    const [title, setTitle] = useState([])
    const [reward, setReward] = useState([])
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState([])
    const [cities, setCities] = useState([])
    const [cityId, setCityId] = useState([])
    const [maritalStatus, setMaritalStatus] = useState([])
    const columnHelper = createColumnHelper();

    //API request
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/admin/polls`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                setData(res.data.data.polls)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [page]);

    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })
    }, [])
    useEffect(() => {
        if (typeof (countryId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (typeof (stateId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states/${stateId}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            })
        }
    }, [stateId])
    // select tables page


    const addPoll = (e) => {
        let data = {
            "description": `${desc}`,
            "poll_category_id": `${pollCategoryId}`,
            "reward": +reward,
            "title": `${title}`,
            "criteria": {
                "birthdate": +birthdate,
                "experience_years": `${exp}`,
                "marital_status": `${maritalStatus}`,
                "location": {
                    "country_state_city_id": `${cityId}`,
                    "country_state_id": `${stateId}`,
                    "country_id": `${countryId}`,
                },
                "job_id": `${jobId}`,
                "gender": `${gender}`
            },
        }
        axios.post('https://api.globalvalidity.app/admin/poll', data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
        }).catch(err => {
            // console.log(err)
        })
    }
    return (
        <>
                <div className={`add-user d-block`}>
                    <div>
                        <form>
                            birthdate:
                            <input type="number" name={`birthdate`}
                                   onKeyDown={(e) => {
                                       if (e.key === 'e') e.preventDefault()
                                   }}
                                   onChange={(e) => setBirthdate(e.target.value)}/>
                            exp years:
                            <select name="experience_years" onChange={(e) => setExp(e.target.value)}>
                                <option value="1-30">1-30</option>
                            </select>
                            <div>
                                gender:
                                <div className={`radio-buttons-container`}>
                                    <label>
                                        <input type="radio" value={`FEMALE`} name={`gender`}
                                               onClick={(e) => setGender(e.target.value)}/> Female
                                    </label>
                                    <label>
                                        <input type="radio" value={`MALE`} name={`gender`}
                                               onClick={(e) => setGender(e.target.value)}/> Male
                                    </label>
                                </div>
                            </div>
                            job id:
                            <input type="text" name={`job_id`} onChange={(e) => setJobId(e.target.value)}/>
                            country id:
                            <select name={`country_id`} onChange={(e) => setCountryId(e.target.value)}>
                                {countries.map((item, number) => {
                                    return <option key={number} value={item.id}>{item.code}, {item.pre_code}</option>
                                })}
                            </select>
                            country state:
                            <select name={`country_state_id`} onChange={(e) => setStateId(e.target.value)}>
                                {states.map((item, number) => {
                                    return <option key={number} value={item.id}>{item.name}</option>
                                })}
                            </select>
                            country state city:
                            <select name={`country_state_city_id`} onChange={(e) => setCityId(e.target.value)}>
                                {cities.map((item, number) => {
                                    return <option key={number} value={item.id}>{item.name}</option>
                                })}
                            </select>
                            <div>
                                marital status:
                                <div className={`radio-buttons-container`}>
                                    <label>
                                        <input type="radio" value={`SINGLE`} name={`maritalStatus`}
                                               onClick={(e) => setMaritalStatus(e.target.value)}/> Single
                                    </label>
                                    <label>
                                        <input type="radio" value={`MARRIED`} name={`maritalStatus`}
                                               onClick={(e) => setMaritalStatus(e.target.value)}/> Married
                                    </label>
                                </div>
                            </div>
                            desc:
                            <textarea name="description" cols="30" rows="10" onChange={(e) => setDesc(e.target.value)}/>
                            poll id:
                            <select name={`poll_id`} onChange={(e) => setPollCategoryID(e.target.value)}>
                                {pollCategories.map((item, number) => {
                                    return <option key={number} value={item.id}>{item.name}</option>
                                })}
                            </select>
                            reward:
                            <input type="text" name={`reward`} onChange={(e) => setReward(parseFloat(e.target.value))}/>
                            title:
                            <input type="text" name={`title`} onChange={(e) => setTitle(e.target.value)}/>
                            <span type={`submit`} onClick={() => addPoll()}>add poll</span>
                        </form>
                    </div>
                </div>
        </>
    );
};

export default PollsTable;