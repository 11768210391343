import React from 'react';
import rejectIcon from '../assets/images/reject-icon.png'

const ImageLightbox = ({lightBoxImg, setShowImgPopup}) => {
    return (
        <div className={'img-verification-popup'} style={{
            maxWidth: '90vw',
            maxHeight: '90vh',
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            marginBottom: '0'
        }}>
            <img src={lightBoxImg.imageBlob} alt=""/>
            <span style={{
                display: 'flex',
                height: '40px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>{lightBoxImg.doc_type}</span>
            <button onClick={() => setShowImgPopup(false)} style={{position: 'absolute', right: '0', top: '0'}}><img
                src={rejectIcon} alt=""/></button>
        </div>
    );
};

export default ImageLightbox;