//hooks
import {useEffect, useRef, useState, useContext} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";

//libraries
import axios from "axios";
//images
import acceptIcon from '../../../assets/images/accept-icon.png'
import rejectIcon from '../../../assets/images/reject-icon.png'
import editIcon from '../../../assets/images/edit-icon.png'
import defalutProfile from '../../../assets/images/profile-user-icon.png'
import angleLeft from '../../../assets/images/angle-left.svg'
import angleRight from '../../../assets/images/angle-right.svg'
import filterIcon from '../../../assets/images/filter-icon.svg'
import searchIcon from '../../../assets/images/search-icon.svg'
import noProfile from '../../../assets/images/profile-user-icon-white.png'
import usernameBadge from '../../../assets/images/uer-badge.png'
//styles
import './Table.css';
import BASE_URL from "../../../api/api";
import Select from "react-select";
import {UpdateContext} from "../../../context";

const Table = ({children, className = ''}) => {
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)
    const [showMessageAlert, setShowMessageAlert] = useState(false)
    const {setUpdateRightSide} = useContext(UpdateContext);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [checkedCounter, setCheckedCounter] = useState(0)
    const columnHelper = createColumnHelper();
    const [showModal, setShowModal] = useState(false)
    const [scrollable, setScrollable] = useState(false)
    const [showRejectPopUp, setShowRejectPopUp] = useState(false)
    const [editableUserId, setEditableUserId] = useState('')
    const [showEditPopup, setShowEditPopup] = useState('')
    const [userProfile, setUserProfile] = useState([]);
    const [job, setJob] = useState(null)
    const [reason, setReason] = useState('')
    const [userLocation, setUserLocation] = useState([])
    const [photoUrl, setPhotoUrl] = useState('');
    const [showFilter, setShowFilter] = useState(false)
    const [showUserCertificates, setShowUserCertificates] = useState(false)
    const [showUserEducation, setShowUserEducation] = useState(false)
    const [education, setEducation] = useState(null)
    const [pages, setPages] = useState([])
    const [rejectUser, setRejectUser] = useState(false)
    const [zoomedImg, setZoomedImg] = useState(0)
    const zoomedImgRef = useRef(null)
    const [birthdate, setBirthDate] = useState('')
    const [experience, setExperience] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [gender, setGender] = useState('')
    const [isVerified, setIsVerified] = useState('')
    const [jobId, setJobId] = useState('')
    const [country, setCountry] = useState()
    const [stateId, setStateId] = useState()
    const [cityId, setCityId] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [type, setType] = useState('')
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [jobsList, setJobsList] = useState([])
    const [certificates, setCertificates] = useState(false)
    const [firstNameFilter, setFirstNameFilter] = useState('')
    const [lastNameFilter, setLastNameFilter] = useState('')
    const [countryFilter, setCountryFilter] = useState('')
    const [filterStates, setFilterStates] = useState([])
    const [filterState, setFilterState] = useState('')
    const [filterCities, setFilterCities] = useState([])
    const [filterCity, setFilterCity] = useState('')
    const [filterExp, setFilterExp] = useState('')
    const [filterGender, setFilterGender] = useState('')
    const [filterJob, setFilterJob] = useState('')
    const [rateFrom, setRateFrom] = useState('')
    const [rateTo, setRateTo] = useState('')
    const [editLoading, setEditLoading] = useState(true)
    const [userIDEdit, setUserIdEdit] = useState('')
    const [registeredAtEdit, setRegisteredAtEdit] = useState('')
    const [fullName, setFullName] = useState('')
    const navigate = useNavigate()
    const [showEdit, setShowEdit] = useState(false)
    const [docsLoading, setDocsLoading] = useState(true)
    const [showUserDocs, setShowUserDocs] = useState(false)
    const [docs, setDocs] = useState(null)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] = useState(false)
    const [waitForReq, setWaitForReq] = useState(false)
    const [certificatesLoading, setCertificatesLoading] = useState(true)
    const [educationsLoading, setEducationsLoading] = useState(true)
    const [updateTableToggler, setUpdateTableToggler] = useState(true)
    const [width100, setWidth100] = useState(true)
    const [showImg, setShowImg] = useState(false)
    const editDivRef = useRef(null)
    const rowRef = useRef(null)
    let expYears = []
    for (let i = 1; i <= 30; i++) {
        expYears.push({value: i, label: i})
    }
    let dateobj = new Date()

    let birthdateYear = []
    for (let i = 1900; i <= dateobj.getFullYear(); i++) {
        birthdateYear.push(i)
    }
    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
                width: '210px'
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                marginTop: `15px`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                height: `100%`,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
            })
        }
    }
    const editSelectStyles = {
        container: (styles) => {
            return ({
                ...styles,
                width: '210px'
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                height: `100%`,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
            })
        }
    }
    const zoomImg = (e) => {
        let element = zoomedImgRef.current
        setZoomedImg(e.target.src)
        // if(element)
        setTimeout(() => {
            if (zoomedImgRef.current.clientHeight > zoomedImgRef.current.parentElement.clientHeight) {
                setWidth100(false)
            }
        }, 100)
        setTimeout(() => {
            setShowImg(true)
        }, 200)
    }
    const showEditSuccess = () => {
        setShowEdit(true)
        setTimeout(function () {
            setShowEdit(false)
        }, 2000)
    }
    const deleteRowsHandler = () => {
        table.getSelectedRowModel().rows.forEach(row => {
            axios.delete(`${BASE_URL}/admin/users/${row.original.user_id}`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowDeleteAlert(false)
                    setUpdateTableToggler(prevState => !prevState)
                    showDeleteSuccess()
                }
            })
        })
    }
    const showDeleteSuccess = () => {
        setShowDeleteSuccessAlert(true)
        setTimeout(() => {
            setShowDeleteSuccessAlert(false)
        }, 1000)
    }
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
    }, [])
    useEffect(() => {
        if (typeof (country) === 'object') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${country.value}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }

    }, [country])
    useEffect(() => {
        if (countryFilter.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryFilter.value}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setFilterStates(res.data.data.country_states)
            })
        }

    }, [countryFilter])
    useEffect(() => {
        if (country && stateId) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${country.value}/states/${stateId.value}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) {
                    setCities(res.data.data.country_state_cities)
                }
            })
        } else {
            setCities([])
        }
    }, [stateId])
    useEffect(() => {
        if (filterState.value && countryFilter.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryFilter.value}/states/${filterState.value}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) {
                    setFilterCities(res.data.data.country_state_cities)
                }
            })
        } else {
            setFilterCities([])
        }
    }, [filterState])
    useEffect(() => {
        axios.get(`${BASE_URL}/entities/jobs`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setJobsList(res.data.data.jobs)
        }).catch(err => console.log(err))
    }, [])

    const fetchUserProfile = async (userId) => {
        try {
            const response = await axios.get(
                `https://api.globalvalidity.app/entities/users/profiles/${userId}/photo`,
                {
                    responseType: "arraybuffer",
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            );

            const blob = new Blob([response.data], {type: "image/png"});
            const dataURL = URL.createObjectURL(blob);
            return dataURL;

        } catch (error) {
            // console.log(error)
            return null;
        }
    };

    const fetchCertificateMedia = async (userId, mediaId) => {
        try {
            const response = await axios.get(
                `https://api.globalvalidity.app/admin/users/${userId}/media/${mediaId}`,
                {
                    responseType: "arraybuffer",
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            );
            if (response) setCertificatesLoading(false)
            const blob = new Blob([response.data], {type: "image/png"});
            const dataURL = URL.createObjectURL(blob);
            return dataURL;

        } catch (error) {
            // console.log(error)
            return null;
        }
    };
    const fetchJobTitle = async (jobId) => {
        if (jobId) {
            const response = await axios.get(
                `https://api.globalvalidity.app/entities/jobs/${jobId}`,
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            );
            return response.data.data.job.title
        } else {
            return 'N/A'
        }
    };
    const fetchCountry = async (country, stateId, cityId) => {
        let location
        if (country) {
            try {
                const response = await axios.get(
                    `https://api.globalvalidity.app/entities/countries/${country}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );
                location = response.data.data.country.name
            } catch (err) {
            }

        }
        if (stateId) {
            try {
                const response = await axios.get(
                    `https://api.globalvalidity.app/entities/countries/${country}/states/${stateId}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );
                location += `, ${response.data.data.country_state.name}`;
            } catch (err) {
            }
        }
        if (cityId) {
            try {
                const response = await axios.get(
                    `https://api.globalvalidity.app/entities/countries/${country}/states/${stateId}/cities/${cityId}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );
                location += `, ${response.data.data.country_state_city.name}`;
            } catch (err) {
            }
        }
        return location
    };
    const acceptButton = ({row}) => {
        setWaitForReq(true)
        axios.patch(`${BASE_URL}/admin/users/${row.original.user_id}/profile`, {
            is_verified: true,
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.status === 200) {
                showEditSuccess()
                setUpdateRightSide(prev => !prev)
                setUpdateTableToggler(prevState => !prevState)
                setWaitForReq(false)
            }
        })
    }
    const rejectButton = (userId) => {
        setWaitForReq(true)
        axios.patch(`${BASE_URL}/admin/users/${userId}/profile`, {
            is_verified: false,
        }, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.status === 200) {
                showEditSuccess()
                setUpdateRightSide(prev => !prev)
                setUpdateTableToggler(prevState => !prevState)
                setWaitForReq(false)
            }
        })
    }

    const showUserCertificatesHandler = (userId) => {
        setShowUserCertificates(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://api.globalvalidity.app/admin/users/profiles/certificates?user_id=${userId}&page=1&limit=10`,
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`
            }
        };
        axios.request(config)
            .then(async (response) => {
                try {
                    const certDataContainer = response.data.data.user_profile_certificates;
                    if (certDataContainer && certDataContainer.length) {

                        // Create an array of promises to fetch images for each certificate
                        const certificatePromises = certDataContainer.map(async (item) => {
                            try {
                                const blob = await fetchCertificateMedia(item.user_id, item.user_profile_media_id);
                                // Add the image blob to the certificate data
                                item.imageBlob = blob;
                                return item;
                            } catch (error) {
                                console.error(error);
                                return null;
                            }
                        });

                        // Wait for all promises to resolve
                        const certificateData = await Promise.all(certificatePromises);

                        // Set the certificates after all data is fetched
                        setCertificates(certificateData);
                    }
                } catch (error) {
                    console.error(error);
                }
                if (response.data.data.user_profile_certificates === null)
                    setCertificatesLoading(false)
            })
            .catch((error) => {
                // console.log(error);
            });

    }
    const showUserEducationHandler = (userId) => {
        setShowUserEducation(true);

        axios.request({
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://api.globalvalidity.app/admin/users/profiles/educations?user_id=${userId}&page=1&limit=10`,
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        })
            .then((response) => {
                let newData = response.data.data.user_profile_educations;
                if (newData === null)
                    setEducationsLoading(false)
                let fieldOfStudyRequests = [];
                let degreeRequests = [];

                newData.forEach((item) => {
                    const fieldOfStudyRequest = axios.get(`${BASE_URL}/entities/fields-of-study/${item.field_of_study_id}`, {
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    });

                    fieldOfStudyRequests.push(fieldOfStudyRequest);

                    const degreeRequest = axios.get(`${BASE_URL}/entities/degrees/${item.degree_id}`, {
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    });

                    degreeRequests.push(degreeRequest);
                });

                // Wait for all field of study requests to complete
                Promise.all(fieldOfStudyRequests)
                    .then(responses => {
                        responses.forEach((res, index) => {
                            newData[index].field_of_study = res.data.data.field_of_study.title;
                        });

                        // Set the education state with updated newData
                        setEducation(newData);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                // Wait for all degree requests to complete
                Promise.all(degreeRequests)
                    .then(responses => {
                        responses.forEach((res, index) => {
                            newData[index].degree = res.data.data.degree.title;
                        });

                        // Set the education state with updated newData
                        setEducation(newData);
                        setEducationsLoading(false)

                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                // console.log(error);
            });
    }
    const showUserDocsHandler = (userId) => {
        setShowUserDocs(true);

        axios.get(`${BASE_URL}/admin/identity-documents?user_id=${userId}&page=1&limit=10`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(async (res) => { // Make the function async here
            let newData = res.data.data.identity_documents;
            let docTypeReqs = [];
            if (newData === null)
                setDocsLoading(false)

            // Use a for loop to properly use 'await'
            if (newData && newData.length) {
                for (let i = 0; i < newData.length; i++) {
                    const item = newData[i];

                    const docTypeReq = axios.get(`${BASE_URL}/entities/document-types/${item.document_type_id}`, {
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    });

                    docTypeReqs.push(docTypeReq);

                    try {
                        const blob = await fetchCertificateMedia(item.user_id, item.user_profile_media_id);
                        item.imageBlob = blob;
                    } catch (err) {
                        // console.log(err);
                    }
                }
            }

            // Use Promise.all for docTypeReqs
            try {
                const responses = await Promise.all(docTypeReqs);
                responses.forEach((item, index) => {
                    newData[index].doc_type = item.data.data.document_type.name;
                });

                // Set the education state with updated newData
                setDocs(newData);
                setDocsLoading(false)
            } catch (error) {
                console.error(error);
            }
        });
    };
    //API request
    useEffect(() => {
        const fetchData = async () => {
            let filters = ''
            if (fullName !== '') filters = filters + 'full_name=' + fullName + '&'
            if (firstNameFilter !== '') filters = filters + 'firstname=' + firstNameFilter + '&'
            if (lastNameFilter !== '') filters = filters + 'lastname=' + lastNameFilter + '&'
            if (countryFilter !== '') filters = filters + 'country_id=' + countryFilter.value + '&'
            if (filterState !== '') filters = filters + 'country_state_id=' + filterState.value + '&'
            if (filterCity !== '') filters = filters + 'country_state_city_id=' + filterCity.value + '&'
            if (filterExp !== '') {
                if (filterExp.value === 0)
                    filters = filters + 'experience_over_30_years=true' + '&'
                else {
                    filters = filters + 'experience_years=' + filterExp.value + '&'

                }
            }
            if (filterGender !== '') filters = filters + 'gender=' + filterGender.value + '&'
            if (filterJob !== '') filters = filters + 'job_id=' + filterJob.value + '&'
            if (rateFrom !== '') filters = filters + 'rate_from=' + rateFrom + '&'
            if (rateTo !== '') filters = filters + 'rate_to=' + rateTo + '&'
            try {
                const response = await axios.get(
                    `https://api.globalvalidity.app/admin/users/profiles?${filters}page=${page}&limit=10`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );


                const count = await axios.get(
                    `https://api.globalvalidity.app/admin/users/profiles?${filters}&count_only=true&page=${page}&limit=10`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );

                // let pagesLimit = 5
                table.resetRowSelection()
                let pagesLimit = Math.ceil((count.data.data.count / 10))
                let pagesArray = []
                if (pagesLimit <= 5 || page <= 3) {
                    for (let i = 1; i <= pagesLimit && i <= 5; i++) {
                        pagesArray.push(i)
                    }
                } else {
                    for (let i = 1; i <= pagesLimit && pagesArray.length < 5; i++) {
                        if (i >= pagesLimit.length - 5)
                            pagesArray = [pagesLimit - 4, pagesLimit - 3, pagesLimit - 2, pagesLimit - 1, pagesLimit]
                        if (((i >= page - 2) && i <= page + 2))
                            pagesArray.push(i)
                    }
                }
                setPages(pagesArray)

                // Fetch job titles for all rows at once
                if (response.data.data.user_profiles) {
                    const jobTitlesPromises = response.data.data.user_profiles.map((user) =>
                        fetchJobTitle(user.job_id)
                    );
                    // Fetch county for all rows at once
                    const countryPromises = response.data.data.user_profiles.map((user) => {
                        if (user.location && user.location.country_id && user.location.country_state_id && user.location.country_state_city_id) {
                            return fetchCountry(user.location.country_id, user.location.country_state_id, user.location.country_state_city_id);
                        } else if (user.location && user.location.country_id && user.location.country_state_id) {
                            return fetchCountry(user.location.country_id, user.location.country_state_id);
                        } else if (user.location && user.location.country_id) {
                            return fetchCountry(user.location.country_id);
                        } else {
                            return 'N/A';
                        }
                    });
                    const jobTitles = await Promise.all(jobTitlesPromises);
                    const country = await Promise.all(countryPromises);
                    const usersWithProfilesAndJobs = await Promise.all(
                        response.data.data.user_profiles.map(async (user, index) => {
                            let userProfile
                            if (user.photo_media_id)
                                userProfile = await fetchUserProfile(user.user_id);
                            return {
                                ...user,
                                profile: userProfile,
                                jobTitle: jobTitles[index],
                                country: country[index]
                            };

                        })
                    );
                    // For each user, fetch their profile and combine it with the main data

                    setData(usersWithProfilesAndJobs);
                    setWaitForReq(false)
                } else {
                    setData([]);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [page, updateTableToggler, firstNameFilter, fullName, lastNameFilter, countryFilter, filterState, filterCity, filterExp, filterGender, filterJob, rateFrom, rateTo]);

    const submitUserChanges = (e) => {
        e.target.classList.add('waiting')
        let exp = experience.value === 'over_30_years' ? {
            "over_30_years": true,
            "years": 0
        } : {
            "over_30_years": false,
            "years": experience.value
        }
        let location = () => {
            let res = {"country_id": country.value}
            if (stateId.value) res = {
                "country_id": country.value, "country_state_id": stateId.value,
            }
            if (cityId.value) res = {
                "country_id": country.value,
                "country_state_city_id": cityId.value,
                "country_state_id": stateId.value
            }
            return res
        }

        let data = {
            "birthdate": `${birthdate}T00:00:00Z`,
            "experience": exp,
            "firstname": firstname,
            "gender": gender.value,
            "is_verified": isVerified,
            "job_id": jobId.value,
            "lastname": lastname,
            "location": location(),
            "marital_status": maritalStatus.value,
        }
        axios.patch(`${BASE_URL}/admin/users/${editableUserId}/profile`, data, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.status === 200) {
                setUpdateRightSide(prev => !prev)
                e.target.classList.remove('waiting')
                editDivRef.current.scrollTo(0, 0)
                setShowEditPopup(false)
                setEditLoading(true)
                setEditableUserId('')
                showEditSuccess()
                setUpdateTableToggler(prev => !prev)
            }
        }).catch(err => {
            e.target.classList.remove('waiting')
        })
    }

    const checkScrollable = () => {
        if (document.querySelector('.manage-surveys-table-container table'))
            document.querySelector('.manage-surveys-table-container table').clientWidth > document.querySelector('.manage-surveys-table-container').clientWidth ? setScrollable(true) : setScrollable(false)
    }
    useEffect(checkScrollable, [data])
    useEffect(() => {
        window.addEventListener('resize', checkScrollable);

        // Remove the event listener when the component unmounts to prevent memory leaks
        return () => {
            window.removeEventListener('resize', checkScrollable);
        };
    }, []);    //go to users page

    const editUser = (userId) => {
        setShowEditPopup(true)
        setEditableUserId(userId)
        const fetchUserProfile = async (loading) => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/entities/users/profiles/${userId}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );
                const userProfileData = response.data.data.user_profile;
                setUserIdEdit(userProfileData.username)
                setRegisteredAtEdit(userProfileData.registered_at)
                // RegisteredAtEdit()
                let job
                if (userProfileData.job_id !== null) {
                    job = await axios.get(
                        `https://api.globalvalidity.app/entities/jobs/${userProfileData.job_id}`,
                        {
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    );
                    setJob(job.data.data.job.title)
                }
                let country
                if (userProfileData.location !== null) {

                    if (userProfileData.location.country_id === null)
                        setCountry('')
                    else
                        country = await axios.get(
                            `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}`,
                            {
                                headers: {
                                    accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                },
                            }
                        )
                    setCountry({label: country.data.data.country.name, value: country.data.data.country.id})
                    let state
                    try {
                        if (userProfileData.location.country_state_id === null)
                            setStateId('')
                        else
                            state = await axios.get(
                                `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}/states/${userProfileData.location.country_state_id}`,
                                {
                                    headers: {
                                        accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                    },
                                }
                            )
                        setStateId({
                            label: state.data.data.country_state.name,
                            value: state.data.data.country_state.id
                        })
                    } catch (err) {
                        // console.log(err)
                    }
                    let city
                    try {
                        if (userProfileData.location.country_state_city_id === null)
                            setCityId('')
                        else
                            city = await axios.get(
                                `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}/states/${userProfileData.location.country_state_id}/cities/${userProfileData.location.country_state_city_id}`,
                                {
                                    headers: {
                                        accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                    },
                                }
                            )
                        setCityId({
                            label: city.data.data.country_state_city.name,
                            value: city.data.data.country_state_city.id
                        })

                    } catch (err) {
                        // console.log(err)
                    }
                } else {
                    setCountry('')
                    setStateId('')
                    setCityId('')
                }
                setUserProfile(userProfileData)
                if (userProfileData.birthdate) {
                    setBirthDate(userProfileData.birthdate.slice(0, 10))
                }
                if (userProfileData.experience) {
                    setExperience(userProfileData.experience.over_30_years ?
                        {label: 'Over 30 years', value: 'over_30_years'}
                        : {label: userProfileData.experience.years, value: userProfileData.experience.years}
                    )
                } else {
                    setExperience('')
                }
                setFirstname(userProfileData.firstname)
                setLastname(userProfileData.lastname)
                if (userProfileData.gender)
                    setGender({
                        label: `${userProfileData.gender.slice(0, 1)}${userProfileData.gender.substring(1).toLowerCase()}`,
                        value: userProfileData.gender
                    })
                else
                    setGender('')
                setIsVerified(userProfileData.is_verified)
                if (userProfileData.job_id === null)
                    setJobId('')
                else
                    fetchJobTitle(userProfileData.job_id).then(res => {
                        setJobId({
                            value: userProfileData.job_id || 'N/A',
                            label: res
                        })
                    })
                // setMaritalStatus(userProfileData.marital_status)
                if (userProfileData.marital_status)
                    setMaritalStatus({
                        label: `${userProfileData.marital_status.slice(0, 1)}${userProfileData.marital_status.substring(1).toLowerCase()}`,
                        value: userProfileData.marital_status
                    })
                else setMaritalStatus('')
                if (userProfileData.type !== null)
                    setType({
                        label: `${userProfileData.type.slice(0, 1)}${userProfileData.type.substring(1).toLowerCase()}`,
                        value: userProfileData.type
                    })
                else {
                    setType('')
                }

                // Fetch and display the user's profile photo
                let photoResponse
                if (userId !== null) {
                    photoResponse = await axios.get(
                        `${BASE_URL}/entities/users/profiles/${userId}/photo`,
                        {
                            responseType: 'arraybuffer',
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    );
                }

                // Convert the received binary data to a Data URL
                const blob = new Blob([photoResponse.data], {type: "image/png"});
                const dataURL = URL.createObjectURL(blob);
                setPhotoUrl(dataURL);
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
            loading()
        };

        fetchUserProfile(() => setEditLoading(false));
    };
    // select tables page
    const tablePageController = (selectedPage) => {
        setWaitForReq(true)
        if (selectedPage === `next`) setPage(prevState => prevState + 1)
        else if (selectedPage === `prev`) setPage(prevState => prevState - 1)
        else setPage(selectedPage)
    }

    function IndeterminateCheckbox({indeterminate, className = "", ...rest}) {
        const ref = useRef(null)

        useEffect(() => {
            if (typeof indeterminate === "boolean") {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
            setCheckedCounter(document.querySelectorAll(`td .select-checkbox[checked]`).length)
        }, [ref, indeterminate])

        return (
            <input
                type="checkbox"
                ref={ref}
                className={className}
                {...rest}
            />
        )
    }

    const columns = [
        {
            id: "select",
            header: ({table}) => (
                <div style={{position: "relative"}}>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row}) => (
                <div className={`d-flex align-items-center`} style={{position: "relative"}}>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                        className={'select-checkbox'}
                    />
                </div>
            ),
        },
        {
            id: "user-profile",
            header: "Profile",
            cell: ({row}) => {
                return (
                    <div className={`table__user-profile-container`}>
                        {
                            (row.original.photo_media_id) ?
                                <div>
                                    <img src={row.original.profile} className={`table__user-profile`}/>
                                    {/*<div className={`controller-buttons`}>*/}
                                    {/*    <img src={acceptIcon} alt=""/>*/}
                                    {/*    <img src={rejectIcon} alt=""/>*/}
                                    {/*</div>*/}
                                </div>
                                :
                                <img src={defalutProfile} className={`default-profile table__user-profile`}/>
                        }
                    </div>
                )
            },
        },
        {
            id: "name",
            header: "Name",
            cell: ({row}) => (
                <div className={`d-flex`}>
                    <span>{row.original.firstname} {row.original.lastname}{" "}</span>
                </div>
            ),
        },
        {
            id: "username",
            header: "Username",
            cell: ({row}) => (
                <div className={`d-flex align-items-center`}>
                    <img style={{width: '18px', marginRight: '10px'}} src={usernameBadge}
                         alt=""/><span>{row.original.username || '-'}{" "}</span>
                </div>
            ),
        },
        {
            id: "registered_at",
            header: "Registered at",
            cell: ({row}) => (
                <div className={`d-flex`}>
                    <span>{row.original.registered_at.slice(0, 10)}{" "}</span>
                </div>
            ),
        },
        {
            id: "details",
            header: "Details",
            cell: ({row}) => {
                return (
                    <div className={`d-flex flex-column`}>
                        <span>
                            {row.original.gender}
                        </span>
                        <span>
                            {row.original.birthdate}
                        </span>
                        <span>
                            {row.original.country}
                        </span>
                        <span>
                            {row.original.marital_status}
                        </span>
                    </div>)
            },
        },
        columnHelper.accessor("experience", {
            header: "Experience Years",
            cell: ({row}) => {
                if (row.original.experience)
                    return row.original.experience.over_30_years ? 'Over 30 Years' : row.original.experience.years
                return 'N/A'
                // return row.original.
            }
        }),
        columnHelper.accessor("is_verified", {
            header: "Verified",
            cell: data => (
                <div className={'is-verified'}>{data.row.original.is_verified ? <img src={acceptIcon}/> :
                    <img src={rejectIcon} alt=""/>}</div>
            )

        }),
        {
            id: "job-title",
            header: "Job",
            cell: ({row}) => {
                return row.original.jobTitle || "Loading..."; // Display "Loading..." until data is fetched
            },
        },
        columnHelper.accessor("documents-button", {
            header: "",
            cell: data => (
                <div className={`documents-buttons`}>
                    <div className={'d-flex flex-column align-items-start'} style={{gap: '5px'}}>
                        <button onClick={() => showUserCertificatesHandler(data.row.original.user_id)}>
                            Certificates
                        </button>
                        <button style={{width: `100%`}}
                                onClick={() => showUserEducationHandler(data.row.original.user_id)}>
                            Education
                        </button>
                    </div>
                    <button onClick={() => showUserDocsHandler(data.row.original.user_id)}>documents</button>
                </div>
            ),
        }),
        columnHelper.accessor("controller_buttons", {
            header: "",
            cell: data => (
                <div className={`controller-buttons`}>
                    <button onClick={e => editUser(data.row.original.user_id)}>
                        <img src={editIcon} className="edit-icon"/>
                    </button>
                    <button onClick={e => acceptButton(data)}>
                        <img src={acceptIcon} alt=""/>
                    </button>
                    <button onClick={() => rejectButton(data.row.original.user_id)}>
                        <img src={rejectIcon} alt=""/>
                    </button>
                </div>
            ),
        }),
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        getRowSelectionState: (row) => {
            return true;
        }
    });
    const addUserHandler = () => {
        setShowModal(true)
    }
    const docAcceptHandler = (item) => {
        axios.patch(`${BASE_URL}/admin/users/${item.user_id}/profile/document/${item.id}`, {
            is_verified: true
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                const foundItem = docs.filter(doc => doc.id !== item.id)
                setDocs(foundItem)
                setShowAlertSuccess(true)
                setTimeout(() => {
                    setShowAlertSuccess(false)
                }, 2000)
            }
        })
    }
    const rejectDocHandler = (e) => {
        axios.patch(`${BASE_URL}/admin/users/${rejectUser.user_id}/profile/document/${rejectUser.id}`, {
            is_verified: false,
            reject_reason: reason
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`
            }
        }).then(res => {
            if (res.status === 200) {
                const foundItem = docs.filter(doc => doc.id !== rejectUser.id)
                setDocs(foundItem)
                setShowMessageAlert(false)
                setShowAlertSuccess(true)
                setTimeout(() => {
                    setShowAlertSuccess(false)
                }, 2000)
            }
        })
    }

    return (
        <div className={`manage-surveys-table-container ${className}`}>
            <div>
                <h3 className={`main-heading`}>{children}</h3>
                <div className={`table-controllers`}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {/*<button style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}*/}
                        {/*        onClick={addUserHandler}><img src={plusIcon} alt=""/></button>*/}
                        <div style={{position: 'relative'}}>
                            <div className={`filters-container ${showFilter ? '' : 'd-none'}`}>
                                <div>
                                    <label className={`d-flex align-items-center justify-content-between`}><span>First Name:</span>
                                        <input
                                            style={{
                                                width: '208px', height: '40px'
                                            }} type="text"
                                            value={firstNameFilter}
                                            onChange={e => {
                                                return setFirstNameFilter(e.target.value)
                                            }}/>
                                    </label>
                                    <label className={`d-flex align-items-center justify-content-between`}><span>Last Name:</span>
                                        <input
                                            style={{width: '208px', height: '40px'}} type="text"
                                            value={lastNameFilter}
                                            onChange={e => setLastNameFilter(e.target.value)}/>
                                    </label>
                                    <div className={`d-flex align-items-center justify-content-between`}
                                         style={{gap: '20px'}}>
                                        Country: <Select onChange={(e) => {
                                        setCountryFilter({value: e.value, label: e.label})
                                        setFilterState('')
                                    }
                                    } options={countries.map(item => ({
                                        value: item.id, label: item.name
                                    }))} styles={selectStyle} value={countryFilter}/>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between`}
                                         style={{gap: '20px'}}>
                                        State: <Select
                                        onChange={(e) => {
                                            setFilterState({value: e.value, label: e.label})
                                            setFilterCity('')
                                        }}
                                        options={filterStates.map(item => {
                                            return {value: item.id, label: item.name}
                                        })} styles={selectStyle} value={filterState}/>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between`}
                                         style={{gap: '20px'}}>
                                        City: <Select name={`city_id`} onChange={(e) => {
                                        setFilterCity({value: e.value, label: e.label})
                                    }} options={filterCities.map(item => {
                                        return {value: item.id, label: item.name}
                                    })} styles={selectStyle} value={filterCity}/>
                                    </div>
                                    <div className={`d-flex align-items-center`}>
                                        Experience: <Select styles={selectStyle}
                                                            onChange={(e) => setFilterExp({
                                                                value: e.value,
                                                                label: e.label
                                                            })} options={
                                        [...expYears, {label: 'Over 30 years', value: 'over_30_years'}]
                                    } value={filterExp}/>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between`}>
                                        Gender: <Select name={`gender-filter`}
                                                        options={[{label: 'Male', value: 'MALE'}, {
                                                            label: 'Female',
                                                            value: 'FEMALE'
                                                        }]}
                                                        styles={selectStyle}
                                                        onChange={e => {
                                                            setFilterGender({value: e.value, label: e.label})
                                                        }}
                                                        value={filterGender}/>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between`}>
                                        Job: <Select name={`job_id`}
                                                     options={jobsList.map(item => ({
                                                         value: item.id, label: item.title
                                                     }))}
                                                     styles={selectStyle} onChange={e => {
                                        setFilterJob({value: e.value, label: e.label})
                                    }} value={filterJob}/>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between`}>
                                        Rate: <div style={{width: '209px'}}>
                                        from <input style={{width: `50px`}} type="text" value={rateFrom}
                                                    onChange={e => setRateFrom(e.target.value)}/> to <input
                                        style={{width: `50px`}} type="text" value={rateTo}
                                        onChange={e => setRateTo(e.target.value)}/>
                                    </div>
                                    </div>
                                </div>
                                {/*<button>Set Filters</button>*/}
                            </div>
                            <button style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    onClick={() => setShowFilter(prevState => !prevState)}><img src={filterIcon}
                                                                                                alt=""/>
                            </button>
                        </div>
                        <span>
                                            {checkedCounter ? <div>{`${checkedCounter} selected`}
                                                <button className={`delete-table-item`}
                                                        onClick={() => setShowDeleteAlert(true)}>Delete
                                                    Selected
                                                </button>
                                            </div> : null}
                                                </span>
                    </div>
                    <label className={`table-controller__search-wrapper`}>
                        <img src={searchIcon} alt=""/>
                        <input type="text" placeholder={'Search by Full Name'}
                               style={{outline: 'none', color: 'white'}} value={fullName}
                               onChange={(e) => setFullName(e.target.value)}/>
                    </label>
                </div>
                <div style={scrollable ? {
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    // maxHeight: `calc(100vh - 100px)`,
                    paddingRight: `15px`,
                    paddingLeft: `15px`
                } : {}}>
                    <table>
                        <thead>
                        {table.getHeaderGroups().map((headerGroup) => (<tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (<th key={header.id}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>))}
                        </tr>))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id} ref={rowRef} onClick={(e) => {
                                if (e.target.nodeName !== 'BUTTON')
                                    row.toggleSelected()
                            }}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {/*<div className={`reject-popup ${null ? 'show-reject-popup' : ''}`}>*/}
                {/*    <button style={{width: '40px', position: 'absolute', right: '-18px', top: '-11px'}}*/}
                {/*            onClick={() => (false)}>*/}
                {/*        <img src={rejectIcon} style={{width: '100%'}} alt=""/>*/}
                {/*    </button>*/}
                {/*    <textarea name="" id="" cols="30" rows="10" placeholder={`Report Details`}/>*/}
                {/*    <button className={`reject-popup__button`} onClick={() => rejectButton(setShowRejectPopUp)}>Done*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
            <div className={`table-pagination-container`}>
                <div className={`table-pagination`}>
                    {page === 1 ? null :
                        <button onClick={() => tablePageController(`prev`)}><img src={angleLeft} alt=""/>
                        </button>
                    }
                    {pages.map((item, index) => {
                        return <button key={index} className={page === item ? 'active' : null}
                                       onClick={() => tablePageController(item)}>{item}</button>
                    })}
                    {page === pages[pages.length - 1] ? null :
                        <button onClick={() => tablePageController(`next`)}><img src={angleRight} alt=""/></button>}
                </div>
            </div>
            <div ref={editDivRef}
                 className={`edit-user-popup ${showEditPopup ? '' : 'd-none'}`}>
                {editLoading ?
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.7)',
                        backdropFilter: 'blur(3px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        zIndex: '5'
                    }}>Loading...
                    </div> : null}

                <button onClick={() => {
                    editDivRef.current.scrollTo(0, 0)
                    setShowEditPopup(false)
                    setEditLoading(true)
                    setEditableUserId('')
                }} style={{
                    position: 'sticky',
                    right: '0',
                    top: '-10px',
                    width: '50px',
                    marginLeft: 'calc(100% - 40px)',
                    marginTop: `-10px`,
                    zIndex: '10'
                }}><img src={rejectIcon} alt="" style={{width: '100%'}}/></button>

                <div className={`${editLoading ? '' : 'overflow-y-scroll'}`}
                     style={{height: 'calc(100vh - 170px', paddingRight: '10px'}}>
                    <div className={`users-information`}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '30px'
                            }}>
                            <img src={photoUrl || noProfile}
                                 style={{borderRadius: '50%', width: '150px', height: '150px'}}
                                 alt="User Profile"/>
                        </div>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}
                             style={{marginBottom: '30px'}}>
                            <span style={{marginBottom: '10px'}}>Username: {userIDEdit}</span>
                            <span>Registered At: {registeredAtEdit.slice(0, 10)}</span>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            First Name: <input type="text" placeholder={userProfile.firstname || 'N/A'}
                                               value={firstname}
                                               onChange={e => setFirstname(e.target.value)}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Last Name: <input type="text" placeholder={userProfile.lastname || 'N/A'} value={lastname}
                                              onChange={e => setLastname(e.target.value)}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Birthdate: <input type={'Date'} onChange={e => setBirthDate(e.target.value)}
                                              placeholder={'N/A'}
                                              value={birthdate}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Gender: <Select
                            options={[{label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}]}
                            styles={editSelectStyles}
                            onChange={e => setGender({value: e.value, label: e.label})}
                            value={gender}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Country: <Select name={`country_id`} onChange={(e) => {
                            setStateId('')
                            setCityId('')
                            return setCountry({value: e.value, label: e.label})
                        }
                        } options={countries.map(item => ({
                            value: item.id, label: `${item.name}`
                        }))} styles={editSelectStyles} value={country}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            State: <Select name={`state_id`} onChange={(e) => {
                            setCityId('')
                            return setStateId({value: e.value, label: e.label})
                        }} options={states.map(item => {
                            return {value: item.id, label: item.name}
                        })} styles={editSelectStyles} value={stateId}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            City: <Select name={`city_id`} onChange={(e) => {
                            setCityId({value: e.value, label: e.label})
                        }} options={cities.map(item => {
                            return {value: item.id, label: item.name}
                        })} styles={editSelectStyles} value={cityId}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Experience: <Select styles={editSelectStyles}
                                                onChange={(e) => setExperience({value: e.value, label: e.label})}
                                                options={
                                                    [...expYears, {label: 'Over 30 years', value: 'over_30_years'}]
                                                } value={experience}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Job: <Select name={`job_id`}
                                         options={jobsList.map(item => ({
                                             value: item.id, label: item.title
                                         }))}
                                         styles={editSelectStyles} onChange={e => {
                            return setJobId({value: e.value, label: e.label})
                        }}
                                         value={jobId}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Marital Status: <Select
                            options={[{label: 'Single', value: 'SINGLE'}, {
                                label: 'Married',
                                value: 'MARRIED'
                            }, {label: 'Divorced', value: 'DIVORCED'}, {label: 'Widowed', value: 'WIDOWED'}]}
                            styles={editSelectStyles} onChange={e => setMaritalStatus({value: e.value, label: e.label})}
                            value={maritalStatus}/>
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            Type: <Select
                            options={[{value: `PERSONAL`, label: `PERSONAL`}, {value: `COMPANY`, label: `COMPANY`}]}
                            styles={editSelectStyles} value={type}
                            onChange={e => setType({value: e.value, label: e.label})}
                        />
                        </div>
                        <br/>
                        <div className={`d-flex align-items-center justify-content-between`} style={{gap: '20px'}}>
                            <label>
                                <input type="checkbox" style={{width: 'auto'}} checked={isVerified}
                                       onChange={e => setIsVerified(e.target.checked)}/> Is Verified
                            </label>
                        </div>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            <button className={`submit-user`} onClick={e => submitUserChanges(e)}>Submit</button>
                        </div>
                    </div>

                </div>
            </div>
            <div className={`${showUserCertificates ? '' : "d-none"} certification-popup`}>
                <button onClick={() => {
                    setShowUserCertificates(false)
                    setCertificatesLoading(true)
                    setCertificates(null)
                }} style={{
                    position: 'sticky',
                    right: '0',
                    top: '-10px',
                    width: '50px',
                    marginLeft: 'calc(100% - 40px)',
                    marginTop: `-10px`,
                    zIndex: '10'
                }}><img src={rejectIcon} alt="" style={{width: '100%'}}/></button>
                {certificatesLoading ?
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.7)',
                        backdropFilter: 'blur(3px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        zIndex: '5'
                    }}>Loading...
                    </div> : null}
                {certificates ? certificates.map((item, index) => {
                    return (
                        <div key={index} className={`d-flex flex-column align-items-center justify-content-center`}
                             style={{marginBottom: '30px'}}>
                            <img src={item.imageBlob} className={'certificate__img mb-3'}
                                 onClick={e => zoomImg(e)}/>
                            <span className={`mb-1`}>Title: {item.title}</span>
                            <span className={`mb-1`}>Start Date: {item.start_date.slice(0, 10)}</span>
                            <span className={`mb-1`}>End Date: {item.end_date.slice(0, 10)}</span>
                        </div>
                    )
                }) : <div style={{textAlign: 'center'}}>
                    <span>There is No Certificates!</span>
                </div>
                }
            </div>
            <div className={`${showUserEducation ? '' : "d-none"} certification-popup`}>
                <button onClick={() => {
                    setShowUserEducation(false)
                    setEducationsLoading(true)
                    setEducation(null)
                }} style={{
                    position: 'sticky',
                    right: '0',
                    top: '-10px',
                    width: '50px',
                    marginLeft: 'calc(100% - 40px)',
                    marginTop: `-10px`,
                    zIndex: '10'
                }}><img src={rejectIcon} alt="" style={{width: '100%'}}/></button>
                {educationsLoading ?
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.7)',
                        backdropFilter: 'blur(3px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        zIndex: '5'
                    }}>Loading...
                    </div> : null}
                {education ? education.map((item, index) => {
                    return (
                        <div key={index} className={`d-flex flex-column align-items-center justify-content-center`}
                             style={{marginBottom: '30px'}}>
                            <span>University: {item.university}</span>
                            <span>Degree: {item.degree}</span>
                            <span>Field of Study: {item.field_of_study}</span>
                            <span>Start Date: {item.start_date}</span>
                            <span>End Date: {item.end_date}</span>
                        </div>
                    )
                }) : <div style={{textAlign: 'center'}}>
                    <span>There is No Educations Data!</span>
                </div>}
            </div>
            <div className={`${showUserDocs ? '' : "d-none"} certification-popup`}>
                <button onClick={() => {
                    setShowUserDocs(false)
                    setDocsLoading(true)
                    setDocs(null)
                }} style={{
                    position: 'sticky',
                    right: '0',
                    top: '-10px',
                    width: '50px',
                    marginLeft: 'calc(100% - 40px)',
                    marginTop: `-10px`,
                    zIndex: '10'
                }}><img src={rejectIcon} alt="" style={{width: '100%'}}/></button>
                {docsLoading ?
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.7)',
                        backdropFilter: 'blur(3px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        zIndex: '5'
                    }}>Loading...
                    </div> : null}
                {docs ? docs.map((item, index) => {
                    return (
                        <div key={index} className={`d-flex flex-column align-items-center justify-content-center`}
                             style={{marginBottom: '30px'}}>
                            <img className={`docs-photos mb-3`} src={item.imageBlob} alt=""/>
                            <span>Document Type: {item.doc_type}</span>
                            <div style={{marginTop: '10px'}}>
                                <button onClick={() => docAcceptHandler(item)}><img style={{width: '30px'}}
                                                                                    src={acceptIcon}
                                                                                    alt=""/></button>
                                <button onClick={() => {
                                    setShowMessageAlert(true)
                                    setRejectUser(item)
                                }}><img style={{width: '30px'}}
                                        src={rejectIcon} alt=""/>
                                </button>
                            </div>
                        </div>
                    )
                }) : <div style={{textAlign: 'center'}}>
                    <span>There is No Educations Data!</span>
                </div>}
            </div>
            <div className={`success-alert ${showEdit ? '' : 'hide'}`}>
                User Edited Successfully!
            </div>
            <div className={`success-alert ${showDeleteSuccessAlert ? '' : 'hide'}`}>
                User Deleted Successfully!
            </div>
            <div className={`${zoomedImg ? '' : 'd-none'} zoomed-img-container`}>
                <button style={{position: 'absolute', right: '0', top: '0', padding: '5px'}}
                        onClick={() => {
                            setWidth100(true)
                            setZoomedImg(0)
                            setShowImg(false)
                        }}><img src={rejectIcon} alt=""/></button>
                <img src={zoomedImg} alt="" ref={zoomedImgRef}
                     className={`${width100 ? 'w-100' : 'h-100'} ${showImg ? 'opacity-1' : 'opacity-0'}`}/>
            </div>
            <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={deleteRowsHandler}>Yes</button>
                    <button onClick={() => setShowDeleteAlert(false)}>No</button>
                </div>
            </div>
            {(waitForReq || !data.length) ? <div style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                background: 'rgba(0,0,0,0.3',
                backdropFilter: 'blur(2px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                wait...
            </div> : null}
            <div className={`success-alert ${showAlertSuccess ? '' : 'hide'}`} style={{width: '200px'}}>
                Done!
            </div>
            <div className={`reject-message ${showMessageAlert ? '' : 'hide'}`} style={{width: 'auto'}}>
                <button style={{width: '40px', position: 'absolute', right: '-18px', top: '-11px'}}
                        onClick={() => {
                            setShowMessageAlert(false)
                            setRejectUser('')
                        }}>
                    <img src={rejectIcon} style={{width: '100%'}} alt=""/>
                </button>
                Reason:
                <textarea name="" id="" cols="30" rows="10" value={reason}
                          onChange={e => setReason(e.target.value)}></textarea>
                <button className={'reject-done-button'} onClick={e => rejectDocHandler(e)}>Done</button>
            </div>
            <div className={`reject-message ${showMessageAlert ? '' : 'hide'}`} style={{width: 'auto'}}>
                <button style={{width: '40px', position: 'absolute', right: '-18px', top: '-11px'}}
                        onClick={() => {
                            setShowMessageAlert(false)
                            setRejectUser('')
                        }}>
                    <img src={rejectIcon} style={{width: '100%'}} alt=""/>
                </button>
                Reason:
                <textarea name="" id="" cols="30" rows="10" value={reason}
                          onChange={e => setReason(e.target.value)}></textarea>
                <button className={'reject-done-button'} onClick={e => rejectDocHandler(e)}>Done</button>
            </div>
        </div>
    )
};

export default Table;
