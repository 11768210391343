import './ProjectCard.css'

//style
import folderNotch from '../../../assets/images/folder-notch.svg'

const ProjectCard = ({title, number, photo}) => {
    return (
        <div className={`projects-card`}>
            <div className={`d-flex align-items-center justify-content-between`}>
                <span className={`projects-card__title`}>{title}</span>
                <img src={folderNotch} className={`projects-card__icon`} alt=""/>
            </div>
            <div className={`d-flex align-items-center justify-content-between`}>
                <span className={`projects-card__count`}>{number}</span>
                {/*<div className={`projects-card__progress`}><span>+14.54%</span><img src="" alt=""/></div>*/}
            </div>
        </div>
    )
}

export default ProjectCard;