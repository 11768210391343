import React, {useEffect, useState} from 'react';
import axios from "axios";
import BASE_URL from "../../api/api";
import {mergeUsersIntoPollArray, selectKeysFromArray} from "../../util/arrayHelper"; // Import the Table component
import {exportToExcel, filterKeysByActive, flattenUserData, transformData} from "../../util/exportHelper";
import {getCurrentDateTimeForFilename} from "../../util/dateHelper";
import 'bootstrap/dist/css/bootstrap-grid.css';
import './index.css';
import ExportFilters from "../../components/ExportFilters";
import api from "../../api/api";

const ExportLog = ({currentQuestion, result, pollId, question}) => {
    const [exportData, setExportData] = useState([])
    const [filters, setFilters] = useState({
            poll_id: pollId,
            experience: null,
            gender: null,
            job: null,
            marital: null,
            country_id: null,
            country_state_id: null,
            country_state_city_id: null,
            degree_id: null,
            birth_year: null, // format: "0, 2030"
            poll_question_choice_id: null, // format: [0, 2030]
            poll_question_id: null, // format: [0, 2030]
        }
    )


    function handleUpdateState(key, value) {
        value = value ? value : null
        setFilters({ ...filters, [key]: value });
    }

    function handleUpdateBithYear(from, to) {
        from = from ? from : 0
        to = to ? to : 2040
        setFilters({ ...filters, birth_year: `${from},${to}` });
    }

    console.log(filters)

    function handleExport() {
        let apiUrlParams = ""
        if (filters.country_id) {
            apiUrlParams += `country_id=${filters.country_id}`
        }
        if (filters.country_state_id) {
            apiUrlParams += apiUrlParams ? `&country_state_city_id=${filters.country_state_city_id}` : `country_state_city_id=${filters.country_state_city_id}`
        }
        if (filters.country_state_city_id) {
            apiUrlParams += apiUrlParams ? `&country_state_id=${filters.country_state_id}` : `country_state_id=${filters.country_state_id}`
        }
        if (filters.degree_id) {
            apiUrlParams += apiUrlParams ? `&degree_id=${filters.degree_id}` : `degree_id=${filters.degree_id}`
        }
        if (filters.birth_year) {
            apiUrlParams += apiUrlParams ? `&birth_year=${filters.birth_year}` : `birth_year=${filters.birth_year}`
        }
        if (filters.gender) {
            apiUrlParams += apiUrlParams ? `&gender=${filters.gender}` : `gender=${filters.gender}`
        }
        if (filters.job) {
            apiUrlParams += apiUrlParams ? `&job=${filters.job}` : `job=${filters.job}`
        }
        if (filters.experience) {
            apiUrlParams += apiUrlParams ? `&experience_years=${filters.experience}` : `experience_years=${filters.experience}`
        }
        if (filters.poll_question_id) {
            apiUrlParams += apiUrlParams ? `&poll_question_id=${filters.poll_question_id}` : `poll_question_id=${filters.poll_question_id}`
        }
        if (filters.poll_question_choice_id) {
            apiUrlParams += apiUrlParams ? `&poll_question_choice_id=${filters.poll_question_choice_id}` : `poll_question_choice_id=${filters.poll_question_choice_id}`
        }
        apiUrlParams = apiUrlParams ? '?' + apiUrlParams : ''


        // @todo Change to api
        axios.get(`${BASE_URL}/admin/poll/${filters.poll_id}/stats${apiUrlParams}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {

             const {stats} = res.data.data
            console.log(stats)
            const transformedData = transformData(stats.participants);
            exportToExcel(transformedData, 'export' + getCurrentDateTimeForFilename())

        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="container mt-3 text-white">
            <div>
                <main style={{color: "#fff"}}>
                    <div className="add-user">
                        <ExportFilters currentQuestion={currentQuestion} handleUpdateBithYear={handleUpdateBithYear} handleUpdateState={handleUpdateState} />
                    </div>
                </main>
            </div>
            <button onClick={handleExport} className="add-poll__button">Export</button>
        </div>
    );
};

export default ExportLog;
