// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-pass-icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/PasswordInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,UAAU;IACV,aAAa;AACjB","sourcesContent":[".show-pass-icon {\n    position: absolute;\n    right: 13px;\n    top: 50%;\n    transform: translateY(-50%);\n    padding: 0;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
