import {useState} from "react";
import './Users.css'
import Table from "./global/Table";
import SidebarLeft from "./global/Sidebar-left";
import TopBar from "./global/TopBar";
import SidebarRight from "./global/Sidebar-right";
import PollsTable from "./PollsTable";

const Polls = () => {
    return (
        <PollsTable className={`user-management-table`}>Order List</PollsTable>
    )
}

export default Polls;