import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useContext, useRef, useState} from "react";
import "../scenes/dashboard/Poll.css";

import {useEffect} from "react";
import axios from "axios";
import BASE_URL from "../api/api";
import Select from "react-select";
import dragIcon from "../assets/images/drag-icon.png"
import trashIcon from "../assets/images/trash-icon.svg"
import {UpdateContext} from "../context";
import button from "bootstrap/js/src/button";
import Editor from "../Editor";
import rejectIcon from "../assets/images/reject-icon.png";
import editIcon from "../assets/images/edit-icon.png";
import addIcon from "../assets/images/add-icon.png";


const ExportFilters = ({currentQuestion, handleUpdateBithYear, handleUpdateState}) => {
    const {setUpdatePolls} = useContext(UpdateContext)
    const [birthdate, setBirthdate] = useState({value: null, label: null})
    const [exp, setExp] = useState({value: null, label: null})
    const [gender, setGender] = useState({value: null, label: null})
    const [jobId, setJobId] = useState({value: null, label: null})
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState({value: null, label: null})
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState('649b37ad7f7ed483f911b03f')
    const [title, setTitle] = useState([])
    const [reward, setReward] = useState([])
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState({value: null, label: null})
    const [cities, setCities] = useState([])
    const [cityId, setCityId] = useState({value: null, label: null})
    const [maritalStatus, setMaritalStatus] = useState({value: null, label: null})
    const [jobsList, setJobsList] = useState([])
    const [showQue, setShowQue] = useState(false)
    const [pollId, setPollId] = useState(undefined)
    const [activePoll, setActivePoll] = useState(false)
    const [formCounter, setFormCounter] = useState(0)
    const [showSubmitedsuccessfully, setShowSubmitedsuccessfully] = useState(false)
    const [showResponsesField, setShowResponsesField] = useState()
    const [langs, setLangs] = useState([])
    const [languages, setLanguages] = useState([])
    const [titleTranslations, setTitleTranslations] = useState([])
    const [degrees, setDegrees] = useState([])
    const [updateDegrees, setUpdateDegree] = useState(false)
    const [fieldOfStudies, setFieldOfStudies] = useState([])
    const [fieldOfStudy, setFieldOfStudy] = useState([])
    const [updateFieldOfStudies, setUpdateFieldOfStudies] = useState(false)

    const [filterOnlyThisQue, setFilterOnlyThisQue] = useState(null)

    useEffect(() => {
        axios.get(`${BASE_URL}/entities/degrees`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setDegrees(res.data.data.degrees))
    }, [updateDegrees])

    useEffect(() => {
        axios.get(`${BASE_URL}/entities/fields-of-study`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setFieldOfStudies(res.data.data.fields_of_study))
    }, [updateFieldOfStudies])

    const [focusedOnTitle, setFocusedOnTitle] = useState(false)

    // translations variables:
    const [haveTranslateToEn, setHaveTranslateToEn] = useState(false)


    // const initRate = {from: 0, to: 10, result: 'teste'}
    const [ratesData, setRatesData] = useState([])

    const addFieldHandler = () => {
        setRatesData([...ratesData, {from: 0, to: 0, result: ''}])
    }
    const initForm = (order) => ({
        order: order,
        question: '',
        translations: {},
        required: true,
        scoring_factor: '',
        type: '',
        inputs: [
            {inputValue: '', order: 1, rate: ``, inputId: `${order}-0`, inputType: '', inputTranslations: {}},
            {inputValue: '', order: 2, rate: ``, inputId: `${order}-1`, inputType: '', inputTranslations: {}}
        ],
        id: `form-${order}`
    });

    const handleEditorUpdate = (html, index) => {
        // Update the data array with the modified HTML
        const newData = [...ratesData];
        newData[index].result = html;
        setRatesData(newData);
    };

    const [forms, setForms] = useState([initForm(formCounter)]);
    let dateobj = new Date()
    let birthdateYear = []
    for (let i = 1900; i <= dateobj.getFullYear(); i++) {
        birthdateYear.push(i)
    }
    let expYears = []
    for (let i = 1; i <= 30; i++) {
        expYears.push({value: i, label: i})
    }
    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                marginTop: `15px`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        valueContainer: (styles) => {
            return {
                ...styles,
                height: '100%',
                position: 'relative'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
            };
        },

        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0'
            })
        },
        input: (styles) => {
            return ({
                ...styles,
                color: 'white',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0',
            })
        }
    }
    const deleteQue = (formIndex) => {
        setFormCounter(prevState => prevState - 1)
        const updatedForms = [...forms]
        updatedForms.splice(formIndex, 1);
        updatedForms.forEach((form, index) => {
            form.order = index
            form.id = `form-${index}`
            form.inputs.forEach((input, inputIndex) => {
                input.inputId = `${index}-${inputIndex}`
            })
        })
        setForms(updatedForms);
    }
    const deleteChoice = (formIndex, inputIndex) => {
        const updatedForms = [...forms]
        updatedForms[formIndex].inputs.splice(inputIndex, 1);
        updatedForms[formIndex].inputs.forEach((input, index) => {
            input.inputId = `${formIndex}-${index}`
        })
        setForms(updatedForms);
    }

    const requiredHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].required = e.target.checked;
        setForms(updatedForms);
    };
    const submitQueHandler = () => {
        forms.map(({order, question, required, scoring_factor, type, inputs, translations}) => {
            axios.post(`${BASE_URL}/admin/poll/${pollId}/question`, {
                order: order + 1,
                question,
                required,
                scoring_factor: +scoring_factor,
                type,
                translations
            }, {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
                langs.forEach((lang, index) => {
                    if (lang.id != '6533c65a7b8ccffa90a73450' && translations[lang.id] != undefined)
                        axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${res.data.data.poll_question.id}/translations`, {
                            "language_id": `${lang.id}`,
                            "question": translations[lang.id]
                        }, {
                            headers: {
                                "Accept": "*/*",
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            }
                        }).then(res => {
                            setShowSubmitedsuccessfully(true)
                            setTimeout(() => {
                                setShowSubmitedsuccessfully(false)
                            }, 2000)
                        }).catch(err => {
                            // console.log(err)
                        })
                })
                inputs.map(({inputValue, order, rate, inputType, inputTranslations}) => {
                    let queId = res.data.data.poll_question.id
                    axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${res.data.data.poll_question.id}/choice`, {
                        answer: inputValue,
                        order: +order,
                        rate: +rate,
                        type: inputType
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        langs.forEach((lang, index) => {
                            if (lang.id != '6533c65a7b8ccffa90a73450' && inputTranslations[lang.id] != undefined)
                                axios.post(`${BASE_URL}/admin/poll/${pollId}/question/${queId}/choice/${res.data.data.poll_question_choice.id}/translations`, {
                                    "choice": inputTranslations[lang.id],
                                    "language_id": `${lang.id}`
                                }, {
                                    headers: {
                                        "Accept": "*/*",
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                    }
                                })
                        })
                        setShowSubmitedsuccessfully(true)
                        setTimeout(() => {
                            setShowSubmitedsuccessfully(false)
                        }, 2000)
                    }).catch(err => {
                        // console.log(err)
                    })
                })
            })


        })
        // axios.patch(`${BASE_URL}/admin/poll/${pollId}`, {
        //     "personality_scores": ratesData
        // }, {
        //     headers: {
        //         "Accept": "*/*",
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
        //     }
        // }).then(res => console.log(res))
        //     .catch(err => console.log(err))
    }
    const typeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].type = e.value;
        setForms(updatedForms);
    }
    const inputTypeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs.map(item => {
            item.inputType = e.value
        })

        setForms(updatedForms);
    }

    useEffect(() => {
        if (pollCategoryId.label === 'Personality Tests') {
            setShowResponsesField(true)
        } else {
            setShowResponsesField(false)
        }
    }, [pollCategoryId])

    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/entities/languages`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            let languages = res.data.data.languages
            let temp = languages[0]
            languages[0] = languages[1]
            languages[1] = temp
            setLangs(res.data.data.languages)
            let langIdContainer = {}
            res.data.data.languages.forEach(item => {
                if (item.id !== '6533c65a7b8ccffa90a73450')
                    langIdContainer[item.id] = ''
            })
            // console.log(res.data.data.languages)
            // setTitleTranslations(langIdContainer)
        }).catch(err => {
            console.log(err)
        })
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`${BASE_URL}/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        }).catch(err => {
            // console.log(err)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })
        axios.get(`${BASE_URL}/entities/jobs`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setJobsList(res.data.data.jobs))

        axios.get(`${BASE_URL}/entities/languages`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setLanguages(res.data.data.languages))


    }, [])
    useEffect(() => {
        if (countryId.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (stateId.value) {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states/${stateId.value}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            })
        }
    }, [stateId])
    const addPoll = (e) => {
        let criteria = {}

        if (birthdate.value || exp.value || gender.value || maritalStatus.value || cityId.value || stateId.value || countryId.value || jobId.value) {

            if (birthdate.value) {
                criteria["birthdate_year"] = birthdate.value
            }

            if (exp.value) {
                criteria["experience"] = {
                    "over_30_years": (exp === 0),
                    "years": exp === undefined ? null : exp.value
                }
            }

            if (gender.value) {
                criteria["gender"] = gender.value
            }

            if (maritalStatus.value) {
                criteria["marital_status"] = maritalStatus.value
            }

            if (cityId.value || stateId.value || countryId.value) {
                criteria["location"] = {
                    "country_state_city_id": cityId === undefined ? null : cityId.value,
                    "country_state_id": stateId === undefined ? null : stateId.value,
                    "country_id": countryId === undefined ? null : countryId.value,
                }
            }

            if (jobId.value) {
                criteria["job_id"] = jobId.value
            }

        } else {
            criteria = {}
        }
        let data = {
            "active": activePoll,
            "criteria": criteria,
            "description": desc === undefined ? null : desc,
            "poll_category_id": pollCategoryId === '' ? null : pollCategoryId.value,
            "reward": reward === undefined ? 0 : +reward,
            "title": title === undefined ? null : title,
        }
        axios.post('https://api.globalvalidity.app/admin/poll', data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                if (titleTranslations != []) {
                    langs.forEach(item => {
                        if (item.id != '6533c65a7b8ccffa90a73450' && titleTranslations[item.id] != undefined) {
                            axios.post(`${BASE_URL}/admin/poll/${res.data.data.poll.id}/translations`, {
                                "language_id": `${item.id}`,
                                "title": `${titleTranslations[item.id]}`
                            }, {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        }
                    })
                }
                setUpdatePolls(prev => !prev)
                setPollId(res.data.data.poll.id)
                setShowQue(true)
            }
        }).catch(err => {
            // console.log(err)
        })
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;


        const items = Array.from(forms);

        if (result.type === "forms") {
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            items.forEach((item, index) => {
                item.order = index
            })
            setForms(items);
        } else {
            const formIndex = result.destination.droppableId[result.destination.droppableId.length - 1];
            const copiedForm = {...forms[formIndex]};
            const copiedInputs = [...copiedForm.inputs];
            const [reorderedInput] = copiedInputs.splice(result.source.index, 1);
            copiedInputs.splice(result.destination.index, 0, reorderedInput);

            copiedForm.inputs = copiedInputs;

            items[formIndex] = copiedForm;

            setForms(items);
        }
    }

    const addPollHandler = () => {
        setForms([...forms, initForm(formCounter + 1)])
        setFormCounter(prevState => prevState + 1)
    }

    const addChoiceHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs = [...updatedForms[formIndex].inputs, {
            inputValue: ``,
            order: updatedForms[formIndex].inputs.length,
            rate: '',
            inputId: `${updatedForms[formIndex].order}-${updatedForms[formIndex].inputs.length + 1}`,
            inputType: updatedForms[formIndex].inputs[0].inputType,
            inputTranslations: langs.map(lang => ({
                language_id: lang.id,  // assuming lang has an id property
                choice: ""
            }))
        }]
        setForms(updatedForms);
    }

    const formInputHandler = (e, formIndex, inputIndex, translate = false) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputValue = e.target.value;
        setForms(updatedForms);
    };
    const formInputTranslationHandler = (e, formIndex, inputIndex, lang = false) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputTranslations[lang.id] = e.target.value;
        setForms(updatedForms);
    };

    const formInputRateHandler = (e, formIndex, inputIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].rate = e.target.value;
        setForms(updatedForms);
    };
    const queHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].question = e.target.value;
        setForms(updatedForms);
    };
    const queTranslationHandler = (e, formIndex, lang) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].translations[lang.id] = e.target.value;
        setForms(updatedForms);
    };

    const scoringFactorHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].scoring_factor = e.target.value;
        setForms(updatedForms);
    }

    const titleTranslationHandler = (e, lang) => {
        let tempt = []
        tempt = {...titleTranslations}
        tempt[lang.id] = e.target.value
        setTitleTranslations(tempt)
    }

    const fromBirthYear = useRef(null)
    const toBirthYear = useRef(null)


    const handleFromSelectChange = (selectedOption) => {
        fromBirthYear.current = selectedOption;
        handleFromToBithYear()
    };

    const handleToSelectChange = (selectedOption) => {
        toBirthYear.current = selectedOption;
        handleFromToBithYear()
    };

    function handleFromToBithYear() {
        handleUpdateBithYear(fromBirthYear.current.value, toBirthYear.current.value)
    }

    return (
        <>
            <div className={`add-user d-block`}>
                <div>
                    <form onSubmit={e => e.preventDefault()}>
                        <div>
                            Birthdate:
                            <br/>
                            <br/>
                            From:
                            <Select ref={fromBirthYear}
                                    isClearable={true}
                                    options={birthdateYear.map(item => ({value: item, label: item}))}
                                    styles={selectStyle}
                                    onChange={handleFromSelectChange}
                            />
                        </div>
                        <div>

                            <br/>
                            <br/>
                            To:
                            <Select ref={toBirthYear}
                                    isClearable={true}
                                    options={birthdateYear.map(item => ({value: item, label: item}))}
                                    styles={selectStyle}
                                    onChange={handleToSelectChange}
                            />
                        </div>
                        <div>
                            Experience:
                            <Select styles={selectStyle} isClearable={true}
                                    onChange={(e) => handleUpdateState('experience', e ? e.value : null)} options={
                                [...expYears, {label: 'Over 30 years', value: 'over_30_years'}]
                            }/>
                        </div>
                        <div>
                            Job:
                            <Select styles={selectStyle}
                                    onChange={(e) => handleUpdateState('job', e ? e.value : null)}
                                    isClearable={true}
                                    options={
                                        jobsList.map((item) => {
                                            return ({
                                                value: item.id, label: item.title
                                            })
                                        })
                                    }/>

                        </div>
                        <div>
                            Gender:
                            <Select isClearable={true}
                                    options={[{label: 'Male', value: 'MALE'}, {
                                        label: 'Female',
                                        value: 'FEMALE'
                                    }]}
                                    onChange={e => handleUpdateState('gender', e ? e.value : null)}
                                    styles={selectStyle}/>
                        </div>
                        <div>
                            Marital Status: <Select isClearable={true}
                                                    options={[{label: 'Single', value: 'SINGLE'}, {
                                                        label: 'Married',
                                                        value: 'MARRIED'
                                                    }, {label: 'Divorced', value: 'DIVORCED'}, {
                                                        label: 'Widowed',
                                                        value: 'WIDOWED'
                                                    }]}
                                                    onChange={e => handleUpdateState('marital', e ? e.value : null)}
                                                    styles={selectStyle}/>
                        </div>
                        <div>
                            <span>Degrees:</span>
                            <br />
                                <Select options={degrees.map(({id, title}) => ({value: id, label: title}))}
                                        styles={selectStyle}
                                        onChange={e => handleUpdateState('degree_id', e ? e.value : null)}/>
                        </div>
                        <div>
                            <span>Field of Study:</span>
                            <br />
                                <Select options={fieldOfStudies.map(({id, title}) => ({value: id, label: title}))}
                                        styles={selectStyle}
                                        onChange={e => handleUpdateState('field_of_study_id', e ? e.value : null)}/>
                        </div>
                        <div>
                            <span>Export only this question:</span>
                            <br />
                            <Select isClearable={true}
                                    options={
                                [
                                    {value: currentQuestion.id, label: "Filter this question"},
                                    {value: null, label: "Include all questions"}
                                ]
                            }
                                    styles={selectStyle}
                                    onChange={e => {handleUpdateState('poll_question_id', e ? e.value : null); e ? setFilterOnlyThisQue(e.value) : setFilterOnlyThisQue(null) }}/>

                            { filterOnlyThisQue ? <div>
                                <span>Choices</span>
                                <br />
                                <Select isClearable={true}
                                        options={
                                    [...currentQuestion.choices.map(ch => { console.log(ch.id)
                                        const label = ch.choice.replace(/<\/?[^>]+(>|$)/g, "")
                                        return {value: ch.id, label: label}
                                    })
                                    ]
                                }
                                        styles={selectStyle}
                                        onChange={e => handleUpdateState('poll_question_choice_id', e ? e.value : null)}/>
                            </div> : null }
                        </div>
                        <div>
                            <div>
                                Country:
                                <Select isClearable={true}
                                        onChange={(e) => {
                                            setCountryId({
                                                value: e === null ? null : e.value,
                                                label: e === null ? null : e.label
                                            })
                                        }}
                                        options={countries.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle}/>
                            </div>
                            <div>
                                State:
                                <Select className={(states && states.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => {
                                            setStateId({
                                                value: e === null ? null : e.value,
                                                label: e === null ? null : e.label
                                            })
                                        }}
                                        options={states.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle}/>
                            </div>
                            <div>
                                City:
                                <Select className={(cities && cities.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => {
                                            setCityId({
                                                value: e === null ? null : e.value,
                                                label: e === null ? null : e.label
                                            })
                                        }}
                                        options={cities.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle}/>
                            </div>
                        </div>
                    </form>

                </div>
            </div>


            <div className={showQue ? null : 'd-none'} style={{gridColumn: `1/13`}}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="forms-container" type={`forms`}>
                        {(provided) => (
                            <ul className="forms" {...provided.droppableProps} ref={provided.innerRef}>
                                {forms.map(({
                                                id,
                                                formId,
                                                question,
                                                order,
                                                inputs,
                                                scoring_factor,
                                                required
                                            }, index) => {
                                    return (
                                        <div key={id}>
                                            <Draggable draggableId={id} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div className={`d-flex align-items-center mb-4`}>
                                                            <div
                                                                className={'d-flex align-items-center flex-column w-100'}>
                                                                <div className={'d-flex align-items-center w-100'}>
                                                            <span {...provided.dragHandleProps}><img
                                                                className={`drag-icon`}
                                                                src={dragIcon}
                                                                alt=""/></span>
                                                                    <span
                                                                        className={`form-que-order`}>{order + 1}</span>
                                                                    <div className={'w-100'}>
                                                                        <div>
                                                                            <label
                                                                                className={'d-flex align-items-center w-100'}>
                                                                        <span style={{
                                                                            display: 'inline-block',
                                                                            width: '20px'
                                                                        }}>Fa:</span> <input type="text"
                                                                                             value={question}
                                                                                             placeholder={`Question`}
                                                                                             onChange={(e) => queHandler(e, index)}
                                                                                             style={{marginLeft: '10px'}}/>

                                                                            </label>
                                                                            {langs.map(lang => {
                                                                                if (lang.id !== "6533c65a7b8ccffa90a73450")
                                                                                    return (
                                                                                        <label key={lang.id}
                                                                                               className={'d-flex align-items-center w-100 mt-2'}>
                                                                                            <span style={{
                                                                                                display: 'inline-block',
                                                                                                width: '20px'
                                                                                            }}>{lang.code}:</span>
                                                                                            <input
                                                                                                style={{marginLeft: '10px'}}
                                                                                                type="text"
                                                                                                placeholder={lang.name}
                                                                                                onChange={(e) => queTranslationHandler(e, index, lang)}/>
                                                                                        </label>
                                                                                    )
                                                                            })}
                                                                        </div>
                                                                    </div>

                                                                    <button onClick={() => deleteQue(index)}>
                                                                        <img style={{
                                                                            width: '20px',
                                                                            filter: 'brightness(0) invert(1)'

                                                                        }} src={trashIcon} alt=""/>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className={`forms__selects-container`}>
                                                            {/*<input type="number" placeholder={`Scorring Factor`}*/}
                                                            {/*       value={scoring_factor}*/}
                                                            {/*       onChange={(e) => scoringFactorHandler(e, index)}*/}
                                                            {/*       style={{*/}
                                                            {/*           marginBottom: `20px`,*/}
                                                            {/*           maxWidth: `150px`*/}
                                                            {/*       }}/>*/}
                                                            <div>
                                                                <span>Questiopn Type:</span>
                                                                <Select options={[{
                                                                    value: 'SINGLE_CHOICE',
                                                                    label: 'Single Choice'
                                                                }, {value: 'MULTI_CHOICE', label: 'Multi Choice'}]}
                                                                        styles={selectStyle}
                                                                        onChange={e => typeHandler(e, index)}/>
                                                            </div>
                                                            <div>
                                                                <span>Inputs Type:</span>
                                                                <Select options={[{
                                                                    value: 'SELECT',
                                                                    label: 'Select'
                                                                }, {
                                                                    value: 'INPUT',
                                                                    label: 'Input'
                                                                }
                                                                    , {
                                                                        value: 'RATE',
                                                                        label: 'Rate'
                                                                    }].map(item => ({
                                                                    value: item.value, label: item.label
                                                                }))} styles={selectStyle}
                                                                        onChange={e => inputTypeHandler(e, index)}/>
                                                            </div>
                                                        </div>
                                                        <Droppable droppableId={`inputs-${order}`}
                                                                   type={`inputs-${order}`}>
                                                            {(provided) => {
                                                                return (
                                                                    <ul className={`inputs inputs-${id}`} {...provided.droppableProps}
                                                                        ref={provided.innerRef}>
                                                                        {inputs.map(({
                                                                                         inputValue,
                                                                                         inputId,
                                                                                         rate,
                                                                                         inputEn,
                                                                                     }, inputIndex) => {
                                                                            return (
                                                                                <Draggable key={inputId}
                                                                                           draggableId={`input-${inputId}`}
                                                                                           index={inputIndex}>
                                                                                    {(provided) => {
                                                                                        return (
                                                                                            <li ref={provided.innerRef} {...provided.draggableProps}
                                                                                                className={`d-flex align-items-start`}>
                                                                                        <span {...provided.dragHandleProps}><img
                                                                                            className={`drag-icon`}
                                                                                            src={dragIcon}
                                                                                            alt=""/></span>
                                                                                                <div
                                                                                                    className={'d-flex flex-column w-100'}>
                                                                                                    <div
                                                                                                        className={'d-flex w-100'}>
                                                                                                        <input
                                                                                                            id={inputId}
                                                                                                            type="text"
                                                                                                            value={inputValue}
                                                                                                            placeholder={`Choice ${inputIndex + 1}`}
                                                                                                            onChange={(e) => formInputHandler(e, index, inputIndex)} // Pass the correct form index and input index
                                                                                                        />
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            value={rate}
                                                                                                            placeholder={`Rate`}
                                                                                                            onChange={(e) => formInputRateHandler(e, index, inputIndex)}
                                                                                                            style={{
                                                                                                                maxWidth: `60px`,
                                                                                                                marginLeft: `10px`
                                                                                                            }}/>
                                                                                                    </div>
                                                                                                    {langs.map((lang) => {
                                                                                                        if (lang.id !== "6533c65a7b8ccffa90a73450")
                                                                                                            return (
                                                                                                                <div
                                                                                                                    key={lang.id}
                                                                                                                    className={'d-flex w-100 mt-2'}>
                                                                                                                    <input
                                                                                                                        id={inputId}
                                                                                                                        type="text"
                                                                                                                        placeholder={`Choice ${inputIndex + 1} ${lang.name}`}
                                                                                                                        onChange={(e) => formInputTranslationHandler(e, index, inputIndex, lang)} // Pass the correct form index and input index
                                                                                                                    />
                                                                                                                    <input
                                                                                                                        type="number"
                                                                                                                        value={rate}
                                                                                                                        placeholder={`Rate`}
                                                                                                                        onChange={(e) => formInputRateHandler(e, index, inputIndex)}
                                                                                                                        style={{
                                                                                                                            maxWidth: `60px`,
                                                                                                                            marginLeft: `10px`
                                                                                                                        }}/>
                                                                                                                </div>
                                                                                                            )
                                                                                                    })}
                                                                                                </div>
                                                                                                <button>
                                                                                                    <img style={{
                                                                                                        width: '20px',
                                                                                                        filter: 'brightness(0) invert(1)'
                                                                                                    }} src={trashIcon}
                                                                                                         onClick={() => deleteChoice(index, inputIndex)}
                                                                                                         alt=""/>
                                                                                                </button>
                                                                                            </li>
                                                                                        );
                                                                                    }}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </ul>
                                                                )
                                                            }}
                                                        </Droppable>
                                                        <label style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginLeft: `48px`,
                                                            marginBottom: '10px'
                                                        }}><input onChange={(e) => requiredHandler(e, index)}
                                                                  type="checkbox"
                                                                  checked={required}
                                                                  style={{
                                                                      width: '16px',
                                                                      marginRight: '10px'
                                                                  }}/> Required</label>
                                                        <button onClick={(e) => addChoiceHandler(e, index)}
                                                                className={`add-poll__button add-choice-button`}>
                                                            Add Choice
                                                        </button>
                                                    </li>
                                                )}
                                            </Draggable>
                                        </div>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )
                        }
                    </Droppable>
                </DragDropContext>
                <button className={`add-poll__button`} type={"submit"} onClick={addPollHandler}>Add Question
                </button>
                {
                    (showQue && pollCategoryId.value === '64fc313df370ffab069369e6') ?
                        <div className={'rating-form'}>
                            {
                                ratesData.map((item, i) => {
                                    return (
                                        <div style={{marginTop: '50px', marginBottom: '30px'}} key={i}>
                                            <div>

                                                <div>
                                                    <label>From: <input type="text" value={ratesData[i].from}
                                                                        onChange={(e) => {
                                                                            const newData = [...ratesData];
                                                                            newData[i].from = +e.target.value;
                                                                            setRatesData(newData)
                                                                        }}/></label>
                                                </div>
                                                <div>
                                                    <label>To: <input type="text" value={ratesData[i].to}
                                                                      onChange={(e) => {
                                                                          const newData = [...ratesData];
                                                                          newData[i].to = +e.target.value;
                                                                          setRatesData(newData)
                                                                      }}/></label>
                                                </div>
                                            </div>

                                            <Editor
                                                key={i}
                                                placeholder={`Edit result ${i + 1}`}
                                                initialValue={item.result}
                                                onUpdate={(html) => handleEditorUpdate(html, i)}
                                            />


                                        </div>
                                    )

                                })
                            }

                            <button className={'add-poll__button'} onClick={addFieldHandler}>Add Rate</button>
                        </div>
                        : null
                }
                <button className={`add-poll__button`} type={"submit"} onClick={submitQueHandler}>Submit</button>
            </div>
            <div style={{gridColumn: `1/13`}}>
            </div>
            <div className={`success-alert ${showSubmitedsuccessfully ? '' : 'hide'}`}>
                Poll Added Successfully!
            </div>
        </>
    )
        ;

};

export default ExportFilters