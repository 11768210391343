// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
    background: #141613;
    padding: 80px 24px 24px;
    max-width: 95%;
    width: 668px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    position: relative;
    max-height: 95%;
    overflow-y: auto;
}

.modal-close-button {
    /* Styling for close button */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 14px;
    right: 16px;
}

.modal-close-button:hover {
    background-color: #4b4d48;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,oCAAoC;AACvD;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000; /* Ensure it's above other content */\n}\n\n.modal-content {\n    background: #141613;\n    padding: 80px 24px 24px;\n    max-width: 95%;\n    width: 668px;\n    border-radius: 4px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    position: relative;\n    max-height: 95%;\n    overflow-y: auto;\n}\n\n.modal-close-button {\n    /* Styling for close button */\n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 10px 10px;\n    cursor: pointer;\n    display: flex;\n    position: absolute;\n    top: 14px;\n    right: 16px;\n}\n\n.modal-close-button:hover {\n    background-color: #4b4d48;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
