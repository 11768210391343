import {useState, useEffect} from "react";

//style
import './PasswordInput.css'

//images
import showPassIcon from "../assets/images/show-pass-icon.svg";

const PasswordInput = ({setPwDiff}) => {
    const [passwordValue, setPasswordValue] = useState('');
    const [repeatedPasswordValue, setRepeatedPasswordValue] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [showPass, setShowPass] = useState(false)
    const passwordInputHandler = (e) => {
        setPasswordValue(e.target.value);
    };
    const showPassHandler = (e) => {
        e.preventDefault();
        setShowPass(prevState => !prevState)
    }
    const repeatPasswordInputHandler = (e) => {
        setRepeatedPasswordValue(e.target.value);
    }
    useEffect(() => {
        const initPwdChecker = () => {
            let pwdCheck = 0;
            let pwRegCheck = 0;
            let pwLengthCheck = 0;
            let validateRegex = ["[A-z]", "[0-9]", "\\W"];
            validateRegex.forEach((regex, i) => {
                if (new RegExp(regex).test(passwordValue)) {
                    pwRegCheck += 1;
                }
                if (passwordValue.length >= 8) {
                    pwLengthCheck = 1;
                } else {
                    pwLengthCheck = 0;
                }
                pwdCheck = pwRegCheck + pwLengthCheck;
            });
            switch (pwdCheck) {
                case 0:
                    return {
                        strength: 0,
                        val: "",
                    };
                case 1:
                    return {
                        strength: 1,
                        val: "weak",
                    };
                case 2:
                    return {
                        strength: 2,
                        val: "fair",
                    };
                case 3:
                    return {
                        strength: 3,
                        val: "good",
                    };
                case 4:
                    return {
                        strength: 4,
                        val: "strong",
                    };
                default:
                    return null;
            }
        };
        setPasswordStrength(initPwdChecker().val);
    }, [passwordValue]);
    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (passwordValue === repeatedPasswordValue && passwordValue !== '') setPwDiff(false)
        else setPwDiff(true)
    }, [passwordValue, repeatedPasswordValue])
    return (
        <div className={`w-100`}>
            <label>
                <input type={`${showPass ? 'text' : 'password'}`} placeholder="Password"
                       onChange={passwordInputHandler}/>
                <button className={`show-pass-icon`} onClick={showPassHandler}>
                    <img src={showPassIcon} alt=""/>
                </button>
            </label>
            <div className={`password-strength ${passwordStrength}`}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <span className={`password-conditions`}>
                Use 8 or more characters with a mix of letters, numbers & symbols.
            </span>
            <label>
                <input type={`${showPass ? 'text' : 'password'}`} placeholder={`Repeat Password`}
                       onChange={repeatPasswordInputHandler}
                       className={`repeat-password`}/>
            </label>
        </div>
    );
};

export default PasswordInput;
