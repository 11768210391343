import axios from "axios";
//style
import './Sidebar-right.css'
import {Link} from 'react-router-dom'
//images
import noProfile from '../../../assets/images/profile-user-icon-white.png'
import bugIcon from '../../../assets/images/bug-icon.svg'
import userIcon from '../../../assets/images/user-icon.svg'
import broadcastIcon from '../../../assets/images/broadcast-icon.svg'
import {useContext, useEffect, useState} from "react";
import BASE_URL from "../../../api/api";
import poll from "../Poll";
import usernameBadge from '../../../assets/images/uer-badge.png'
import {UpdateContext} from "../../../context";

const fetchUserProfile = async (userId) => {
    try {
        const response = await axios.get(
            `https://api.globalvalidity.app/entities/users/profiles/${userId}/photo`,
            {
                responseType: "arraybuffer",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }
        );

        const blob = new Blob([response.data], {type: "image/png"});
        const dataURL = URL.createObjectURL(blob);
        return dataURL;

    } catch (error) {
        // console.log(error)
        return null;
    }
};

const SidebarRight = ({isOpen}) => {
    const {updateRightSide, updatePolls} = useContext(UpdateContext);
    const [usersData, setUsersData] = useState([]);
    const [newUsersData, setNewUsersData] = useState([]);
    const [page, setPage] = useState(1);
    const [polls, setPolls] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    let unverifiedUsersCounter = 1
    useEffect(() => {
        // console.log(updateRightSide);
    }, [updateRightSide]);

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(`${BASE_URL}/admin/users/profiles?page=${page}&limit=10`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            })
            .then(async (res) => {
                let users = res.data.data.user_profiles;
                let counter = 0

                const usersWithProfilesAndJobs = await Promise.all(
                    users.map(async (user, index) => {
                        if (!user.is_verified) {
                            counter++
                        }
                        if (counter <= 5) {
                            let userProfile
                            if (user.photo_media_id)
                                userProfile = await fetchUserProfile(user.user_id);
                            return {
                                ...user,
                                profile: userProfile || null,
                            };
                        }
                    })
                );
                if (counter < 5) {
                    setPage(prevState => prevState + 1)
                } else {
                }
                setUsersData([...usersData, ...usersWithProfilesAndJobs])
                setIsLoading(false)

                // setUsersData(userProfile)
            }).catch(err => {
            console.log(err)
        })
    }, [page, updateRightSide]);

    useEffect(() => {
        setPage(1)
        setUsersData([])
    }, [updateRightSide])

    useEffect(() => {
        if (isLoading === false) {
            setNewUsersData(usersData); // Set newUsersData to usersData
        }
    }, [isLoading, usersData])


    useEffect(() => {
        axios
            .get(`${BASE_URL}/admin/polls?page=1&limit=5`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => setPolls(res.data.data.polls))
            .catch(err => console.log(err))
    }, [updatePolls])

    return (
        <aside className={`d-none d-lg-inline-block side-bar--right ${isOpen ? '' : 'close'}`}>
            <h3 className="right-side__title">Unverified Users</h3>
            <ul className={`notifications`}>
                {newUsersData.map((item, index) => {
                    if (item && item.is_verified !== true && unverifiedUsersCounter <= 5) {
                        unverifiedUsersCounter++
                        return (
                            <li key={index}>
                                <Link to="users">
                                    <div className={`notifications__img-container`}>
                                        <img style={{width: '100%'}} src={item.profile || noProfile} alt=""/>
                                    </div>
                                    <div>
                                        <span className={`list-item__title`}>{item.firstname} {item.lastname}</span>
                                        <div className={'d-flex align-items-center'}>
                                            <img style={{width: "18px", marginRight: '5px'}} src={usernameBadge}
                                                 alt=""/>
                                            <span>{item.username || '-'}</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                })}
            </ul>
            <h3 className="right-side__title">Latest Users</h3>
            <ul className={`notifications`}>
                {newUsersData.map((item, index) => {
                    if (item && index <= 4)
                        return (
                            <li key={index}>
                                <Link to="users">
                                    <div className={`notifications__img-container`}>
                                        <img style={{width: '100%'}} src={item.profile || noProfile} alt=""/>
                                    </div>
                                    <div>
                                        <span className={`list-item__title`}>{item.firstname} {item.lastname}</span>
                                        <div className={'d-flex align-items-center'}>
                                            <img style={{width: "18px", marginRight: '5px'}} src={usernameBadge}
                                                 alt=""/>
                                            <span>{item.username || '-'}</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                })}
            </ul>
            <h3 className="right-side__title">Latest Surveys</h3>
            <ul className={`notifications`}>
                {
                    polls.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link to="manage-surveys">
                                    <span>
                            <span className={`list-item__title`}>{item.title}</span>
                            <span>{item.description}</span>
                                </span>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </aside>
    );

}

export default SidebarRight;