// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    grid-row: 1/2;
    border-bottom: 1px solid rgba(var(--primary-color), 10%);
    padding: 0 var(--gap);
    grid-column: 2/4;
}

@media screen and (min-width: 62em) {
    .top-bar {
        grid-column: 2/3;
    }
}

.top-bar > div {
    display: flex;
    align-items: center;
}

.search-label {
    width: 160px;
    height: 28px;
    background-color: rgba(var(--primary-color), .1);
    padding: 4px 6px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.search-label input {
    border: none;
    outline: none;
    background-color: transparent;
    display: inline;
    width: 100%;
}

.search-label img {
    width: 16px;
    height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/scenes/dashboard/global/TopBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,wDAAwD;IACxD,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,gDAAgD;IAChD,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".top-bar {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 72px;\n    grid-row: 1/2;\n    border-bottom: 1px solid rgba(var(--primary-color), 10%);\n    padding: 0 var(--gap);\n    grid-column: 2/4;\n}\n\n@media screen and (min-width: 62em) {\n    .top-bar {\n        grid-column: 2/3;\n    }\n}\n\n.top-bar > div {\n    display: flex;\n    align-items: center;\n}\n\n.search-label {\n    width: 160px;\n    height: 28px;\n    background-color: rgba(var(--primary-color), .1);\n    padding: 4px 6px;\n    border-radius: 8px;\n    display: inline-flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.search-label input {\n    border: none;\n    outline: none;\n    background-color: transparent;\n    display: inline;\n    width: 100%;\n}\n\n.search-label img {\n    width: 16px;\n    height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
