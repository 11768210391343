import ProjectCard from "./global/ProjectCard";
import folderNotch from "../../assets/images/folder-notch.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";

const UsersLogs = () => {
    const [usersCount, setUsersCount] = useState()
    const [newUsersCount, setNewUsersCount] = useState()
    const [onlineUsersCount, setOnlineUsersCount] = useState()
    useEffect(() => {
        const date = new Date()
        axios.get(`${BASE_URL}/admin/users/profiles?count_only=true&page=1`, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            setUsersCount(res.data.data.count)
        })
        const fiveDaysAgo = new Date(date);
        fiveDaysAgo.setDate(date.getDate() - 10);

        const formattedDate = (date) => {
            const pad = (n) => (n < 10 ? `0${n}` : n);
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}%3A${pad(date.getMinutes())}%3A${pad(date.getSeconds())}%2B03%3A30`;
        };

        axios.get(`${BASE_URL}/admin/users/profiles?count_only=true&registered_at_from=${formattedDate(fiveDaysAgo)}&registered_at_to=${formattedDate(date)}&page=1`, {
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user-token")}`,
            },
        }).then((res) => {
            if (res.data.data.count === undefined) setNewUsersCount(0);
            else setNewUsersCount(res.data.data.count);
        }).catch((err) => {
            console.log(err);
        });
        const padWithZero = (num) => num.toString().padStart(2, '0');


        const currentDate = new Date();
        const fiveHoursAgo = new Date(currentDate.getTime() - 5 * 60 * 60 * 1000); // Subtract 5 hours in milliseconds

        const formattedDate2 = (date) => `${date.getFullYear()}-${padWithZero(date.getMonth() + 1)}-${padWithZero(date.getDate())}T${padWithZero(date.getHours())}%3A${padWithZero(date.getMinutes())}%3A${padWithZero(date.getSeconds())}%2B03%3A30`;

        axios.get(`${BASE_URL}/admin/users/profiles?last_request_at_from=${formattedDate2(fiveHoursAgo)}&last_request_at_to=${formattedDate2(currentDate)}&count_only=true&page=1`, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            console.log(res)
            if (res.data.data.count === undefined) setOnlineUsersCount(0);
            else setOnlineUsersCount(res.data.data.count)
        });


    }, [])
    return (
        <section className={`project-cards-section`}>
            <h3 className={`main-heading`}>Summery</h3>
            <div className={`project-cards-container`}>
                <ProjectCard title={`Total Users`} number={usersCount}/>
                <ProjectCard title={`New Users`} number={newUsersCount}/>
                <ProjectCard title={`Online Users`} number={onlineUsersCount}/>
            </div>
        </section>
    )
}

export default UsersLogs