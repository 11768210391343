import {ResponsivePie} from "@nivo/pie";
import {useParams} from "react-router-dom";
import PieChart from "./global/PieChart";
import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";

const PollLog = () => {
    const [stats, setStats] = useState(null)
    let {pollId} = useParams()
    useEffect(() => {
        axios.get(`${BASE_URL}/admin/poll/${pollId}/stats`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setStats(res.data.data.stats)
        }).catch(err => {
            console.log(err)
        })
    }, [])
console.log(stats)
    return (
        <>
            {stats && stats.poll_id ?
                <PieChart stats={stats} className={`poll-log-pie-chart`}>Project Status</PieChart>
                : null
            }
        </>
    )
}

export default PollLog