import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap-grid.css';
import SignIn from "./scenes/authentication/SignIn";
import ProtectedRoute from "./util/ProtectedRoute";
import Verification from "./scenes/authentication/Verification";
import Dashboard from "./scenes/dashboard";
import AddPoll from "./scenes/dashboard/AddPoll";
import SingUp from "./scenes/authentication/SingUp";
import NotFound from "./NotFound";
import UsersManagement from "./scenes/dashboard/Users";
import User from "./scenes/dashboard/User";
import Polls from './scenes/dashboard/Polls'
import Home from "./scenes/dashboard/Home";
import UserManagement from "./scenes/dashboard/UserManagement";
import Poll from "./scenes/dashboard/Poll";
import Table from "./scenes/dashboard/global/Table";
import Settings from "./scenes/dashboard/Settings";
import LocationsSettings from "./scenes/dashboard/LocationsSetting";
import PollsLogs from "./scenes/dashboard/PollsLogs";
import UsersLogs from "./scenes/dashboard/UsersLogs";
import PollLog from "./scenes/dashboard/PollLog";
import EditUser from "./scenes/dashboard/EditUser";
import UsersAvatars from "./scenes/dashboard/UsersAvatars";
import UsersDocuments from "./scenes/dashboard/UsersDocuments";
import EditPoll from "./scenes/dashboard/EditPoll";
import JobsSettings from "./scenes/dashboard/JobsSettings";
import EducationSettings from "./scenes/dashboard/EducationSettings";
import DocumentSettings from "./scenes/dashboard/DocumentSettings";
import SurveyCategories from "./scenes/dashboard/SurveyCategories";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter basename={'/'}>
        <Routes>
            <Route path='/login' element={<SignIn/>}/>
            <Route path="verification/:verificationUrl" element={<Verification/>}/>
            <Route path="/" element={<App/>}>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Dashboard/>
                    </ProtectedRoute>
                }>
                    <Route path={`/`} element={
                        <ProtectedRoute>
                            <Home/>
                        </ProtectedRoute>
                    }/>
                    <Route path={`manage-surveys`} element={
                        <ProtectedRoute>
                            <Polls/>
                        </ProtectedRoute>}
                    />
                    <Route path="user-management/user/:userId" element={
                        <ProtectedRoute>
                            <User/>
                        </ProtectedRoute>
                    }/>
                    <Route path="user-management/edit-user/:userId" element={
                        <ProtectedRoute>
                            <EditUser/>
                        </ProtectedRoute>
                    }/>
                    <Route path="logs/polls/:pollId" element={
                        <ProtectedRoute>
                            <PollLog/>
                        </ProtectedRoute>
                    }/>
                    <Route path="user" element={
                        <ProtectedRoute>
                            <UserManagement/>
                        </ProtectedRoute>
                    }/>
                    <Route path="add-poll" element={
                        <ProtectedRoute>
                            <Poll/>
                        </ProtectedRoute>
                    }/>
                    <Route path="users" element={
                        <ProtectedRoute>
                            <Table/>
                        </ProtectedRoute>
                    }/>
                    <Route path="app-settings" element={
                        <ProtectedRoute>
                            <Settings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="locations-settings" element={
                        <ProtectedRoute>
                            <LocationsSettings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="jobs-settings" element={
                        <ProtectedRoute>
                            <JobsSettings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="users-logs" element={
                        <ProtectedRoute>
                            <UsersLogs/>
                        </ProtectedRoute>
                    }/>
                    <Route path="polls-logs" element={
                        <ProtectedRoute>
                            <PollsLogs/>
                        </ProtectedRoute>
                    }/>
                    <Route path="users-avatars" element={
                        <ProtectedRoute>
                            <UsersAvatars/>
                        </ProtectedRoute>
                    }/>
                    <Route path="users-documents" element={
                        <ProtectedRoute>
                            <UsersDocuments/>
                        </ProtectedRoute>
                    }/>
                    <Route path="edit-poll/:editPollId" element={
                        <ProtectedRoute>
                            <EditPoll/>
                        </ProtectedRoute>
                    }/>
                    <Route path="educations-settings" element={
                        <ProtectedRoute>
                            <EducationSettings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="document-settings" element={
                        <ProtectedRoute>
                            <DocumentSettings/>
                        </ProtectedRoute>
                    }/>
                    <Route path="survey-categories" element={
                        <ProtectedRoute>
                            <SurveyCategories/>
                        </ProtectedRoute>
                    }/>
                </Route>
            </Route>
            <Route path={`*`} element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
    // </React.StrictMode>
);