// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authentication__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.authentication__top-bar nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 23px;
}

.authentication__top-bar nav ul li {
    display: flex;
}

.authentication__top-bar nav ul li a {
    padding: 6px 5px;
    font-size: 14px;
}

.authentication__top-bar button {
    font-size: 14px;
    padding: 5px 8px;
}

.login-button {
    border-radius: 999px;
    border: 1px solid rgba(var(--primary-color), 20%);
    margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/scenes/authentication/global/TopBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,iDAAiD;IACjD,gBAAgB;AACpB","sourcesContent":[".authentication__top-bar {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px;\n}\n\n.authentication__top-bar nav ul {\n    display: flex;\n    list-style: none;\n    padding: 0;\n    gap: 23px;\n}\n\n.authentication__top-bar nav ul li {\n    display: flex;\n}\n\n.authentication__top-bar nav ul li a {\n    padding: 6px 5px;\n    font-size: 14px;\n}\n\n.authentication__top-bar button {\n    font-size: 14px;\n    padding: 5px 8px;\n}\n\n.login-button {\n    border-radius: 999px;\n    border: 1px solid rgba(var(--primary-color), 20%);\n    margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
