export function mergeUsersIntoPollArray(poll, users) {
    poll.questions.forEach(question => {
        question.choices.forEach(choice => {
            console.log(choice.user_ids)
            if (choice.user_ids !== null) {
                // Map each user_id to the corresponding user object from the users array
                choice.users = choice.user_ids.map(id =>
                    users.find(user => console.log(user))
                );
            } else {
                choice.users = null
            }
        });
    });

    return poll
}

export function selectKeysFromArray(arr, keys) {
    return arr.map(obj => {
        let selected = {};
        keys.forEach(key => {
            if (obj.hasOwnProperty(key)) {
                selected[key] = obj[key];
            }
        });
        return selected;
    });
}