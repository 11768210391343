import logo from '../../../assets/images/logo.svg'


//style
import style from './TopBar.css'
const TopBar = () => {
    return (
        <header className={`authentication__top-bar`}>
            <img src={logo} alt=""/>
            {/*<nav>*/}
            {/*    <ul>*/}
            {/*        <li><a href="#">Menu 1</a></li>*/}
            {/*        <li><a href="#">Menu 2</a></li>*/}
            {/*        <li><a href="#">Menu 3</a></li>*/}
            {/*        <li><a href="#">Menu 4</a></li>*/}
            {/*        <li><a href="#">Menu 5</a></li>*/}
            {/*    </ul>*/}
            {/*</nav>*/}
            <div>
                {/*<button>Sign in</button>*/}
                <button className={`login-button`}>Login</button>
            </div>
        </header>
    )
}

export default TopBar;