//hooks
import {useState, useEffect, useRef} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
//libraries
import axios from "axios";

//styles
import './global/Table.css';
import acceptIcon from "../../assets/images/accept-icon.png";
import rejectIcon from "../../assets/images/reject-icon.png";
import button from "bootstrap/js/src/button";
import logsIcon from "../../assets/images/logs-icon.png";

const PollsLogs = ({children, className}) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [checkedCounter, setCheckedCounter] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const history = useNavigate()
    const [birthdate, setBirthdate] = useState()
    const [exp, setExp] = useState()
    const [gender, setGender] = useState()
    const [jobId, setJobId] = useState()
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState([])
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState([])
    const [title, setTitle] = useState([])
    const [reward, setReward] = useState([])
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState([])
    const [cities, setCities] = useState([])
    const [cityId, setCityId] = useState([])
    const [maritalStatus, setMaritalStatus] = useState([])
    const columnHelper = createColumnHelper();
    const navigate = useNavigate()

    const goToPoll = (pollId) => {
        navigate(`/logs/polls/${pollId}`);
    }

    //API request
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/admin/polls?page=1&limit=50&poll_category_ids=64fc2cd5f370ffab069369e1,64fc2dbff370ffab069369e3,64fc2ddcf370ffab069369e4`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                setData(res.data.data.polls)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [page]);

    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })
    }, [])
    useEffect(() => {
        if (typeof (countryId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (typeof (stateId) === 'string') {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId}/states/${stateId}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            })
        }
    }, [stateId])
    // select tables page
    const tablePageController = (selectedPage) => {
        if (selectedPage === `next`) setPage(prevState => prevState + 1)
        else if (selectedPage === `prev`) setPage(prevState => prevState - 1)
        else setPage(selectedPage)
    }

    function IndeterminateCheckbox({indeterminate, className = "", ...rest}) {
        const ref = useRef(null)

        useEffect(() => {
            if (typeof indeterminate === "boolean") {
                ref.current.indeterminate = !rest.checked && indeterminate
            }
            setCheckedCounter(document.querySelectorAll(`td .cursor-pointer[checked]`).length)
        }, [ref, indeterminate])

        return (<input
            type="checkbox"
            ref={ref}
            className={className + " cursor-pointer"}
            {...rest}
        />)
    }

    const columns = [columnHelper.accessor("active", {
        header: () => {
            return <div className={'d-flex justify-content-center'}>
                <span>active</span>
            </div>
        },
        cell: ({row}) => {
            return <div className={`d-flex align-items-center justify-content-center`} style={{paddingLeft: '6px'}}>
                <img src={row.original.active ? acceptIcon : rejectIcon} style={{width: '20px'}}/>
            </div>
        }
    }), columnHelper.accessor("title", {
        header: "title",
    }), columnHelper.accessor("log", {
        header: "Log",
        cell: ({row}) => {
            return <button className={'p-0'}
                           onClick={(event) => {
                               goToPoll(row.original.id)
                           }}
            ><img src={logsIcon} style={{width: '24px', filter: "invert(1)"}}/></button>
        }
    })];

    const table = useReactTable({
        data, columns, getCoreRowModel: getCoreRowModel(), enableRowSelection: true,
    });

    const addPoll = (e) => {
        let data = {
            "description": `${desc}`,
            "poll_category_id": `${pollCategoryId}`,
            "reward": +reward,
            "title": `${title}`,
            "criteria": {
                "birthdate": +birthdate,
                "experience_years": `${exp}`,
                "marital_status": `${maritalStatus}`,
                "location": {
                    "country_state_city_id": `${cityId}`,
                    "country_state_id": `${stateId}`,
                    "country_id": `${countryId}`,
                },
                "job_id": `${jobId}`,
                "gender": `${gender}`
            },
        }
        axios.post('https://api.globalvalidity.app/admin/poll', data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            // console.log(res)
        }).catch(err => {
            // console.log(err)
        })
    }
    const birthdateHandler = (e) => {

    }
    return (<div className={className} style={{gridColumn: '1/13'}}>
        <h3 className={`main-heading`}>{children}</h3>
        <div style={{width: `100%`, overflowX: 'scroll'}} className={`scrollable-table`}>

            <table>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (<tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (<th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>))}
                </tr>))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => {
                    return (<tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (<td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>))}
                    </tr>)
                })}
                </tbody>
            </table>
        </div>
        <div className={`table-pagination`}>
            <button onClick={() => tablePageController(`prev`)}>prev</button>
            <button onClick={() => tablePageController(1)}>1</button>
            <button onClick={() => tablePageController(2)}>2</button>
            <button onClick={() => tablePageController(3)}>3</button>
            <button onClick={() => tablePageController(4)}>4</button>
            <button onClick={() => tablePageController(5)}>5</button>
            <button onClick={() => tablePageController(`next`)}>next</button>
        </div>
    </div>);
};

export default PollsLogs;
