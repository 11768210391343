import {useState, useEffect} from "react";
import {Routes, Route, Outlet, useNavigate} from "react-router-dom";
import axios from "axios";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [is404, setIs404] = useState(false);
    const navigate = useNavigate()
    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    useEffect(() => {
        axios.interceptors.response.use(
            (res) => res,
            (err) => {
                console.log(err)
                // displayTxError(err)
                if (err.response?.status === 404) {
                    setIs404(true)
                }
                if (err.response?.status === 401) {
                    navigate('/login')
                    console.log(err)
                    // localStorage.removeItem('user-token');

                    setIsLoggedIn(false)
                }
            }
        )
    }, []);
    return (
        <Outlet/>
    );
}

export default App;
