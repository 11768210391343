import {useState} from "react";
import {useNavigate} from "react-router-dom";
import './Users.css'
import Table from "./global/Table";
import SidebarLeft from "./global/Sidebar-left";
import TopBar from "./global/TopBar";
import SidebarRight from "./global/Sidebar-right";

const Users = () => {
    const [sideBarLeftOpen, setSideBarLeftOpen] = useState(true)
    const [sideBarRightOpen, setSideBarRightOpen] = useState(true)
    const navigate = useNavigate()
    const toggleLeftSide = () => {
        setSideBarLeftOpen(!sideBarLeftOpen)
    }
    const toggleRightSide = () => {
        setSideBarRightOpen(!sideBarRightOpen)
    }

    function avatarHandler() {
        navigate("/usedfdrs");
    }


    return (
        <main>
            <Table className={`user-management-table`}>Order List</Table>
        </main>
    )
}

export default Users;