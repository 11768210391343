import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import acceptIcon from '../../assets/images/accept-icon.png'
import rejectIcon from '../../assets/images/reject-icon.png'
import Documents from "../../components/Documents";

const UsersDocuments = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [documents, setDocuments] = useState([])
    const [paged, setPaged] = useState(1)
    const [verifiedDocs, setVerifiedDocs] = useState(0)
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)

    const limit = 10

    function handleVerifiedDocsNum() {
        setVerifiedDocs(prevState => prevState + 1)
    }

    useEffect(() => {
        if (verifiedDocs >= 10) {
            setPaged(prevState => prevState + 1)
            setVerifiedDocs(prevState => prevState - 10)
        }
    }, [verifiedDocs])

    useEffect(() => {
        axios.get(`${BASE_URL}/admin/identity-documents?page=${paged}&limit=${limit}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(async (res) => {
            const {identity_documents} = res.data.data;
            setIsLoading(false)
            setDocuments(identity_documents)
        });
    }, [paged]);

    const acceptDocHandler = (item) => {
        axios.patch(`${BASE_URL}/admin/users/${item.user_id}/profile/document/${item.id}`, {
            is_verified: true
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                const foundItem = docs.filter(doc => doc.id !== item.id)
                setDocs(foundItem)
                setShowImgPopup(false)
                setShowAlertSuccess(true)
                setTimeout(() => {
                    setShowAlertSuccess(false)
                }, 2000)
            }
        })
    }

    const rejectDocHandler = (e) => {
        axios.patch(`${BASE_URL}/admin/users/${rejectUser.user_id}/profile/document/${rejectUser.id}`, {
            is_verified: false,
            reject_reason: reason
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`
            }
        }).then(res => {
            if (res.status === 200) {
                const foundItem = docs.filter(doc => doc.id !== rejectUser.id)
                setDocs(foundItem)
                setShowMessageAlert(false)
                setShowImgPopup(false)
                setShowAlertSuccess(true)
                setTimeout(() => {
                    setShowAlertSuccess(false)
                }, 2000)
            }
        })
    }


    if (isLoading) {
        return "Loading..."
    }

    return (
        <>
            {
                (documents && documents.length) ?
                    <Documents documents={documents} handleVerifiedDocsNum={handleVerifiedDocsNum}/> : "Nothing Found!"
            }
            <div className={`success-alert ${showAlertSuccess ? '' : 'hide'}`}>
                Done!
            </div>
        </>
    )


}

export default UsersDocuments;
