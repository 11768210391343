import {Routes, Route, Outlet} from "react-router-dom";
// hooks
import {useEffect, useState} from "react";
import {UpdateContext} from '../../context'
import {useNavigate} from "react-router-dom";

//global
import TopBar from "./global/TopBar";
import SidebarLeft from "./global/Sidebar-left";
import SidebarRight from "./global/Sidebar-right";


//style
import './index.css'

import Users from "./Users";
import Home from "./Home";
import UserManagement from './UserManagement'
import AddPoll from "./AddPoll";
import users from "./Users";

const Dashboard = () => {
    const [sideBarLeftOpen, setSideBarLeftOpen] = useState(true)
    const [sideBarRightOpen, setSideBarRightOpen] = useState(true)
    const [updateRightSide, setUpdateRightSide] = useState(false)
    const [updatePolls, setUpdatePolls] = useState(false)
    const navigate = useNavigate()
    const toggleLeftSide = () => {
        setSideBarLeftOpen(!sideBarLeftOpen)
    }

    useEffect(() => {
        if (window.innerWidth < 992) {
            setSideBarLeftOpen(false)
        }
    }, []);
    const toggleRightSide = () => {
        setSideBarRightOpen(!sideBarRightOpen)
    }
    return (
        <UpdateContext.Provider value={{updateRightSide, setUpdateRightSide, updatePolls, setUpdatePolls}}>
            <div
                className={`dashboard-container ${sideBarRightOpen ? null : "right-side--closed"} ${sideBarLeftOpen ? null : "left-side--closed"}`}>
                <SidebarLeft isOpen={sideBarLeftOpen}/>
                <TopBar toggleLeftSide={toggleLeftSide} toggleRightSide={toggleRightSide}/>
                <SidebarRight isOpen={sideBarRightOpen}/>
                <main>
                    <Outlet/>
                </main>
            </div>
        </UpdateContext.Provider>
    )
}
export default Dashboard;