import {SketchPicker} from "react-color";
import {useEffect, useState} from "react";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import closeIcon from '../../assets/images/reject-icon.png'
import axios from "axios";
import Select from 'react-select'
import rejectIcon from '../../assets/images/reject-icon.png'
import editIcon from '../../assets/images/edit-icon.png'
import addIcon from '../../assets/images/add-icon.png'
import BASE_URL from "../../api/api";

const selectStyle = {
    container: (styles) => {
        return ({
            ...styles,
            marginBottom: `22px`,
            width: '100%'
        })
    },
    control: (styles) => {
        return ({
            ...styles,
            border: `1px solid #5f615d`,
            backgroundColor: `#2a2c29`,
            borderRadius: `8px`,
            color: `white`,
            padding: `2px 8px`,
            outline: `none`,
            boxShadow: `none`,
            marginTop: `15px`,
            height: `42px`,
            ':hover': {cursor: `pointer`}
        })
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            background: `#2c2e2b`,
            color: `white`,
            ':hover': {background: `#3e3f3c`, cursor: `pointer`},
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            background: `transparent`
        }
    },
    menuList: (styles) => {
        return {
            ...styles,
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#4b4d48"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }
    },
    noOptionsMessage: (styles) => {
        return ({
            ...styles,
            background: `#2c2e2b`,
        })
    },
    singleValue: (styles) => {
        return ({
            ...styles,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            color: `white`,
        })
    },
    input: (styles) => {
        return ({
            ...styles,
            color: `white`,
        })
    }
}

const LocationsSetting = () => {
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState([])
    const [stateId, setStateId] = useState([])
    const [cityId, setCityId] = useState([])
    const [countryLabel, setCountryLabel] = useState(countryId ? countryId.label : '');
    const [countryCode, setCountryCode] = useState(countryId ? countryId.code : '');
    const [countryPreCode, setCountryPreCode] = useState(countryId ? countryId.pre_code : '');
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [showCountryInputs, setShowCountryInputs] = useState(false);
    const [showStatesInputs, setShowStatesInputs] = useState(false);
    const [showCitiesInputs, setShowCitiesInputs] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [deleteCountryAlert, setDeleteCountryAlert] = useState(false);
    const [deleteStateAlert, setDeleteStateAlert] = useState(false);
    const [deleteCityAlert, setDeleteCityAlert] = useState(false);
    const [updateToggler, setUpdateToggler] = useState(false);

    useEffect(() => {
        axios.get(
            `https://api.globalvalidity.app/entities/countries`,
            {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }
        ).then(res => {
            setCountries(res.data.data.countries)
        })
    }, [updateToggler])

    useEffect(() => {
        setStateId(null)
        setCityId(null)
        if (countryId && countryId.value)
            axios.get(
                `https://api.globalvalidity.app/entities/countries/${countryId.value}/states`,
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            ).then(res => {
                if (res.data.data.country_states)
                    setStates(res.data.data.country_states)
            }).catch(err => {
                // console.log(err)
            })
    }, [countryId, updateToggler])

    useEffect(() => {
        setCityId(null)
        if (countryId && countryId.value && stateId && stateId.value)
            axios.get(
                `https://api.globalvalidity.app/entities/countries/${countryId.value}/states/${stateId.value}/cities`,
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            ).then(res => {
                if (res.data.data.country_state_cities)
                    setCities(res.data.data.country_state_cities)
            }).catch(err => {
                // console.log(err)
            })
    }, [stateId, updateToggler])

    const editCountryHandler = () => {
        if (countryId.value)
            setShowCountryInputs(true)
    }
    const removeCountryHandler = () => {
        if (countryId && countryId.value)
            axios.delete(`${BASE_URL}/admin/country/${countryId.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                    if (res.status === 200) {
                        setShowSuccess(true)
                        setDeleteCountryAlert(false)
                        setCountryId(null)
                        setUpdateToggler(prevState => !prevState)
                        setTimeout(() => {
                            setShowSuccess(false)
                        }, 2000)
                    }
                }
            )
    }
    const removeStateHandler = () => {
        if (countryId && countryId.value && stateId && stateId.value) {
            axios.delete(`${BASE_URL}/admin/country/${countryId.value}/state/${stateId.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                    if (res.status === 200) {
                        setShowSuccess(true)
                        setDeleteStateAlert(false)
                        setStateId(null)
                        setUpdateToggler(prevState => !prevState)
                        setTimeout(() => {
                            setShowSuccess(false)
                        }, 2000)
                    }
                }
            )
        }
    }
    const removeCityHandler = () => {
        if (countryId && countryId.value && stateId && stateId.value && cityId && cityId.value) {
            axios.delete(`${BASE_URL}/admin/country/${countryId.value}/state/${stateId.value}/city/${cityId.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                    if (res.status === 200) {
                        setShowSuccess(true)
                        setDeleteCityAlert(false)
                        setCityId(null)
                        setUpdateToggler(prevState => !prevState)
                        setTimeout(() => {
                            setShowSuccess(false)
                        }, 2000)
                    }
                }
            )
        }
    }
    const addCountryHandler = () => {
        if (countryId && countryId.value) {
            axios.put(`${BASE_URL}/admin/country/${countryId.value}`, {
                code: countryCode,
                name: countryLabel,
                pre_code: countryPreCode
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/country`, {
                code: countryCode,
                name: countryLabel,
                pre_code: countryPreCode
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const addStateId = () => {
        if (countryId && countryId.value && stateId && stateId.value) {
            axios.put(`${BASE_URL}/admin/country/${countryId.value}/state/${stateId.value}`, {
                name: stateName,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/country/${countryId.value}/state`, {
                name: stateName,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const addCity = () => {
        if (countryId && countryId.value && stateId && stateId.value && cityId && cityId.value) {
            axios.put(`${BASE_URL}/admin/country/${countryId.value}/state/${stateId.value}/city/${cityId.value}`, {
                name: cityName,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/country/${countryId.value}/state/${stateId.value}/city`, {
                name: cityName,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setShowSuccess(true)
                    setUpdateToggler(prevState => !prevState)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    return (
        <div className={`d-flex flex-column`} style={{gridColumn: '1/13'}}>
            <span>Countries:</span>
            <div className={'mb-4'}>
                <div className={`d-flex align-items-center`}>
                    <Select styles={selectStyle} options={countries.map(country => {
                        return {value: country.id, label: country.name, code: country.code, pre_code: country.pre_code}
                    })} onChange={e => {
                        setCountryId(e)
                        setCountryLabel(e ? e.label : '')
                        setCountryCode(e ? e.code : '')
                        setCountryPreCode(e ? e.pre_code : '')
                    }} isClearable={true} value={countryId}/>
                    <button onClick={() => setDeleteCountryAlert(true)}><img style={{width: '20px'}} src={rejectIcon}
                                                                             alt=""/>
                    </button>
                    <button onClick={editCountryHandler}><img style={{width: '20px', filter: 'brightness(0) invert(1)'}}
                                                              src={editIcon} alt=""/></button>
                    <button onClick={() => {
                        setShowCountryInputs(true)
                    }}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
                <div className={showCountryInputs ? '' : 'd-none'}>
                    <span>Country Name:</span>
                    <input className={`location-settings-input`} type="text" defaultValue={countryLabel}
                           onChange={(e) => setCountryLabel(e.target.value)}/>
                    <span>Country Code:</span>
                    <input className={`location-settings-input`} type="text" defaultValue={countryCode}
                           onChange={(e) => setCountryCode(e.target.value)}/>
                    <span>Country Pre Code:</span>
                    <input className={`location-settings-input`} type="text" defaultValue={countryPreCode}
                           onChange={(e) => setCountryPreCode(e.target.value)}/>
                    <button className={'submit-job-button'} style={{width: 'fit-content'}}
                            onClick={addCountryHandler}>Submit
                    </button>

                </div>

            </div>
            <span>States:</span>
            <div className={'mb-4'}>
                <div className={`d-flex align-items-center`}>
                    <Select styles={selectStyle} options={states.map(state => {
                        return {value: state.id, label: state.name}
                    })} onChange={e => {
                        setStateId(e)
                        setStateName(e ? e.label : '')
                    }} isClearable={true} value={stateId}/>
                    <button onClick={() => setDeleteStateAlert(true)}><img style={{width: '20px'}} src={rejectIcon}
                                                                           alt=""/></button>
                    <button onClick={() => {
                        if (stateId && stateId.value)
                            setShowStatesInputs(true)
                    }}><img style={{width: '20px', filter: 'brightness(0) invert(1)'}} src={editIcon} alt=""/>
                    </button>
                    <button onClick={() => {
                        if (countryId.value)
                            setShowStatesInputs(true)
                    }}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
                <div className={showStatesInputs ? '' : 'd-none'}>
                    <input className={`location-settings-input`} type="text" defaultValue={stateName}
                           onChange={e => setStateName(e.target.value)}/>
                    <button className={'submit-job-button'} style={{width: 'fit-content'}} onClick={addStateId}>Submit
                    </button>

                </div>
            </div>
            <div>Cities:</div>
            <div className={'mb-4'}>
                <div className={`d-flex align-items-center`}>
                    <Select styles={selectStyle} options={cities.map(city => {
                        return {value: city.id, label: city.name}
                    })} onChange={e => {
                        setCityId(e)
                        setCityName(e ? e.label : '')
                    }} isClearable={true} value={cityId}/>
                    <button onClick={() => setDeleteCityAlert(true)}><img style={{width: '20px'}} src={rejectIcon}
                                                                          alt=""/></button>
                    <button onClick={() => {
                        if (cityId && cityId.value)
                            setShowCitiesInputs(true)
                    }}><img style={{width: '20px', filter: 'brightness(0) invert(1)'}} src={editIcon} alt=""/>
                    </button>
                    <button onClick={() => {
                        if (countryId.value && stateId.value)
                            setShowCitiesInputs(true)
                    }}><img style={{width: '20px'}} src={addIcon}
                            alt=""/>
                    </button>
                </div>
                <div className={showCitiesInputs ? '' : 'd-none'}>
                    <input className={`location-settings-input`} type="text" defaultValue={cityName}
                           onChange={e => setCityName(e.target.value)}/>
                    <button className={'submit-job-button'} style={{width: 'fit-content'}} onClick={addCity}>Submit
                    </button>

                </div>
            </div>
            <div className={`success-alert ${showSuccess ? '' : 'hide'}`}>
                Done!
            </div>
            <div className={`delete-alert ${deleteCountryAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={removeCountryHandler}>Yes</button>
                    <button onClick={() => setDeleteCountryAlert(false)}>No</button>
                </div>
            </div>
            <div className={`delete-alert ${deleteStateAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={removeStateHandler}>Yes</button>
                    <button onClick={() => setDeleteStateAlert(false)}>No</button>
                </div>
            </div>
            <div className={`delete-alert ${deleteCityAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={removeCityHandler}>Yes</button>
                    <button onClick={() => setDeleteCityAlert(false)}>No</button>
                </div>
            </div>
        </div>
    )
}
export default LocationsSetting;