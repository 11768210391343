import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import rejectIcon from "../../assets/images/reject-icon.png";
import editIcon from "../../assets/images/edit-icon.png";
import addIcon from "../../assets/images/add-icon.png";

const selectStyle = {
    container: (styles) => {
        return ({
            ...styles,
            marginBottom: `22px`,
            width: '100%'
        })
    },
    control: (styles) => {
        return ({
            ...styles,
            border: `1px solid #5f615d`,
            backgroundColor: `#2a2c29`,
            borderRadius: `8px`,
            color: `white`,
            padding: `2px 8px`,
            outline: `none`,
            boxShadow: `none`,
            marginTop: `15px`,
            height: `42px`,
            ':hover': {cursor: `pointer`}
        })
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            background: `#2c2e2b`,
            color: `white`,
            ':hover': {background: `#3e3f3c`, cursor: `pointer`},
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            border: '1px solid #5f615d',
            boxShadow: '0 0 7px 1px black'
        }
    },
    input: (styles) => {
        return {
            ...styles,
            color: 'white'
        }
    },
    menuList: (styles) => {
        return {
            ...styles,
            background: 'red',
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#4b4d48"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }
    },
    noOptionsMessage: (styles) => {
        return ({
            ...styles,
            background: `#2c2e2b`,
        })
    },
    singleValue: (styles) => {
        return ({
            ...styles,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            color: `white`,
        })
    }
}

const DocumentSetting = () => {
    const [docTypes, setDocTypes] = useState([])
    const [docType, setDocType] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [documentType, setDocumentType] = useState([])
    const [fieldOfStudy, setFieldOfStudy] = useState([])
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showFiledDeleteAlert, setShowFiledDeleteAlert] = useState(false)
    const [showPageDeleteAlert, setShowPageDeleteAlert] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showFormEdit, setShowFormEdit] = useState(false)
    const [showFieldOfStudyForm, setShowFieldOfStudyForm] = useState(false)
    const [showFieldOfStudyForm2, setShowFieldOfStudyForm2] = useState(false)
    const [showForm2, setShowForm2] = useState(false)
    const [updateDegrees, setUpdateDegree] = useState(false)
    const [updateFieldOfStudies, setUpdateFieldOfStudies] = useState(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
    const [title, setTitle] = useState('')
    const [showAddSuccess, setShowAddSuccess] = useState(false)
    const [showEditeSuccess, setShowEditSuccess] = useState(false)
    const [documentTypeTitle, setDocumentTypeTitle] = useState('')
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState('')
    const [required, setRequired] = useState(false)
    const [docForPages, setDocForPages] = useState(false)
    const [docForPage, setDocForPage] = useState(false)
    const [documentTypePageTitle, setDocumentTypePageTitle] = useState('')
    const [countryDocumentType, setCountryDocumentType] = useState('')
    const [sequence, setSequence] = useState('')
    const [showEditRequirment, setShowEditRequirment] = useState(false)
    const [documentTypePages, setDocumentTypePages] = useState([])
    const [showPagesForm, setShowPagesForm] = useState([])
    const [docPage, setDocPage] = useState('')

    useEffect(() => {
        axios.get(`${BASE_URL}/entities/countries`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setCountries(res.data.data.countries))
    }, [])
    useEffect(() => {
        if (docForPages && docForPages.value)
            axios.get(`${BASE_URL}/entities/document-types/${docForPages.value}/pages`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.document_type_pages)
                    setDocumentTypePages(res.data.data.document_type_pages)
                else
                    setDocumentTypePages([])
            })
    }, [docForPages])
    useEffect(() => {
        if (country && country.value)
            axios.get(`${BASE_URL}/entities/countries/${country.value}/document-types`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => setDocTypes(res.data.data.country_document_types))
    }, [updateDegrees, country])
    useEffect(() => {
        axios.get(`${BASE_URL}/entities/document-types`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setDocumentTypes(res.data.data.document_types)
        })
    }, [updateFieldOfStudies])
    const editDegreeHandler = () => {
        if (docType && docType.value) {
            setShowFormEdit(true)
            setShowForm(false)
        }
    }
    const editDocumentTypeHandler = () => {
        if (documentType && documentType.value)
            setShowFieldOfStudyForm(true)
    }
    const editDocumentTypeHandler2 = () => {
        if (docForPages && docForPages.value && docPage && docPage.value) {
            setShowForm2(true)
        }
    }
    const AddDegreeHandler = () => {
        setShowForm(true)
        setShowFormEdit(false)
        setDocType(undefined)
    }
    const AddFieldOfStudyHandler = () => {
        setShowFieldOfStudyForm(true)
        setFieldOfStudy(undefined)
    }
    const addPageHandler = () => {
        setShowForm2(true)
        if (docForPages && docForPages.value) {
            setShowFieldOfStudyForm2(true)
            setShowPagesForm(true)
            setDocPage(undefined)
        }
    }
    const deleteCountryDocumentType = () => {
        if (docType && docType.value) {
            axios.delete(`${BASE_URL}/admin/country/${country.value}/document-type/${docType.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateDegree(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
        }
    }
    const deleteFieldOfStudyHandler = () => {
        if (documentType && documentType.value)
            axios.delete(`${BASE_URL}/admin/document-type/${documentType.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowFiledDeleteAlert(false)
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
    }
    const deletePageHandler = () => {
        if (docForPages && docForPages.value && docPage && docPage.value) {
            axios.delete(`${BASE_URL}/admin/document-type/${docForPages.value}/page/${docPage.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowFiledDeleteAlert(false)
                        setShowDeleteSuccess(false)
                        setShowPageDeleteAlert(false)
                    }, 2000)
                }
            })
        }
    }
    const submitDegreeHandler = () => {
        if (country && country.value && docType && docType.value) {
            axios.put(`${BASE_URL}/admin/country/${country.value}/document-type/${docType.value}/toggle-required`, {}, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDocType(prevState => ({value: prevState.value, label: title}))
                    setUpdateDegree(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/country/${country.value}/document-type`, {
                document_type_id: countryDocumentType.value,
                is_required: required
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateDegree(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const submitFieldOfStudyHandler = () => {
        if (documentType && documentType.value) {
            axios.put(`${BASE_URL}/admin/document-type/${documentType.value}`, {
                name: documentTypeTitle
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDocType(prevState => ({value: prevState.value, label: title}))
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/document-type`, {
                name: documentTypeTitle
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const submitFieldOfStudyPagesHandler = () => {
        if (docForPages && docForPages.value && docPage && docPage.value) {
            axios.put(`${BASE_URL}/admin/document-type/${docForPages.value}/page/${docPage.value}`, {
                name: documentTypePageTitle,
                sequence: +sequence,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDocType(prevState => ({value: prevState.value, label: title}))
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/document-type/${docForPages.value}/page`, {
                name: documentTypePageTitle,
                sequence: +sequence,
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }

    return (
        <div className={'jobs-settings'}>
            <span>Country Document Type:</span>
            <div className={`d-flex align-items-center`}>
                <div style={{marginRight: '10px', width: '100%'}}>
                    <Select options={countries.map(({id, name}) => ({value: id, label: name}))} styles={selectStyle}
                            onChange={e => setCountry(e)}/>
                </div>
                <div style={{width: '100%'}}>
                    <Select options={docTypes.map(({id, Name, is_required}) => ({
                        value: id,
                        label: `${Name} ${is_required ? '(Required)' : ''}`,
                        "is_required": is_required
                    }))}
                            styles={selectStyle}
                            onChange={e => {
                                setDocType(e)
                                setRequired(e.is_required)
                            }}/>
                </div>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editDegreeHandler} style={{filter: 'brightness(0) invert(1)',}}>
                        <img style={{width: '20px'}} src={editIcon} alt=""/>
                    </button>
                    <button onClick={AddDegreeHandler}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
                <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deleteCountryDocumentType}>Yes</button>
                        <button onClick={() => setShowDeleteAlert(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={showForm ? '' : 'd-none'}>
                <Select options={documentTypes.map(({id, name}) => ({
                    value: id,
                    label: name
                }))}
                        styles={selectStyle}
                        onChange={e => setCountryDocumentType(e)}/>
                <label className={'d-flex align-items-center justify-content-start mb-4'}>
                    <input type="checkbox" onChange={(e) => {
                        setRequired(e.target.checked)
                    }} style={{width: 'auto', margin: '0 10px 0 0'}}/> <span>Required</span>
                </label>
                <button onClick={submitDegreeHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <div className={showFormEdit ? '' : 'd-none'}>
                <label className={'d-flex align-items-center justify-content-start mb-4'}>
                    <input type="checkbox" onChange={(e) => {
                        setRequired(e.target.checked)
                    }} style={{width: 'auto', margin: '0 10px 0 0'}} checked={required}/> <span>Required</span>
                </label>
                <button onClick={submitDegreeHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <span>Document Type:</span>
            <div className={`d-flex align-items-center`}>
                <Select options={documentTypes.map(({id, name}) => ({value: id, label: name}))} styles={selectStyle}
                        onChange={e => setDocumentType(e)}/>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowFiledDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editDocumentTypeHandler} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={AddFieldOfStudyHandler}><img style={{width: '20px'}} src={addIcon} alt=""/>
                    </button>
                </div>
                <div className={`delete-alert ${showFiledDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deleteFieldOfStudyHandler}>Yes</button>
                        <button onClick={() => setShowFiledDeleteAlert(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={showFieldOfStudyForm ? '' : 'd-none'}>
                <input type="text" value={documentTypeTitle} onChange={e => setDocumentTypeTitle(e.target.value)}
                       placeholder={'Document Type Title'}/>
                <button onClick={submitFieldOfStudyHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <span>Document Type Page:</span>
            <div className={`d-flex align-items-center`}>
                <div style={{width: '100%', marginRight: '10px'}}>
                    <Select options={documentTypes.map(({id, name}) => ({value: id, label: name}))}
                            styles={selectStyle}
                            onChange={e => setDocForPages(e)}/>
                </div>
                <div style={{width: '100%'}}>
                    <Select options={documentTypePages.map(({id, name}) => ({value: id, label: name}))}
                            styles={selectStyle}
                            onChange={e => setDocPage(e)}/>
                </div>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowPageDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editDocumentTypeHandler2} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={addPageHandler}><img style={{width: '20px'}} src={addIcon} alt=""/>
                    </button>
                </div>
                <div className={`delete-alert ${showFiledDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deleteFieldOfStudyHandler}>Yes</button>
                        <button onClick={() => setShowFiledDeleteAlert(false)}>No</button>
                    </div>
                </div>
                <div className={`delete-alert ${showPageDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deletePageHandler}>Yes</button>
                        <button onClick={() => showPageDeleteAlert(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={showForm2 ? '' : 'd-none'}>
                <input type="text" value={documentTypePageTitle}
                       onChange={e => setDocumentTypePageTitle(e.target.value)}
                       placeholder={'Document Page Title'}/>
                <input type="number" value={sequence} onChange={e => setSequence(e.target.value)}
                       placeholder={'Sequence'}/>
                <button onClick={submitFieldOfStudyPagesHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <div className={`success-alert ${showAddSuccess ? '' : 'hide'}`}>
                Added Successfully!
            </div>
            <div className={`success-alert ${showEditeSuccess ? '' : 'hide'}`}>
                Edited Successfully!
            </div>
            <div className={`success-alert ${showDeleteSuccess ? '' : 'hide'}`}>
                Deleted Successfully!
            </div>
        </div>
    )
}

export default DocumentSetting;