// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verification-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 28px;
}

.verification-code-container input {
    padding: 8px 16px;
    height: 58px;
    width: 57px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-family: Inter, serif;
}

.mobile-icon {
    margin-bottom: 25px;
}

.phone-number {
    margin-top: 28px;
    font-size: 18px;
    font-family: Inter, serif;
    font-weight: 600;
    margin-bottom: 28px;
}

.verification-desc {
    font-size: 14px;
    font-weight: 600;
    font-family: Inter, serif;
    margin-bottom: 12px;
}

.verification-form{
    padding: 99px 146px !important;
}`, "",{"version":3,"sources":["webpack://./src/scenes/authentication/Verification.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".verification-code-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n    margin-bottom: 28px;\n}\n\n.verification-code-container input {\n    padding: 8px 16px;\n    height: 58px;\n    width: 57px;\n    text-align: center;\n    font-size: 24px;\n    font-weight: 600;\n    font-family: Inter, serif;\n}\n\n.mobile-icon {\n    margin-bottom: 25px;\n}\n\n.phone-number {\n    margin-top: 28px;\n    font-size: 18px;\n    font-family: Inter, serif;\n    font-weight: 600;\n    margin-bottom: 28px;\n}\n\n.verification-desc {\n    font-size: 14px;\n    font-weight: 600;\n    font-family: Inter, serif;\n    margin-bottom: 12px;\n}\n\n.verification-form{\n    padding: 99px 146px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
