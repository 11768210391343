import React, {useEffect, useState} from 'react';
import Modal from "./Modal";
import editIcon from '../assets/images/edit-icon-white.png'
import axios from "axios";
import BASE_URL from "../api/api";

const Question = ({
                      currentQuestionChoicesTranslations,
                      newInputType,
                      newQuestionType,
                      questionTitleTranslation,
                      handleuestionInEdit,
                      children,
                      question,
                      handleInputsInEdit,
                      setShowEditedSuccessfully,
                      editPollId,
                      allChoicesType,
                      type,
                      formId,
                      forms,
                      langs
                  }) => {
    const {inputs} = question
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    function handleQuestionSubmit() {
        setIsLoading(true)
        const newQueType = newQuestionType ? newQuestionType.value : question.type

        let questionData = {
            ...question
        }
        if (newInputType.value !== 'INPUT') {
            questionData.type = newQueType
        }

        axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}`,
            questionData,
            {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
            setIsLoading(false)
            inputs.map((input) => {
                if (input.id !== undefined) {
                    const newInpType = newInputType ? newInputType.value : allChoicesType.value
                    axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice/${input.id}`, {
                        answer: input.inputValue,
                        order: +input.order,
                        rate: +input.rate,
                        type: newInpType
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            setShowEditedSuccessfully(true)
                            setTimeout(() => {
                                setShowEditedSuccessfully(false)
                            }, 2000)
                            setIsLoading(false)
                        }
                    }).catch(err => {
                        // console.log('input', err)
                    })
                } else {
                    const newInpType = newInputType ? newInputType.value : allChoicesType.value
                    axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice`, {
                        answer: input.inputValue,
                        order: +input.order,
                        rate: +input.rate,
                        type: newInpType
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        setIsLoading(false)
                        // console.log(res)
                    }).catch(err => {
                        // console.log(err)
                    })
                }
            })
            let savedForm = forms.find(item => item.formId === formId)
            console.log(langs)
            langs.forEach(lang => {
                if (lang.id !== "6533c65a7b8ccffa90a73450") {
                    if (savedForm.translations[lang.id]) {
                        axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/translations`,
                            {
                                language_id: lang.id,
                                question: savedForm.translations[lang.id]
                            }
                            , {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                            console.log(res)
                            setIsLoading(false)
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                    console.log(savedForm)
                }
                savedForm.inputs.forEach(input => {
                    if (input.translations[lang.id]) {
                        axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice/${input.id}/translations`,
                            {
                                language_id: lang.id,
                                choice: input.translations[lang.id]
                            }
                            , {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                            setIsLoading(false)
                        }).catch(err => {
                            console.log(err)
                        })
                    } else {
                        axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice/${input.id}/translations`,
                            {
                                language_id: lang.id,
                                choice: input.inputValue
                            }
                            , {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                            setIsLoading(false)
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                })
            })

            // if (questionTitleTranslation) {
            //     questionTitleTranslation.map(qtt => {
            //         axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/translations`,
            //             {
            //                 language_id: qtt.id,
            //                 question: qtt.question
            //             }
            //             , {
            //                 headers: {
            //                     "Accept": "*/*",
            //                     'Content-Type': 'application/json',
            //                     'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            //                 }
            //             }).then(res => {
            //             setIsLoading(false)
            //         })
            //     })
            // }

            // if (currentQuestionChoicesTranslations) {
            //     currentQuestionChoicesTranslations.map(questionChoicesTrans => {
            //         questionChoicesTrans.translations.map(qcht => {
            //             axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice/${questionChoicesTrans.id}/translations`, {
            //                 language_id: qcht.language_id,
            //                 choice: qcht.choice
            //             }, {
            //                 headers: {
            //                     "Accept": "*/*",
            //                     'Content-Type': 'application/json',
            //                     'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            //                 }
            //             }).then(res => {
            //                 setIsLoading(false)
            //             })
            //             console.log('trans', qcht)
            //         })
            //     })
            // }
        })
    }

    return (
        <div>
            <button onClick={() => {
                setModalOpen(true);
                handleuestionInEdit(question.formId);
                handleInputsInEdit(question.inputs)
            }}><img style={{width: '20px'}} src={editIcon} alt=""/></button>
            <Modal isLoading={isLoading} handleSubmit={handleQuestionSubmit} isOpen={isModalOpen}
                   onClose={() => setModalOpen(false)}>
                {children}
            </Modal>
        </div>
    );
};

export default Question;