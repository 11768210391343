import React, {useState} from 'react';
import axios from "axios";
import BASE_URL from "../api/api";
import Document from "./Document";
import ImageLightbox from "./ImageLightbox";

const Documents = ({documents, handleVerifiedDocsNum}) => {
    const [showImgPopup, setShowImgPopup] = useState(false)
    const [lightBoxImg, setLightboxImg] = useState({})

    return (
        <>
            <div style={{gridColumn: '1/13', margin: '0 -12px'}} className={'row position-relative'}>
                {documents.map(document => {
                    return <Document key={document.id} handleOpenLightBox={setShowImgPopup} handleSetLightboxImg={setLightboxImg} document={document}
                                     handleVerifiedDocsNum={handleVerifiedDocsNum}/>
                })}
            </div>
            <div>
                {showImgPopup ?
                    <ImageLightbox setShowImgPopup={setShowImgPopup} lightBoxImg={lightBoxImg} />
                    : null}
            </div>
        </>
    );
};

export default Documents;