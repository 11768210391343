import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import BASE_URL from "../../api/api";
import axios from "axios";

const User = () => {
    const {userId} = useParams();
    const [userProfile, setUserProfile] = useState([]);
    const [photoUrl, setPhotoUrl] = useState('');
    const [job, setJob] = useState([])
    const [location, setLocation] = useState([])

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/entities/users/profiles/${userId}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );
                const userProfileData = response.data.data.user_profile;
                let job
                if (userProfileData.job_id !== null)
                    job = await axios.get(
                        `https://api.globalvalidity.app/entities/jobs/${userProfileData.job_id}`,
                        {
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    );
                let country = await axios.get(
                    `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}`,
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                )
                country = country.data.data.country.name
                let state
                try {
                    state = await axios.get(
                        `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}/states/${userProfileData.location.country_state_id}`,
                        {
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    )
                    state = state.data.data.country_state.name
                } catch (err) {
                    // console.log(err)
                }
                let city
                try {
                    city = await axios.get(
                        `https://api.globalvalidity.app/entities/countries/${userProfileData.location.country_id}/states/${userProfileData.location.country_state_id}/cities/${userProfileData.location.country_state_city_id}`,
                        {
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    )
                    city = city.data.data.country_state_city.name
                } catch (err) {
                    // console.log(err)
                }
                setLocation(`${country}, ${state}, ${city}`)
                setJob(job.data.data.job.title)
                setUserProfile(userProfileData)

                // Fetch and display the user's profile photo
                let photoResponse
                if (userId !== null)
                    photoResponse = await axios.get(
                        `${BASE_URL}/entities/users/profiles/${userId}/photo`,
                        {
                            responseType: 'arraybuffer',
                            headers: {
                                accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    );

                // Convert the received binary data to a Data URL
                const blob = new Blob([photoResponse.data], {type: "image/png"});
                const dataURL = URL.createObjectURL(blob);
                setPhotoUrl(dataURL);
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
        fetchUserProfile();
    }, [userId]);

    return (
        <div className={`d-flex align-items-start`} style={{gridColumn: `1/13`}}>
            <div
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '30px'}}>
                <img src={photoUrl} style={{borderRadius: '50%', width: '150px'}} alt="User Profile"/>
            </div>
            <div className={`users-information`}>
                <div className={`d-flex`}>
                    Birthdate: {userProfile.birthdate || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Experience: {userProfile.experience || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    First Name: {userProfile.firstname || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Gender: {userProfile.gender || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Verification status: {`${userProfile.is_verified}` || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Job: {job || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Last Name: {userProfile.lastname || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Location: {location || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Marital Status: {userProfile.marital_status || 'N/A'}
                </div>
                <br/>
                <div className={`d-flex`}>
                    Type: {userProfile.type || 'N/A'}
                </div>
            </div>
        </div>
    );
};

export default User;
