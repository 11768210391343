import React, {useEffect, useState} from 'react';
import Modal from "./Modal";
import editIcon from '../assets/images/edit-icon-white.png'
import axios from "axios";
import BASE_URL from "../api/api";

const Rate = ({rate, children, editPollId, submitQueHandler}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    function handleQuestionSubmit () {

        setIsLoading(true)
        axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}`,
            question,
            {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            setIsLoading(false)
            inputs.map((input) => {
                if (input.id !== undefined) {
                    axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice/${input.id}`, {
                        answer: input.inputValue,
                        order: +input.order,
                        rate: +input.rate,
                        type: allChoicesType.value
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            setShowEditedSuccessfully(true)
                            setTimeout(() => {
                                setShowEditedSuccessfully(false)
                            }, 2000)
                        }
                    }).catch(err => {
                        // console.log('input', err)
                    })
                } else {
                    axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.formId}/choice`, {
                        answer: input.inputValue,
                        order: +input.order,
                        rate: +input.rate,
                        type: type
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        setIsLoading(false)
                        // console.log(res)
                    }).catch(err => {
                        // console.log(err)
                    })
                }

            })
        })
    }

    return (
        <div>
            <button onClick={() => setModalOpen(true)}><img style={{width: '20px'}} src={editIcon} alt=""/></button>
            <Modal isLoading={isLoading} handleSubmit={submitQueHandler} isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                {children}
            </Modal>
        </div>
    );
};

export default Rate;