export const selectStyle = {
    // Define your select styles here
};

export const initialValues = {
    countries: [],
    // Other initial values
};

export const axiosConfig = {
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
    }
};

// Add other utility functions or constants as needed

export function containsHtmlTags(str) {
    const pattern = /<[^>]+>/; // Basic pattern for finding HTML-like strings
    return pattern.test(str);
}

export function maskPhone(phone) {
    return phone.replace(/(\+)(.*)(..)/, (match, p1, p2, p3) => p1 + '*'.repeat(p2.length) + p3);
}
