import {ResponsivePie} from '@nivo/pie'
import {useEffect, useState, useRef} from "react";
import {data} from "franc/data";
import ExportLog from "../ExportLog";
import {exportChartAsImage} from "../../../util/exportHelper";
import "../../../assets/css/log-table.css"
import {ResponsiveLine} from "@nivo/line";

const PieChart = ({stats, className}) => {
    const chartRef = useRef();
    const barChartRef = useRef();

    function getText(html) {
        let divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    const barChartColors = ['hsl(240, 70%, 50%)', 'hsl(352, 70%, 50%)', 'hsl(213, 70%, 50%)', 'hsl(288, 70%, 50%)', 'hsl(272, 70%, 50%)', 'hsl(228, 70%, 50%)', 'hsl(354, 70%, 50%)', 'hsl(195, 70%, 50%)', 'hsl(156, 70%, 50%)', 'hsl(171, 70%, 50%)', 'hsl(108, 70%, 50%)']

    return (
        <div className={`${className}`} style={{color: 'black'}}>
            <span className={`main-heading`}>Title: {stats.title}</span>
            {stats.questions ? stats.questions.map((que, index) => { console.log(stats.questions)
                return (
                    <div key={index} style={{marginTop: '30px'}}>
                        <div>
                            <span style={{color: '#ffffff'}}>Question: {getText(que.title)}</span>
                            {que.choices.map((item, index) => {
                                return (
                                    <div key={index}
                                         style={{color: 'white', marginTop: '30px'}}>{index + 1}: {item.choice}</div>
                                )
                            })}
                        </div>
                        <div style={{margin: '3rem 0'}}>
                            <ExportLog currentQuestion={que} pollId={stats.poll_id} result={que}/>
                        </div>
                        <div ref={chartRef} style={{width: '600px', height: '500px', margin: 'auto'}}>
                            <ResponsivePie
                                data={que.choices.map((item, index) => {
                                    return (
                                        {
                                            "id": `${item.choice}`,
                                            "label": `${item.choice}`,
                                            "value": `${index * 2}`
                                        }
                                    )
                                })}
                                margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#FFFFFF"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{from: 'color'}}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: '#FFFFFF',
                                    modifiers: [
                                        [
                                            'darker',
                                            2
                                        ]
                                    ]
                                }}
                                // defs={[
                                //     {
                                //         id: 'dots',
                                //         type: 'patternDots',
                                //         background: 'inherit',
                                //         color: 'rgba(255, 255, 255, 0.3)',
                                //         size: 4,
                                //         padding: 1,
                                //         stagger: true
                                //     },
                                //     {
                                //         id: 'lines',
                                //         type: 'patternLines',
                                //         background: 'inherit',
                                //         color: 'rgba(255, 255, 255, 0.3)',
                                //         rotation: -45,
                                //         lineWidth: 6,
                                //         spacing: 10
                                //     }
                                // ]}

                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 0,
                                        translateY: 56,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#FFFFFF'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                            <div style={{marginTop: '2rem'}}>
                                <button className="add-poll__button" onClick={() => exportChartAsImage(chartRef)}>Export
                                    as Image
                                </button>
                            </div>
                        </div>
                        <div ref={barChartRef} style={{width: '700px', height: '500px', margin: '120px auto'}}>
                            <ResponsiveLine
                            data={[
                                {
                                    "id": "Votes",
                                    "color": "hsl(221,76%,50%)",
                                    "data": que.choices.map((item, index) => ({
                                        "x": item.choice,
                                        "y": index * 2,
                                    }))
                                }
                            ]}
                                keys={que.choices.map((item) => item.choice)}
                                indexBy="id"
                                margin={{top: 50, right: 130, bottom: 50, left: 60}}
                                padding={0.2}
                                colors={{scheme: 'nivo'}}
                                borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Result',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1,
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                            />
                            <div style={{marginTop: '2rem'}}>
                                <button className="add-poll__button" onClick={() => exportChartAsImage(barChartRef)}>Export
                                    as Image
                                </button>
                            </div>
                        </div>

                    </div>
                )
            }) : <p style={{color: '#fff'}}>No log available.</p>}
            {/*<div>*/}
            {/*    <div style={{marginTop: `20px`, color: 'white', display: 'flex', alignItems: 'center', gap: `10px`}}>*/}
            {/*        <div style={{background: `#1f77b4`, width: `10px`, height: `10px`}}></div>*/}
            {/*        <span>Choice 1:</span>*/}
            {/*        <span>{`${(90 / (160 + 90 + 676)) * 100}`}%</span>*/}
            {/*    </div>*/}
            {/*    <div style={{marginTop: `20px`, color: 'white', display: 'flex', alignItems: 'center', gap: `10px`}}>*/}
            {/*        <div style={{background: '#ff7f0e', width: `10px`, height: `10px`}}></div>*/}
            {/*        <span>Choice 1:</span>*/}
            {/*        <span>{`${(160 / (160 + 90 + 676)) * 100}`}%</span>*/}
            {/*    </div>*/}
            {/*    <div style={{marginTop: `20px`, color: 'white', display: 'flex', alignItems: 'center', gap: `10px`}}>*/}
            {/*        <div style={{background: '#2ca02c', width: `10px`, height: `10px`}}></div>*/}
            {/*        <span>Choice 1:</span>*/}
            {/*        <span>{`${(676 / (160 + 90 + 676)) * 100}`}%</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default PieChart;