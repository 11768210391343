import {SketchPicker} from "react-color";
import {useEffect, useState} from "react";
import Select from "react-select";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {convertToRaw, EditorState, ContentState} from 'draft-js';
import Editor from "../../Editor";
import draftToMarkdown from 'draftjs-to-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import closeIcon from '../../assets/images/reject-icon.png'
import axios from "axios";
import BASE_URL from "../../api/api";

const Settings = () => {

    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                marginTop: `15px`,
                height: `42px`,
                ':hover': {cursor: `pointer`},
                '> div': {height: '100%'}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        ValueContainer: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                height: `100%`,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
            })
        }
    }

    const [appIntro1, setAppIntro1] = useState('');
    const [appIntro2, setAppIntro2] = useState('');
    const [lang, setLang] = useState('');
    const [ELUA, setELUA] = useState('');
    const [aboutUs, setAboutUs] = useState('');
    const [contactUs, setContactUs] = useState('');
    const [appWelcomeMessage, setAppWelcomeMessage] = useState('');
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    // const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
    const [successAlert, setSuccessAlert] = useState(false)
    const onEditorStateChange = (html) => {
        setELUA(html)
    };
    const onEditor2StateChange = (html) => {
        setAboutUs(html)
    };
    const onEditor3StateChange = (html) => {
        setContactUs(html)
    };

    useEffect(() => {
        axios.get(`${BASE_URL}/admin/settings`, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            let data = res.data.data.settings
            data.forEach(setting => {
                if (setting.name === 'app_welcome_message') {
                    setAppWelcomeMessage(setting.value)
                }
                if (setting.name === 'app_intro_1') {
                    setAppIntro1(setting.value)
                }
                if (setting.name === 'app_intro_2') {
                    setAppIntro2(setting.value)
                }
                if (setting.name === 'lang') {
                    setLang(setting.value)
                }
                if (setting.name === 'html_elua') {
                    setELUA(setting.value)
                    let blocksFromHtml = htmlToDraft(setting.value)
                    const {contentBlocks, entityMap} = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const editorState = EditorState.createWithContent(contentState);
                    // setEditorState(editorState)
                }
                if (setting.name === 'html_about_us') {
                    setAboutUs(setting.value)
                    let blocksFromHtml = htmlToDraft(setting.value)
                    const {contentBlocks, entityMap} = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const editorState = EditorState.createWithContent(contentState);
                    // setEditorState2(editorState)
                }
                if (setting.name === 'html_contact_us') {
                    setContactUs(setting.value)
                    let blocksFromHtml = htmlToDraft(setting.value)
                    const {contentBlocks, entityMap} = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const editorState = EditorState.createWithContent(contentState);
                    // setEditorState3(editorState)
                }
            })
        })
    }, [])

    const test = () => {
        let data = [
            {name: "app_welcome_message", value: appWelcomeMessage},
            {name: "app_intro_1", value: appIntro1},
            {name: "app_intro_2", value: appIntro2},
            {name: "lang", value: lang},
            {name: "html_elua", value: ELUA},
            {name: "html_about_us", value: aboutUs},
            {name: "html_contact_us", value: contactUs}
        ]
        let showSuccess = 0;
        data.forEach(({name, value}) => {
            console.log(name,
                value)
            axios.post(`${BASE_URL}/admin/settings`, {
                "is_public": true,
                name,
                value
            }, {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
                if (res.status === 200) {
                    showSuccess++
                    if (showSuccess === 7) {
                        setSuccessAlert(true)
                        setTimeout(() => {
                            setSuccessAlert(false)
                        }, 3000)
                    }
                }
            })
        })
    }

    return (
        <>
            <div style={{gridColumn: '1/13'}} className={`settings`}>
                <h4>App welcome message:</h4>
                <textarea name="" id="" cols="30" rows="10" value={appWelcomeMessage} onChange={(e) => {
                    setAppWelcomeMessage(e.target.value)
                }}></textarea>
                <h4>App intro page 1 text:</h4>
                <textarea name="" id="" cols="30" rows="10" value={appIntro1} onChange={(e) => {
                    setAppIntro1(e.target.value)
                }}></textarea>
                <h4>App intro page 2 text:</h4>
                <textarea name="" id="" cols="30" rows="10" value={appIntro2} onChange={(e) => {
                    setAppIntro2(e.target.value)
                }}></textarea>
                <h4>App default language:</h4>
                <Select styles={selectStyle}
                        options={[{value: 'En', label: 'En'}, {value: 'Fa', label: 'Fa'}, {value: 'Ar', label: 'Ar'},]}
                        value={{label: lang, value: lang}}
                        onChange={(e) => {
                            setLang(e.value)
                        }}
                />
                <div>
                    <h4>ELUA:</h4>
                    <Editor
                        placeholder={`Edit result`}
                        initialValue={ELUA}
                        onUpdate={(html) => onEditorStateChange(html)}
                    />
                    {/*<Editor*/}
                    {/*    editorState={editorState}*/}
                    {/*    wrapperClassName="text-editor-wrapper"*/}
                    {/*    editorClassName="text-editor"*/}
                    {/*    onEditorStateChange={onEditorStateChange}*/}
                    {/*/>*/}
                </div>
                <div>
                    <h4>About Us:</h4>
                    <Editor
                        placeholder={`Edit result`}
                        initialValue={aboutUs}
                        onUpdate={(html) => onEditor2StateChange(html)}
                    />
                    {/*<Editor*/}
                    {/*    editorState={editorState2}*/}
                    {/*    wrapperClassName="text-editor-wrapper"*/}
                    {/*    editorClassName="text-editor"*/}
                    {/*    onEditorStateChange={onEditor2StateChange}*/}
                    {/*/>*/}
                </div>
                <div>
                    <h4>Contact Us:</h4>
                    <Editor
                        placeholder={`Edit result`}
                        initialValue={contactUs}
                        onUpdate={(html) => onEditor3StateChange(html)}
                    />
                    {/*<Editor*/}
                    {/*    editorState={editorState3}*/}
                    {/*    wrapperClassName="text-editor-wrapper"*/}
                    {/*    editorClassName="text-editor"*/}
                    {/*    onEditorStateChange={onEditor3StateChange}*/}
                    {/*/>*/}
                </div>
            </div>
            <button className={'add-poll__button'} style={{width: '120px', maxWidth: 'unset'}} onClick={test}>Save
            </button>
            <div className={`success-alert ${successAlert ? '' : 'hide'}`}>
                Poll Added Successfully!
            </div>
        </>
    )
}

export default Settings;