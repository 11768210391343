// hooks
import {useEffect, useState} from "react";

//global
import ProjectCard from "./global/ProjectCard"
import PieChart from "./global/PieChart";
import BASE_URL from "../../api/api";
//style
import './index.css'
import Table from "./global/Table";
import axios from "axios";
import folderNotch from "../../assets/images/folder-notch.svg";

const Dashboard = () => {
    const [totalPolls, setTotalPolls] = useState('')
    const [totalActivePolls, setTotalActivePolls] = useState('')
    const [totalClosedPolls, setTotalClosedPolls] = useState('')
    const [usersCount, setUsersCount] = useState()

    useEffect(() => {
        var currentDate = new Date();
        // axios.get(`${BASE_URL}/admin/users/profiles`, {
        //     headers: {
        //         accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
        //     },
        // }).then(res => console.log(res)).catch(err => {
        //     console.log(err)
        // })
        axios.get(`${BASE_URL}/entities/polls?active=true`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setTotalActivePolls(res.data.data.total_items)).catch(err => {

        })
        axios.get(`${BASE_URL}/admin/polls?closed=true&count_only=true&page=1`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.data.data.total_items === undefined)
                setTotalClosedPolls(0)
            else
                setTotalClosedPolls(res.data.data.total_items)
        }).catch(err => {

        })
        axios.get(`${BASE_URL}/admin/polls?count_only=true&page=1`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setTotalPolls(res.data.data.count)).catch(err => {

        })
        axios.get(`${BASE_URL}/admin/users/profiles?page=1&limit=10`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            // console.log(res)
        }).catch(err => {
            console.log(err)
        })
        axios.get(`${BASE_URL}/admin/users/profiles?count_only=true&page=1`, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            setUsersCount(res.data.data.count)
        })
    }, [])
    return (
        <>
            <section className={`project-cards-section`}>
                <h3 className={`main-heading`}>Summery</h3>
                <div className={`project-cards-container`}>
                    <ProjectCard title={`Total Polls`} number={totalPolls}/>
                    <ProjectCard title={`Total Active Polls`} number={totalActivePolls}/>
                    <ProjectCard title={`Total Closed Polls`} number={totalClosedPolls}/>
                    <ProjectCard title={`Total Users`} number={usersCount}/>
                </div>
            </section>

            {/*<PieChart className={`index__pie-chart-container`}>Project Status</PieChart>*/}
            <Table className={`primary-container index__users-table-container`}>Users</Table>
            <div></div>
        </>
    )
}
export default Dashboard;