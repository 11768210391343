import * as XLSX from 'xlsx';
import {getCurrentDateTimeForFilename} from "./dateHelper";
import {maskPhone} from "./pollHelper";

// const data = [
//     { name: "John", email: "john@example.com" },
//     { name: "Jane", email: "jane@example.com" }
//     // ... more data
// ];

export function exportToExcel(data, fileName) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write and export the workbook
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export function filterKeysByActive(config, data) {
    // First, get the keys that are active
    const activeKeys = config.filter(item => item.isActive).map(item => item.key);

    // Then, filter the keys in each object of the data array
    return data.map(obj => {
        return activeKeys.reduce((acc, key) => {
            if (obj.hasOwnProperty(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
    });
}


// Chart export
export function exportChartAsImage(chartRef) {
    if (!chartRef.current) {
        console.error('Chart ref is not assigned');
        return;
    }

    const svg = chartRef.current.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);

    const canvas = document.createElement('canvas');
    const svgSize = svg.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    const ctx = canvas.getContext('2d');

    // Set the background color to white
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const img = new Image();

    img.onload = function() {
        ctx.drawImage(img, 0, 0);
        const imgsrc = canvas.toDataURL("image/png");

        // Create a download link and click it
        const downloadLink = document.createElement('a');
        downloadLink.href = imgsrc;
        downloadLink.download = "chart"+ getCurrentDateTimeForFilename() +".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
}

// Add this function to a button's onClick handler



export function transformData(users) {
    return users.map(user => {
        // Extracting nested experience data with fallbacks
        const { over_30_years, years } = user.experience || {};
        const jobYearsExperience = over_30_years ? "30+" : years;

        // Flattening contact_methods into first level with type as key and value as value
        let contactInfo = {};
        if (user.contact_methods) {
            user.contact_methods.forEach(contact => {
                contactInfo[contact.type.toLowerCase()] = contact.value;
            });
        }

        if (contactInfo && contactInfo.hasOwnProperty('phone')) {
            contactInfo.phone = maskPhone(contactInfo.phone)
        }

        // Flattening location into first level
        const { country, state, city } = user.location || {};

        // Transforming and renaming keys as specified
        return {
            user_id: user.user_id,
            firstname: user.firstname,
            lastname: user.lastname,
            full_name: user.full_name,
            username: user.username,
            gender: user.gender,
            BirthDate: user.birthdate.split('T')[0], // Extracting date part
            MartialStatus: user.marital_status,
            Score: user.reward_score,
            Rate: user.rate,
            'Register Date': user.registered_at.split('T')[0], // Extracting date part
            educations: user.educations,
            job: user.job,
            ...contactInfo, // Spread flattened contact methods
            Country: country,
            State: state,
            City: city,
            'Job Years Experience': jobYearsExperience // Using transformed years experience
        };
    });
}
