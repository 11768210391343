import axios from "axios";
import BASE_URL from "../api/api";
import {useEffect} from "react";

export function updatePollQuestionTranslation(languageId, questionTitle) {

}

export function getPollQuestionTranslations(pollId, pollQuestionId, langs, setStateHandle, forms, setForms) {
    axios.get(`${BASE_URL}/admin/poll/${pollId}/question/${pollQuestionId}`, {
        headers: {
            "Accept": "*/*",
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
        }
    }).then(res => {
        // const {translations} = res.data.data.poll_question
        // let oldForms = forms
        // oldForms.forEach(form => {
        //     if (translations !== null) {
        //         translations.forEach(translation => {
        //             if (form.formId === translation.poll_question_id) {
        //                 form.translations[translation.language_id] = translation.question
        //             }
        //         })
        //     }
        // })
        // setForms([...oldForms])
        // let langsTranslationsArray = []
        // if (translations && translations.length) {
        //     translations.map(lang => {
        //         if (lang.id !== "6533c65a7b8ccffa90a73450") {
        //             const transLang = {
        //                 id: lang.language_id,
        //                 question: lang.question
        //             }
        //
        //             langsTranslationsArray.push(transLang)
        //         }
        //     })
        //     return setStateHandle(langsTranslationsArray)
        // }
        //
        // langs.map(lang => {
        //     if (lang.id !== "6533c65a7b8ccffa90a73450") {
        //         const emptyLang = {
        //             id: lang.id,
        //             question: ""
        //         }
        //
        //         langsTranslationsArray.push(emptyLang)
        //     }
        // })
        //
        // setStateHandle(langsTranslationsArray)
        console.log(res)
        let translations = res.data.data.poll_question.translations
        console.log(translations)
        let formsHolder = forms;
        formsHolder.forEach(form => {
            if (translations) {
                translations.forEach(translation => {
                    if (translation.poll_question_id === form.formId) {
                        langs.forEach(lang => {
                            console.log(lang)
                            if (lang.id !== '6533c65a7b8ccffa90a73450') {
                                if (translation.language_id === lang.id) {
                                    form.translations[lang.id] = translation.question
                                }
                            }
                        })
                    }
                })
            }
        })
        console.log(formsHolder)
    })
}

export function getChoicesOfQuestion(pollId, pollQuestionId, langs, currentInputsQuestion, setStateHandle) {
    axios.get(`${BASE_URL}/admin/poll/${pollId}/question/${pollQuestionId}/choices?page=0&limit=999`, {
        headers: {
            "Accept": "*/*",
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
        }
    }).then(res => {
        console.log(res)
        const queChoices = res.data.data.poll_question_choices

        const removeLanguageId = "6533c65a7b8ccffa90a73450";

// Transform and inject translations with language details, then remove the specified translation
        const updatedcurrentInputsQuestion = currentInputsQuestion.map(secondItem => {
            // Find the corresponding poll from the first structure
            const correspondingPoll = queChoices.find(poll => poll.poll_question_choice.id === secondItem.id);

            if (correspondingPoll) {
                let newTranslationsArray = correspondingPoll.translations.map(translation => {
                    // Find the corresponding language details
                    const languageDetails = langs.find(lang => lang.id === translation.language_id);

                    return {
                        ...translation,
                        // Add language details (code and name) to each translation
                        language_name: languageDetails ? languageDetails.name : undefined,
                        language_code: languageDetails ? languageDetails.code : undefined
                    };
                });

                // Filter out the translation with the specified language_id to remove
                newTranslationsArray = newTranslationsArray.filter(translation => translation.language_id !== removeLanguageId);

                // Return the updated secondItem with new translations array
                return {...secondItem, translations: newTranslationsArray};
            }

            // If no corresponding poll is found, return the secondItem unchanged
            return secondItem;
        });

        setStateHandle(updatedcurrentInputsQuestion);

    })
}

