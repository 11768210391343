import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import rejectIcon from "../../assets/images/reject-icon.png";
import editIcon from "../../assets/images/edit-icon.png";
import addIcon from "../../assets/images/add-icon.png";

const selectStyle = {
    container: (styles) => {
        return ({
            ...styles,
            marginBottom: `22px`,
            width: '100%'
        })
    },
    control: (styles) => {
        return ({
            ...styles,
            border: `1px solid #5f615d`,
            backgroundColor: `#2a2c29`,
            borderRadius: `8px`,
            color: `white`,
            padding: `2px 8px`,
            outline: `none`,
            boxShadow: `none`,
            marginTop: `15px`,
            height: `42px`,
            ':hover': {cursor: `pointer`}
        })
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            background: `#2c2e2b`,
            color: `white`,
            ':hover': {background: `#3e3f3c`, cursor: `pointer`},
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            border: '1px solid #5f615d',
            boxShadow: '0 0 7px 1px black'
        }
    },
    input: (styles) => {
        return {
            ...styles,
            color: 'white'
        }
    },
    menuList: (styles) => {
        return {
            ...styles,
            background: 'red',
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#4b4d48"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }
    },
    noOptionsMessage: (styles) => {
        return ({
            ...styles,
            background: `#2c2e2b`,
        })
    },
    singleValue: (styles) => {
        return ({
            ...styles,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            color: `white`,
        })
    }
}

const EducationSettings = () => {
    const [degrees, setDegrees] = useState([])
    const [fieldOfStudies, setFieldOfStudies] = useState([])
    const [fieldOfStudy, setFieldOfStudy] = useState([])
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showFiledDeleteAlert, setShowFiledDeleteAlert] = useState(false)
    const [degree, setDegree] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [showFieldOfStudyForm, setShowFieldOfStudyForm] = useState(false)
    const [updateDegrees, setUpdateDegree] = useState(false)
    const [updateFieldOfStudies, setUpdateFieldOfStudies] = useState(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
    const [title, setTitle] = useState('')
    const [showAddSuccess, setShowAddSuccess] = useState(false)
    const [showEditeSuccess, setShowEditSuccess] = useState(false)
    const [fieldOfStudyTitle, setFieldOfStudyTitle] = useState('')


    useEffect(() => {
        axios.get(`${BASE_URL}/entities/degrees`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setDegrees(res.data.data.degrees))
    }, [updateDegrees])
    useEffect(() => {
        axios.get(`${BASE_URL}/entities/fields-of-study`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => setFieldOfStudies(res.data.data.fields_of_study))
    }, [updateFieldOfStudies])
    const editDegreeHandler = () => {
        if (degree && degree.value)
            setShowForm(true)
    }
    const editFieldOfStudyHandler = () => {
        if (fieldOfStudy && fieldOfStudy.value)
            setShowFieldOfStudyForm(true)
    }
    const AddDegreeHandler = () => {
        setShowForm(true)
        setDegree(undefined)
    }
    const AddFieldOfStudyHandler = () => {
        setShowFieldOfStudyForm(true)
        setFieldOfStudy(undefined)
    }
    const deleteDegreeHandler = () => {
        if (degree.value)
            axios.delete(`${BASE_URL}/admin/degree/${degree.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateDegree(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
    }
    const deleteFieldOfStudyHandler = () => {
        if (fieldOfStudy.value)
            axios.delete(`${BASE_URL}/admin/field-of-study/${fieldOfStudy.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
    }
    const submitDegreeHandler = () => {
        if (degree && degree.value) {
            axios.put(`${BASE_URL}/admin/degree/${degree.value}`, {
                title
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDegree(prevState => ({value: prevState.value, label: title}))
                    setUpdateDegree(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/degree`, {
                title
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateDegree(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const submitFieldOfStudyHandler = () => {
        if (fieldOfStudy && fieldOfStudy.value) {
            axios.put(`${BASE_URL}/admin/field-of-study/${fieldOfStudy.value}`, {
                title: fieldOfStudyTitle
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDegree(prevState => ({value: prevState.value, label: title}))
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowEditSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/field-of-study`, {
                title: fieldOfStudyTitle
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateFieldOfStudies(prevState => !prevState)
                    setShowAddSuccess(true)
                    setTitle('')
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }

    return (
        <div className={'jobs-settings'}>
            <span>Degrees:</span>
            <div className={`d-flex align-items-center`}>
                <Select options={degrees.map(({id, title}) => ({value: id, label: title}))} styles={selectStyle}
                        onChange={e => setDegree(e)}/>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editDegreeHandler} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={AddDegreeHandler}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
                <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deleteDegreeHandler}>Yes</button>
                        <button onClick={() => setShowDeleteAlert(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={showForm ? '' : 'd-none'}>
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder={'Degree Title'}/>
                <button onClick={submitDegreeHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <span>Field of Study:</span>
            <div className={`d-flex align-items-center`}>
                <Select options={fieldOfStudies.map(({id, title}) => ({value: id, label: title}))} styles={selectStyle}
                        onChange={e => setFieldOfStudy(e)}/>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowFiledDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editFieldOfStudyHandler} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={AddFieldOfStudyHandler}><img style={{width: '20px'}} src={addIcon} alt=""/>
                    </button>
                </div>
                <div className={`delete-alert ${showFiledDeleteAlert ? 'show-alert' : ''}`}>
                    <span>Are you sure?</span>
                    <div className={`delete-alert__buttons-container`}>
                        <button onClick={deleteFieldOfStudyHandler}>Yes</button>
                        <button onClick={() => setShowFiledDeleteAlert(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className={showFieldOfStudyForm ? '' : 'd-none'}>
                <input type="text" value={fieldOfStudyTitle} onChange={e => setFieldOfStudyTitle(e.target.value)} placeholder={'Field of Study Title'}/>
                <button onClick={submitFieldOfStudyHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <div className={`success-alert ${showAddSuccess ? '' : 'hide'}`}>
                Degree Added Successfully!
            </div>
            <div className={`success-alert ${showEditeSuccess ? '' : 'hide'}`}>
                Degree Edited Successfully!
            </div>
            <div className={`success-alert ${showDeleteSuccess ? '' : 'hide'}`}>
                Job Deleted Successfully!
            </div>
        </div>
    )
}

export default EducationSettings;