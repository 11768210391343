//hooks
import {useEffect, useState} from "react";

//style
import './TopBar.css'

//images
import leftSideIcon from '../../../assets/images/left-side-icon.svg'
import rightSideIcon from '../../../assets/images/right-side-icon.svg'
import bookmarkIcon from '../../../assets/images/star-icon.svg'
import sunIcon from '../../../assets/images/sun-icon.svg'
import historyIcon from '../../../assets/images/history-icon.svg'
import bellIcon from '../../../assets/images/bell-icon.svg'
import searchIcon from '../../../assets/images/search-icon.svg'

const TopBar = ({toggleLeftSide, toggleRightSide}) => {
    // const [darkMode, setDarkMode] = useState(true)
    const [title, setTitle] = useState('')
    // const changeTheme = () => {
    //     setDarkMode(!darkMode)
    //     document.querySelector('body').setAttribute('data-darkMode', darkMode)
    // }

    useEffect(() => {
        let newTitle
        let locArray = location.href.split('/')
        if (locArray[locArray.length - 1] === '') newTitle = 'Dashboard'
        else if (locArray.includes('user-management'))
            newTitle = 'User Management'
        else if (locArray.includes('logs'))
            newTitle = 'Logs'
        else if (locArray.includes('edit-poll'))
            newTitle = 'Edit Poll'
        else
            newTitle = locArray[locArray.length - 1].split(`-`).map(item => `${item[0].toUpperCase()}${item.slice(1)} `)

        setTitle(newTitle)
    }, [location.href])

    return <div className={'top-bar'}>
        <div>
            <button onClick={toggleLeftSide}><img src={leftSideIcon} alt=""/></button>
            {/*<button><img src={bookmarkIcon} alt=""/></button>*/}
            <div>
                {title}
            </div>
        </div>
        <div>
            {/*<label className={'search-label'} htmlFor="">*/}
            {/*    <img src={searchIcon} alt=""/>*/}
            {/*    <input type="search" placeholder={'Search'}/>*/}
            {/*</label>*/}
            {/*<button onClick={changeTheme}>{(darkMode)?<img src={sunIcon} alt=""/>:'dark'}</button>*/}
            {/*<button><img src={historyIcon} alt=""/></button>*/}
            {/*<button><img src={bellIcon} alt=""/></button>*/}
            <button onClick={toggleRightSide}><img src={rightSideIcon} className="d-none d-lg-block"/></button>
        </div>
    </div>

}
export default TopBar;