import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import acceptIcon from '../../assets/images/accept-icon.png'
import rejectIcon from '../../assets/images/reject-icon.png'

const UsersAvatars = () => {
    const [users, setUsers] = useState([])
    const [noAvatars, setNoAvatars] = useState(false)
    const [page, setPage] = useState(1)
    const [maxPages, setMaxPages] = useState(1)
    const [reason, setReason] = useState('')
    const [showMessageAlert, setShowMessageAlert] = useState(false)
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)
    const [updateData, setUpdateData] = useState(true)
    const [showImgPopup, setShowImgPopup] = useState(false)
    const [rejectedUserId, setRejectedUserId] = useState('')
    const [waitForReq, setWaitForReq] = useState(true)

    const fetchUserProfile = async (userId, mediaId) => {
        try {
            const response = await axios.get(
                `https://api.globalvalidity.app/admin/users/${userId}/media/${mediaId}`,
                {
                    responseType: "arraybuffer",
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }
            );
            // Convert the received binary data to a Data URL
            const blob = new Blob([response.data], {type: "image/png"});
            return URL.createObjectURL(blob);

        } catch (error) {
            // console.log(error)
            return null;
        }
    };

    useEffect(() => {
        setWaitForReq(true)
        axios.get(`${BASE_URL}/admin/users/profiles/pending-photos`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setWaitForReq(false)

            if (res.data.data.total_items !== 0)
                if (res.data.data.user_profile_pending_photos.length) {
                    setNoAvatars(false)
                    setWaitForReq(false)
                    const promises = res.data.data.user_profile_pending_photos
                        .filter(user => user.user_profile_media_id !== null)
                        .map(user => {
                            return fetchUserProfile(user.user_id, user.user_profile_media_id).then(photo => ({
                                photo,
                                userId: user.user_id
                            }));
                        });

                    Promise.all(promises)
                        .then(updatedUsers => {
                            setUsers(updatedUsers);
                            if (updatedUsers.length < 10) {
                                setMaxPages(prevState => prevState + 1);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                }
        });
    }, [page, updateData]);
    const acceptAvatar = (userId) => {
        setWaitForReq(true)
        axios.post(`${BASE_URL}/admin/users/profiles/pending-photos/${userId}`, {
            "is_verified": true,
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setWaitForReq(false)
                    const foundItem = users.filter(item => item.userId !== userId)
                    setUsers(foundItem)
                    setShowAlertSuccess(true)
                    setTimeout(() => {
                        setShowAlertSuccess(false)
                    }, 2000)
                }
            }).catch(err => {
            // console.log(err)
        })
    }
    const rejectAvatar = (userId) => {
        setWaitForReq(true)
        axios.post(`${BASE_URL}/admin/users/profiles/pending-photos/${userId}`, {
            "is_verified": false,
            "reject_reason": reason
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setWaitForReq(false)
                    setReason('')
                    const foundItem = users.filter(item => item.userId !== userId)
                    setUsers(foundItem)
                    setShowImgPopup(false)
                    setShowAlertSuccess(true)
                    setUpdateData(prevState => !prevState)
                    setTimeout(() => {
                        setShowAlertSuccess(false)
                    }, 2000)
                }
            }).catch(err => {
            // console.log(err)
        })
    }

    return (
        <>
            <div className={`user-avatars-container position-relative row`}>
                {
                    (users.length === 0) ?
                        (noAvatars ? 'No Avatar to Reject/Verify' : 'No Images available') :
                        users.map((item, index) => {
                            return <div key={index} className={'col-12 col-sm-6 col-lg-3'}>
                                <div className={`user-avatar`}>
                                    <div>
                                        {/*<img src={photo} alt=""/>*/}
                                        <div className={'hovered-img-container'}>
                                            <div className={'main-img-container'}>
                                                <img src={item.photo} alt="" style={{width: '100%'}}
                                                     onClick={() => setShowImgPopup(item)}/>
                                            </div>
                                            {/*<div className={'hovered-img'}>*/}
                                            {/*    <img src={photo} alt=""/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-around pt-2 pb-1`}
                                         style={{background: '#2c2d30'}}>
                                        <button onClick={() => {
                                            setRejectedUserId(item.userId)
                                            setShowMessageAlert(true)
                                        }}><img src={rejectIcon}

                                                style={{width: '30px'}}
                                                alt=""/></button>
                                        <button onClick={() => acceptAvatar(item.userId)}><img src={acceptIcon}
                                                                                               style={{width: '30px'}}
                                                                                               alt=""/></button>
                                    </div>
                                </div>
                            </div>
                        })
                }
            </div>
            <div className={`success-alert ${showAlertSuccess ? '' : 'hide'}`} style={{width: '200px'}}>
                Done!
            </div>
            <div className={`reject-message ${showMessageAlert ? '' : 'hide'}`} style={{width: 'auto'}}>
                <button style={{width: '40px', position: 'absolute', right: '-18px', top: '-11px'}}
                        onClick={() => {
                            setShowMessageAlert(false)
                        }}>
                    <img src={rejectIcon} style={{width: '100%'}} alt=""/>
                </button>
                Reason:
                <textarea name="" id="" cols="30" rows="10" value={reason}
                          onChange={e => setReason(e.target.value)}></textarea>
                <button className={'reject-done-button'} onClick={e => rejectAvatar(rejectedUserId)}>Done</button>
            </div>
            {showImgPopup &&
                <div className={'img-verification-popup'} style={{overflowY: 'scroll', overflowX: 'unset'}}>
                    <button style={{position: 'absolute', right: '0', top: '0'}}><img src={rejectIcon}
                                                                                      onClick={() => setShowImgPopup(false)}
                                                                                      alt=""/></button>
                    <img src={showImgPopup.photo} alt=""/>
                    <div className={`d-flex justify-content-around mt-2 mb-1`}
                         style={{maxWidth: '300px', width: "100%", marginLeft: 'auto', marginRight: 'auto'}}>
                        <button onClick={() => {
                            setShowMessageAlert(true)
                            setRejectedUserId(showImgPopup.userId)
                        }}><img style={{width: '30px'}}
                                src={rejectIcon} alt=""/>
                        </button>
                        <button onClick={() => acceptAvatar(showImgPopup.userId)}><img src={acceptIcon}
                                                                                       style={{width: '30px'}}
                                                                                       alt=""/></button>
                    </div>
                </div>
            }
            {waitForReq ? <div style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                background: 'rgba(0,0,0,0.3',
                backdropFilter: 'blur(2px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                wait...
            </div> : null}
        </>
    )
}

export default UsersAvatars