import {useEffect, useState} from "react";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import rejectIcon from '../../assets/images/reject-icon.png'
import editIcon from '../../assets/images/edit-icon.png'
import addIcon from '../../assets/images/add-icon.png'

const selectStyle = {
    container: (styles) => {
        return ({
            ...styles,
            marginBottom: `22px`,
            width: '100%'
        })
    },
    control: (styles) => {
        return ({
            ...styles,
            border: `1px solid #5f615d`,
            backgroundColor: `#2a2c29`,
            borderRadius: `8px`,
            color: `white`,
            padding: `2px 8px`,
            outline: `none`,
            boxShadow: `none`,
            marginTop: `15px`,
            height: `42px`,
            ':hover': {cursor: `pointer`}
        })
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            background: isDisabled ? '#3b3c3a' : `#2c2e2b`,
            color: isDisabled ? 'rgba(255,255,255,0.5)' : `white`,
            ':hover': {background: `#3e3f3c`, cursor: `pointer`},
        };
    },
    menu: (styles) => {
        return {
            ...styles,
            marginTop: `5px`,
            padding: `0`,
            overflow: `hidden`,
            borderRadius: `8px`,
            border: '1px solid #5f615d',
            boxShadow: '0 0 7px 1px black'
        }
    },
    input: (styles) => {
        return {
            ...styles,
            color: 'white'
        }
    },
    menuList: (styles) => {
        return {
            ...styles,
            background: 'red',
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#4b4d48"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }
    },
    noOptionsMessage: (styles) => {
        return ({
            ...styles,
            background: `#2c2e2b`,
        })
    },
    singleValue: (styles) => {
        return ({
            ...styles,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            color: `white`,
        })
    }
}

const SurveyCategories = () => {
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState({
        id: '',
        is_personality_test: false,
        is_user_specific: false,
        name: '',
        public_results: false,
        single_question_only: false,
        supports_branching: false,
        isdisabled: false,
    })
    const [showForm, setShowForm] = useState(false)
    const [showAddSuccess, setShowAddSuccess] = useState(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
    const [showEditeSuccess, setShowEditSuccess] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [updateCategories, setUpdateCategories] = useState(false)
    const [defaultValue, setDefaultValue] = useState()
    useEffect(() => {
        axios.get(`${BASE_URL}/admin/poll-categories`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setCategories(res.data.data.poll_categories)
        })
    }, [updateCategories])
    useEffect(() => {
        axios.get(`${BASE_URL}/admin/poll-categories`, {
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setCategories(res.data.data.poll_categories)
            console.log(res)
        })
    }, [updateCategories])
    const submitCategoryHandler = () => {
        if (categoryId && categoryId.value) {
            axios.patch(`${BASE_URL}/admin/poll-category/${categoryId.value}`, {
                name: categoryId.name,
                is_user_specific: categoryId.is_user_specific,
                public_results: categoryId.public_results,
                single_question_only: categoryId.single_question_only,
                supports_branching: categoryId.supports_branching,
                allow_duplicate_reward: categoryId.allow_duplicate_reward,
                is_personality_test: categoryId.is_personality_test
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setCategoryId(prevCategory => ({
                        ...prevCategory,
                        name: prevCategory.name
                    }));
                    setUpdateCategories(prevState => !prevState)
                    setShowEditSuccess(true)
                    setCategoryId({
                        id: '',
                        is_personality_test: false,
                        is_user_specific: false,
                        name: '',
                        public_results: false,
                        single_question_only: false,
                        supports_branching: false,
                        allow_duplicate_reward: false
                    })
                    setTimeout(() => {
                        setShowEditSuccess(false)
                    }, 2000)
                }
            })
        } else {
            axios.post(`${BASE_URL}/admin/poll-category`, {
                name: categoryId.name,
                is_user_specific: categoryId.is_user_specific,
                public_results: categoryId.public_results,
                single_question_only: categoryId.single_question_only,
                supports_branching: categoryId.supports_branching,
                allow_duplicate_reward: categoryId.allow_duplicate_reward,
                is_personality_test: categoryId.is_personality_test
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setDefaultValue({value: '', label: ''})
                    setUpdateCategories(prevState => !prevState)
                    setShowAddSuccess(true)
                    setCategoryId({
                        id: '',
                        is_personality_test: false,
                        is_user_specific: false,
                        name: '',
                        public_results: false,
                        single_question_only: false,
                        supports_branching: false,
                    })
                    setTimeout(() => {
                        setShowAddSuccess(false)
                    }, 2000)
                }
            })
        }
    }
    const deleteCategoryHandler = () => {
        if (categoryId.value)
            axios.delete(`${BASE_URL}/admin/poll-category/${categoryId.value}`, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.status === 200) {
                    setUpdateCategories(prevState => !prevState)
                    setShowDeleteSuccess(true)
                    setCategoryId({
                        id: '',
                        is_personality_test: false,
                        is_user_specific: false,
                        name: '',
                        public_results: false,
                        single_question_only: false,
                        supports_branching: false,
                    })

                    setTimeout(() => {
                        setShowDeleteSuccess(false)
                        setShowDeleteAlert(false)
                    }, 2000)
                }
            })
    }

    const editCategoryHandler = () => {
        if (categoryId.value)
            setShowForm(true)
    }

    const AddCategoryHandler = () => {
        setShowForm(true)
        setCategoryId({
            id: '',
            is_personality_test: false,
            is_user_specific: false,
            name: '',
            public_results: false,
            single_question_only: false,
            supports_branching: false,
        })
    }

    return (
        <div className={'jobs-settings'}>
            Poll Categories:
            <div className={`d-flex align-items-center`}>
                <Select options={categories.map(({
                                                     id,
                                                     name,
                                                     supports_branching,
                                                     single_question_only,
                                                     is_user_specific,
                                                     public_results,
                                                     is_personality_test
                                                 }) => {
                    let isDis = true
                    if (id !== '64fc313df370ffab069369e6' && id !== '64fc3121f370ffab069369e5' && id !== '64fc2ddcf370ffab069369e4' && id !== '64fc2dbff370ffab069369e3' && id !== '64fc2cd5f370ffab069369e1') {
                        isDis = false
                    }
                    return {
                        label: name,
                        value: id,
                        supports_branching,
                        single_question_only,
                        is_user_specific,
                        public_results,
                        is_personality_test,
                        isdisabled: isDis
                    }
                })} styles={selectStyle}
                        onChange={e => {
                            if (e.value !== '64fc313df370ffab069369e6' && e.value !== '64fc3121f370ffab069369e5' && e.value !== '64fc2ddcf370ffab069369e4' && e.value !== '64fc2dbff370ffab069369e3' && e.value !== '64fc2cd5f370ffab069369e1') {
                                setCategoryId(e)
                            }
                        }} isOptionDisabled={(option) => option.isdisabled}/>
                <div className={`d-flex align-items-center`}>
                    <button onClick={() => setShowDeleteAlert(true)}>
                        <img style={{width: '20px'}} src={rejectIcon} alt=""/>
                    </button>
                    <button onClick={editCategoryHandler} style={{filter: 'brightness(0) invert(1)',}}><img
                        style={{width: '20px'}} src={editIcon}
                        alt=""/>
                    </button>
                    <button onClick={AddCategoryHandler}><img style={{width: '20px'}} src={addIcon} alt=""/></button>
                </div>
            </div>
            <div className={showForm ? '' : 'd-none'}>
                <input type="text" value={categoryId.name} onChange={e => {
                    setCategoryId(prevCategory => ({
                        ...prevCategory,
                        name: e.target.value
                    }));
                }}
                       placeholder={'Category Title'}/>
                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.is_user_specific}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                is_user_specific: e.target.checked
                            }));
                        }}
                    />
                    is User Specific
                </label>
                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.public_results}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                public_results: e.target.checked
                            }));
                        }}
                    />
                    Public Results
                </label>
                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.single_question_only}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                single_question_only: e.target.checked
                            }));
                        }}
                    />
                    Single Question Only
                </label>
                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.supports_branching}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                supports_branching: e.target.checked
                            }));
                        }}
                    />
                    Supports Branching
                </label>
                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.allow_duplicate_reward}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                allow_duplicate_reward: e.target.checked
                            }));
                        }}
                    />
                    Allow Duplicate Reward
                </label>

                <label style={{display: "block"}}>
                    <input
                        type="checkbox"
                        style={{width: 'auto', margin: '0 10px 20px 0'}}
                        checked={categoryId.is_personality_test}
                        onChange={e => {
                            setCategoryId(prevCategory => ({
                                ...prevCategory,
                                is_personality_test: e.target.checked
                            }));
                        }}
                    />
                    is Personality Test
                </label>

                <button onClick={submitCategoryHandler} className={`submit-job-button`}>Submit</button>
            </div>
            <div className={`success-alert ${showAddSuccess ? '' : 'hide'}`}>
                Category Added Successfully!
            </div>
            <div className={`success-alert ${showDeleteSuccess ? '' : 'hide'}`}>
                Category Deleted Successfully!
            </div>
            <div className={`success-alert ${showEditeSuccess ? '' : 'hide'}`}>
                Category Edited Successfully!
            </div>
            <div className={`delete-alert ${showDeleteAlert ? 'show-alert' : ''}`}>
                <span>Are you sure?</span>
                <div className={`delete-alert__buttons-container`}>
                    <button onClick={deleteCategoryHandler}>Yes</button>
                    <button onClick={() => setShowDeleteAlert(false)}>No</button>
                </div>
            </div>
        </div>
    )
}


export default SurveyCategories;