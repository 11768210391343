import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useContext, useEffect, useRef, useState} from "react";
import "./Poll.css";
import axios from "axios";
import BASE_URL from "../../api/api";
import Select from "react-select";
import dragIcon from "../../assets/images/drag-icon.png"
import trashIcon from "../../assets/images/trash-icon.svg"
import {useParams} from "react-router-dom";
import {UpdateContext} from "../../context";
import button from "bootstrap/js/src/button";
import Editor from "../../Editor";
import Question from "../../components/Question";
import Rate from "../../components/Rate";
import {getChoicesOfQuestion, getPollQuestionTranslations} from "../../util/translations";
import {containsHtmlTags} from "../../util/pollHelper";

const Poll = () => {
    const {setUpdatePolls} = useContext(UpdateContext)
    const [birthdate, setBirthdate] = useState({value: null, label: null})
    const [exp, setExp] = useState({value: null, label: null})
    const [gender, setGender] = useState({value: null, label: null})
    const [jobId, setJobId] = useState({value: null, label: null})
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState({value: null, label: null})
    const [desc, setDesc] = useState()
    const [pollCategories, setPollCategories] = useState([])
    const [pollCategoryId, setPollCategoryID] = useState('')
    //10:
    const [title, setTitle] = useState('')
    const [reward, setReward] = useState('')
    const [states, setStates] = useState([])
    const [stateId, setStateId] = useState({value: null, label: null})
    const [cities, setCities] = useState([])
    const [closedPoll, setClosedPoll] = useState(false)
    const [cityId, setCityId] = useState({value: null, label: null})
    const [maritalStatus, setMaritalStatus] = useState({value: null, label: null})
    const [jobsList, setJobsList] = useState([])
    const [pollId, setPollId] = useState(undefined)
    const [formCounter, setFormCounter] = useState(0)
    //20
    const [queId, setQueId] = useState(undefined)
    const [deletedQues, setDeletedQues] = useState([])
    const [deleteInout, setDeleteInout] = useState([])
    const [activePoll, setActivePoll] = useState(false)
    const [inputsType, setInputsType] = useState({value: null, label: null})
    const [allChoicesType, setAllChoicesType] = useState({value: null, label: null})
    const [sending, setSending] = useState(false)
    const [showEditedSuccessfully, setShowEditedSuccessfully] = useState(false)
    const [ratesData, setRatesData] = useState([])
    const [resultText, setResultText] = useState('')
    // 30
    const [haveTranslateToAr, setHaveTranslateToAr] = useState(true)
    const [haveTranslateToEn, setHaveTranslateToEn] = useState(true)
    const [translations, setTranslations] = useState([])
    const [titleTranslation, setTitleTranslation] = useState([])
    const [questionsTranslations, setQuestionsTranslations] = useState([])
    const [choicesTranslations, setChoicesTranslations] = useState([])
    const [langs, setLangs] = useState([])
    const editPollId = useParams()
    const [updateForms, setUpdateForms] = useState(true)
    const [focusedOnTitle, setFocusedOnTitle] = useState(false)
    const [titleTranslations, setTitleTranslations] = useState([])
    // 40
    const [questionTitleTranslation, setQuestionTitleTranslation] = useState([])
    const [currentQuestionInEdit, setCurrentQuestionInEdit] = useState(null)
    const [currentQuestionChoicesTranslations, setCurrentQuestionChoicesTranslations] = useState([])
    const [currentInputsQuestion, setCurrentInputsQuestion] = useState([])
    const [newInputsType, setNewInputsType] = useState("")
    const [newQuestionType, setNewQuestionType] = useState("")
    const [forms, setForms] = useState([]);
    const [degrees, setDegrees] = useState([])
    const [degree, setDegree] = useState({value: null, label: null})
    const [degreeId, setDegreeId] = useState()
    const [jobsInput, setJobsInput] = useState('')

    const questionsTranslationHandler = (e, landId, formId) => {
        let oldForms = forms.slice(); // create a copy of the forms array
        oldForms.forEach(form => {
            if (form.formId === formId) {
                form.translations[landId] = e.target.value;
            }
        });
        setForms([...oldForms]); // update state with the new array
    };
    const handleJobsInputChange = (inputValue) => {
        setJobsInput(inputValue)
    }
    const choicesTranslationHandler = (e, formId, id, langId) => {
        console.log(e.target.value, formId, id, langId)
        let formsHolder = forms
        formsHolder.forEach(form => {
            if (form.formId === formId) {
                //question founded
                form.inputs.forEach(input => {
                    if (input.id === id) {
                        input.translations[langId] = e.target.value
                    }
                })
            }
        })
        setForms([...formsHolder])
    }
    useEffect(() => {
        if (currentQuestionInEdit && pollId) {
            getPollQuestionTranslations(pollId, currentQuestionInEdit, langs, setQuestionTitleTranslation, forms, setForms)
        }
    }, [currentQuestionInEdit])

    useEffect(() => {
        if (currentQuestionInEdit && currentQuestionChoicesTranslations) {
            getChoicesOfQuestion(pollId, currentQuestionInEdit, langs, currentInputsQuestion, setCurrentQuestionChoicesTranslations)
        }
    }, [currentQuestionInEdit, forms])
    // }, [currentQuestionInEdit, forms])

    const isFirstRender = useRef(true);

    const initForm = (order, queId) => ({
        order: order,
        question: 'No title',
        translations: {
            '6533c65a7b8ccffa90a7344e': '',
            '656d7dbf9e05b050d04c83bc': ''
        },
        required: true,
        scoring_factor: 0,
        type: 'SINGLE_CHOICE',
        inputs: [
            // {inputValue: '', order: 1, rate: ``, inputId: `${order}-0`, inputType: '', inputTranslations: {}},
            // {inputValue: '', order: 2, rate: ``, inputId: `${order}-1`, inputType: '', inputTranslations: {}}
        ],
        id: `form-${order}`,
        formId: queId
    });

    let dateobj = new Date()
    let birthdateYear = []
    for (let i = 1900; i <= dateobj.getFullYear(); i++) {
        birthdateYear.push(i)
    }
    let expYears = []
    for (let i = 1; i <= 30; i++) {
        expYears.push({value: i, label: i})
    }
    const selectStyle = {
        container: (styles) => {
            return ({
                ...styles,
                marginBottom: `22px`,
            })
        },
        control: (styles) => {
            return ({
                ...styles,
                border: `1px solid #5f615d`,
                backgroundColor: `#2a2c29`,
                borderRadius: `8px`,
                color: `white`,
                padding: `2px 8px`,
                outline: `none`,
                boxShadow: `none`,
                marginTop: `15px`,
                height: `42px`,
                ':hover': {cursor: `pointer`}
            })
        },
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                background: `#2c2e2b`,
                color: `white`,
                ':hover': {background: `#3e3f3c`, cursor: `pointer`},
            };
        },
        valueContainer: (styles) => {
            return {
                ...styles,
                height: '100%',
                position: 'relative'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
            };
        },

        menu: (styles) => {
            return {
                ...styles,
                marginTop: `5px`,
                padding: `0`,
                overflow: `hidden`,
                borderRadius: `8px`,
                border: '1px solid #5f615d',
                boxShadow: '0 0 7px 1px black'
            }
        },
        menuList: (styles) => {
            return {
                ...styles,
                padding: 0,
                "::-webkit-scrollbar": {
                    width: "4px",
                    height: "0px",
                },
                "::-webkit-scrollbar-track": {
                    background: "#4b4d48"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "#888"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: "#555"
                }
            }
        },
        noOptionsMessage: (styles) => {
            return ({
                ...styles,
                background: `#2c2e2b`,
            })
        },
        singleValue: (styles) => {
            return ({
                ...styles,
                display: `flex`,
                alignItems: `center`,
                color: `white`,
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0'
            })
        },
        input: (styles) => {
            return ({
                ...styles,
                color: 'white',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '0',
            })
        }
    }
    const inputTypeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs.map(item => {
            item.inputType = e.value
        })

        setForms(updatedForms);
    }
    const deleteQue = (formIndex, formId) => {
        let NewDeletedQues = deletedQues;
        NewDeletedQues.push(formId)
        setDeletedQues(NewDeletedQues)
        setFormCounter(prevState => prevState - 1)
        const updatedForms = [...forms]
        updatedForms.splice(formIndex, 1);
        updatedForms.forEach((form, index) => {
            form.order = index + 1
            form.id = `form-${index}`
            form.inputs.forEach((input, inputIndex) => {
                input.inputId = `${index}-${inputIndex}`
            })
        })
        setForms(updatedForms);
    }
    const addFieldHandler = () => {
        setRatesData([...ratesData, {from: 0, to: 0, result: ''}])
    }

    const deleteRateHandler = (rateIndex) => {
        ratesData.splice(rateIndex, 1);
        setRatesData([...ratesData])
    }

    const deleteChoice = (formIndex, inputIndex, id, formId) => {
        let newDeleteInout = deleteInout
        newDeleteInout.push([formId, id])
        setDeleteInout(newDeleteInout)
        const updatedForms = [...forms]
        updatedForms[formIndex].inputs.splice(inputIndex, 1);
        updatedForms[formIndex].inputs.forEach((input, index) => {
            input.inputId = `${formIndex}-${index}`
        })
        setForms(updatedForms);
    }

    const requiredHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].required = e.target.checked;
        setForms(updatedForms);
    };
    const submitQueHandler = () => {
        axios.patch(`${BASE_URL}/admin/poll/${pollId}`, {
            "personality_scores": ratesData

        }, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                if (titleTranslations != []) {
                    langs.forEach(item => {
                        if (item.id != '6533c65a7b8ccffa90a73450' && titleTranslations[item.id] != undefined) {
                            axios.post(`${BASE_URL}/admin/poll/${res.data.data.poll.id}/translations`, {
                                "language_id": `${item.id}`,
                                "title": `${titleTranslations[item.id]}`
                            }, {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        }
                    })
                }
                setUpdatePolls(prev => !prev)
                setPollId(res.data.data.poll.id)
                setShowEditedSuccessfully(true)
                setTimeout(() => {
                    setShowEditedSuccessfully(false)
                }, 2000)
            }
            console.log(res)
        }).catch(err => {
            console.log(err)
        })


        let criteria = {}
        if (birthdate.value || exp.value || gender.value || maritalStatus.value || cityId.value || stateId.value || countryId.value || jobId.value || ratesData || degree.value) {

            if (birthdate.value) {
                criteria["birthdate_year"] = birthdate.value
            }
            console.log(degree)
            if (degree.value) {
                criteria["degree_id"] = degree.value
            }
            if (exp.value) {
                criteria["experience"] = {
                    "over_30_years": (exp === 0),
                    "years": exp === undefined ? null : exp.value
                }
            }

            if (gender.value) {
                criteria["gender"] = gender.value
            }

            if (maritalStatus.value) {
                criteria["marital_status"] = maritalStatus.value
            }

            if (cityId.value || stateId.value || countryId.value) {
                criteria["location"] = {
                    "country_state_city_id": cityId === undefined ? null : cityId.value,
                    "country_state_id": stateId === undefined ? null : stateId.value,
                    "country_id": countryId === undefined ? null : countryId.value,
                }
            }

            if (jobId.value) {
                criteria["job_id"] = jobId.value
            }

        } else {
            criteria = {}
        }

        let data = {
            "active": activePoll,
            "closed": closedPoll,
            "criteria": criteria,
            "description": desc === undefined ? null : desc,
            "poll_category_id": pollCategoryId === '' ? null : pollCategoryId,
            "reward": reward === undefined ? 0 : +reward,
            "title": title === undefined ? null : title,
            "language_id": "6533c65a7b8ccffa90a73450",
            "personality_scores": ratesData

        }
        setSending(true)
        setTimeout(() => {
            setSending(false)
        }, 1000)
        axios.patch(`https://api.globalvalidity.app/admin/poll/${editPollId.editPollId}`, data, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                setUpdatePolls(prev => !prev)
            }
        }).catch(err => {
            // console.log(err)
        })
        //let typeForAll = forms[0].type
        console.log('translations')
        forms.map(({order, question, required, scoring_factor, type, inputs, formId, translations}) => {
            if (formId !== undefined) {
                axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${formId}`, {
                    order: order,
                    question,
                    required,
                    scoring_factor: +scoring_factor,
                    type
                }, {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                }).then(res => {
                    inputs.map((input) => {
                        if (input.id !== undefined) {
                            axios.patch(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${res.data.data.poll_question.id}/choice/${input.id}`, {
                                answer: input.inputValue,
                                order: +input.order,
                                rate: +input.rate,
                                type: allChoicesType.value
                            }, {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                                if (res.status === 200) {
                                    setShowEditedSuccessfully(true)
                                    setTimeout(() => {
                                        setShowEditedSuccessfully(false)
                                    }, 2000)
                                }
                            }).catch(err => {
                                // console.log('input', err)
                            })
                        } else {
                            axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${res.data.data.poll_question.id}/choice`, {
                                answer: input.inputValue,
                                order: +input.order,
                                rate: +input.rate,
                                type: type
                            }, {
                                headers: {
                                    "Accept": "*/*",
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                }
                            }).then(res => {
                                // console.log(res)
                            }).catch(err => {
                                // console.log(err)
                            })
                        }

                    })
                })
            } else {
                axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question`, {
                    order: order,
                    question,
                    required,
                    scoring_factor: +scoring_factor,
                    type
                }, {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                }).then(res => {
                    inputs.map((input) => {
                        axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${res.data.data.poll_question.id}/choice`, {
                            answer: input.inputValue,
                            order: +input.order,
                            rate: +input.rate,
                            type: type
                        }, {
                            headers: {
                                "Accept": "*/*",
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            }
                        }).then(res => {
                        }).catch(err => {
                            // console.log(err)
                        })
                    })
                }).catch(err => {
                    // console.log(err)
                })
            }
        })
        deletedQues.forEach(item => {
            if (item)
                axios.delete(`${BASE_URL}/admin/poll/${pollId}/question/${item}`, {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                }).then(res => {
                    // console.log(res)
                })
        })
        deleteInout.forEach(item => {
            if (item[0] && item[1])
                axios.delete(`${BASE_URL}/admin/poll/${pollId}/question/${item[0]}/choice/${item[1]}`, {
                    headers: {
                        "Accept": "*/*",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                }).then(res => {
                    // console.log(res)
                })
        })
        if (questionsTranslations) {
            questionsTranslations.forEach(item => {
                if (item.translations) {
                    item.translations.forEach(translation => {
                        axios.post(`${BASE_URL}/admin/poll/${translation.poll_id}/question/${translation.poll_question_id}/translations`, {
                            "language_id": translation.language_id,
                            "question": translation.question
                        }, {
                            headers: {
                                'accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }).then(res => {

                        }).catch(err => {
                            console.log(err)
                        })
                    })
                }
            })
        }
        if (choicesTranslations) {
            choicesTranslations.forEach(item => {
                if (item.translations)
                    item.translations.forEach(translation => {
                        axios.post(`${BASE_URL}/admin/poll/${translation.poll_id}/question/${translation.poll_question_id}/choice/${translation.poll_question_choice_id}/translations`, {
                            "language_id": translation.language_id,
                            "choice": translation.choice
                        }, {
                            headers: {
                                'accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }).then(res => {
                            console.log(res)
                        }).catch(err => {
                            console.log(err)
                        })
                    })
            })
        }
    }
    const typeHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].type = e.value;
        setForms(updatedForms);
    }
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/auth/countries`).then(res => {
            setCountries(res.data.data)
        })
        axios.get(`${BASE_URL}/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        }).catch(err => {
            // console.log(err)
        })
        axios.get(`https://api.globalvalidity.app/admin/poll-categories`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            setPollCategories(res.data.data.poll_categories)
        })
        axios.get(`${BASE_URL}/admin/poll/${editPollId.editPollId}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.data.data.poll.personality_scores) {
                let test = res.data.data.poll.personality_scores.map((item) => {
                    let itemRes
                    return ({...item})
                })
                setRatesData([...test])
            }

            setActivePoll(res.data.data.poll.active)
            setClosedPoll(res.data.data.poll.closed)
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.location && res.data.data.poll.criteria.location.country_id)
                axios.get(`${BASE_URL}/entities/countries/${res.data.data.poll.criteria.location.country_id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    setCountryId({value: res.data.data.country.id, label: res.data.data.country.name})
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.location && res.data.data.poll.criteria.location.country_id && res.data.data.poll.criteria.location.country_state_id && res.data.data.poll.criteria.location.country_state_id !== "000000000000000000000000")
                axios.get(`${BASE_URL}/entities/countries/${res.data.data.poll.criteria.location.country_id}/states/${res.data.data.poll.criteria.location.country_state_id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    setStateId({value: res.data.data.country_state.id, label: res.data.data.country_state.name})
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.location && res.data.data.poll.criteria.location.country_id && res.data.data.poll.criteria.location.country_state_id && res.data.data.poll.criteria.location.country_state_city_id && res.data.data.poll.criteria.location.country_state_city_id !== "000000000000000000000000")
                axios.get(`${BASE_URL}/entities/countries/${res.data.data.poll.criteria.location.country_id}/states/${res.data.data.poll.criteria.location.country_state_id}/cities/${res.data.data.poll.criteria.location.country_state_city_id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    setCityId({
                        value: res.data.data.country_state_city.id,
                        label: res.data.data.country_state_city.name
                    })
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.location && res.data.data.poll.criteria.location.country_id)
                axios.get(`${BASE_URL}/entities/countries/${res.data.data.poll.criteria.location.country_id}/states`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    if (res.data.data.country_states !== null)
                        setStates(res.data.data.country_states)
                }).catch(err => {
                    // console.log(err)
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.location && res.data.data.poll.criteria.location.country_id && res.data.data.poll.criteria.location.country_state_id)
                axios.get(`${BASE_URL}/entities/countries/${res.data.data.poll.criteria.location.country_id}/states/${res.data.data.poll.criteria.location.country_state_id}/cities`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    if (res.data.data.country_state_cities !== null)
                        setCities(res.data.data.country_state_cities)
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.job_id)
                axios.get(`${BASE_URL}/entities/jobs/${res.data.data.poll.criteria.job_id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    },
                }).then(res => {
                    setJobId({value: res.data.data.job.id, label: res.data.data.job.title})
                    setJobsInput(res.data.data.job.title)
                }).catch(err => {
                    // console.log(err)
                })

            let titlesTranslationsObj = []
            if (res.data.data.poll && res.data.data.poll.translations) {
                titlesTranslationsObj = res.data.data.poll.translations.reduce((obj, transLang) => {
                    obj[transLang.language_id] = transLang.title;
                    return obj;
                }, {})
            }

            setTitleTranslations(titlesTranslationsObj)
            setDesc(res.data.data.poll.description || '')
            setReward(res.data.data.poll.reward || '')
            setPollId(res.data.data.poll.id)
            setActivePoll(res.data.data.poll.active)
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.birthdate_year)
                setBirthdate({
                    value: res.data.data.poll.criteria.birthdate_year,
                    label: res.data.data.poll.criteria.birthdate_year
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.gender)
                setGender({
                    value: res.data.data.poll.criteria.birthdate_year,
                    label: res.data.data.poll.criteria.birthdate_year
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.degree_id)
                setDegreeId(res.data.data.poll.criteria.degree_id)
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.marital_status)
                setMaritalStatus({
                    label: `${res.data.data.poll.criteria.marital_status.slice(0, 1)}${res.data.data.poll.criteria.marital_status.substring(1).toLowerCase()}`,
                    value: res.data.data.poll.criteria.marital_status
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.gender)
                setGender({
                    label: `${res.data.data.poll.criteria.gender.slice(0, 1)}${res.data.data.poll.criteria.gender.substring(1).toLowerCase()}`,
                    value: res.data.data.poll.criteria.gender
                })
            if (res.data.data.poll.criteria && res.data.data.poll.criteria.experience && res.data.data.poll.criteria.experience.over_30_years) {
                setExp({label: 'Over 30 years', value: 'over_30_years'})
            } else if (res.data.data.poll.criteria && res.data.data.poll.criteria.experience) {
                setExp({
                    label: res.data.data.poll.criteria.experience.years,
                    value: res.data.data.poll.criteria.experience.years
                })
            }
            setTitle(res.data.data.poll.title)
            axios.get(`${BASE_URL}/admin/poll-categories/${res.data.data.poll.poll_category_id}`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                // console.log(res)
                setPollCategoryID({
                    value: res.data.data.poll_category.id,
                    label: res.data.data.poll_category.name
                })
            })
            setPollCategoryID(res.data.data.poll.poll_category_id)
            let oldInputs = []
            if (res.data.data.poll.questions) {
                setFormCounter(res.data.data.poll.questions.length)
                let oldForms = res.data.data.poll.questions.map(({
                                                                     order,
                                                                     question,
                                                                     scoring_factor,
                                                                     required,
                                                                     type, choices, id
                                                                 }, index) => {
                    let queTranslation
                    if (choices && choices[0] && choices[0].type) {
                        setAllChoicesType({
                            value: choices[0].type,
                            label: choices[0].type.charAt(0) + choices[0].type.slice(1).toLowerCase()
                        })
                    }
                    oldInputs = choices.map(({answer, order, rate, id}, inputIndex) => {
                        return {
                            inputValue: answer,
                            order,
                            rate,
                            inputId: `${index}-${order}-${inputIndex}`,
                            id,
                            type: choices[0].type,
                        }
                    })
                    oldInputs.sort((a, b) => a.order - b.order)
                    return {
                        order,
                        question,
                        required,
                        scoring_factor,
                        inputs: [...oldInputs],
                        id: `form-${index}`,
                        formId: id,
                        type
                    }
                })
                oldForms.sort((a, b) => a.order - b.order)

                setForms(oldForms)
                setUpdateForms(prevState => !prevState)
            }
        })

        axios.get(`${BASE_URL}/entities/degrees`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            let degrees = res.data.data.degrees.map(degree => ({
                value: degree.id, label: degree.title
            }))
            setDegrees(degrees)
        })
    }, [])
    useEffect(() => {
        console.log({label: degrees.find(degree => degreeId === degree.value || null), value: degreeId})
        setDegree({label: degrees.find(degree => degreeId === degree.value || null), value: degreeId})
    }, [degreeId, degrees])
    useEffect(() => {
        axios.get(`https://api.globalvalidity.app/entities/languages`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            let languages = res.data.data.languages
            let temp = languages[0]
            languages[0] = languages[1]
            languages[1] = temp
            setLangs(res.data.data.languages)
            let langIdContainer = {}
            res.data.data.languages.forEach((item, index) => {
                if (item.id !== '6533c65a7b8ccffa90a73450')
                    langIdContainer[index] = item.id
            })

            // setTitleTranslations(langIdContainer)
            let formHolder = forms
            formHolder.forEach(form => {
                form.translations = {
                    [langIdContainer[1]]: '',
                    [langIdContainer[2]]: ''
                }
                form.inputs.forEach(input => {
                    input.translations = {
                        [langIdContainer[1]]: '',
                        [langIdContainer[2]]: ''
                    }
                })
            })
            // formHolder[res.data.data.languages[0]] = ''
        }).catch(err => {
            console.log(err)
        })
        if (forms.length) {
            axios.get(`${BASE_URL}/admin/poll/${editPollId.editPollId}/questions?page=0&limit=999`, {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
                let questions = res.data.data.poll_questions;

                setQuestionsTranslations(questions);

                let accumulatedChoicesTranslations = [];

                const fetchChoices = (question, index) => {
                    if (question.translations)
                        return axios.get(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question.translations[0].poll_question_id}/choices?page=0&limit=999`, {
                            headers: {
                                "Accept": "*/*",
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                            }
                        }).then(inputRes => {
                            accumulatedChoicesTranslations = [...accumulatedChoicesTranslations, ...inputRes.data.data.poll_question_choices];
                        }).catch(err => console.log(err));
                };

                // Use Promise.all to wait for all asynchronous operations to complete
                Promise.all(questions.map(fetchChoices))
                    .then(() => {
                        let formsHolder = forms
                        accumulatedChoicesTranslations.forEach(choiceAndTranslation => {
                            choiceAndTranslation.translations.forEach(translation => {
                                // console.log(translation)
                                formsHolder.forEach(form => {
                                    if (form.formId === translation.poll_question_id) {
                                        form.inputs.forEach(input => {
                                            if (input.id === translation.poll_question_choice_id) {
                                                //input founded
                                                input.translations[translation.language_id] = translation.choice
                                            }
                                        })
                                    }
                                })
                            })
                        })
                        setChoicesTranslations(accumulatedChoicesTranslations);
                    })
                    .catch(err => console.log(err));
            });
        }
    }, [updateForms]);


    useEffect(() => {
        if (countryId.value !== null && countryId.value !== "000000000000000000000000") {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                setStates(res.data.data.country_states)
            }).catch(err => {
                // console.log(err)
            })
        }
    }, [countryId])
    useEffect(() => {
        if (stateId.value !== null && stateId.value !== "000000000000000000000000") {
            axios.get(`https://api.globalvalidity.app/entities/countries/${countryId.value}/states/${stateId.value}/cities`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                },
            }).then(res => {
                if (res.data.data.country_state_cities) setCities(res.data.data.country_state_cities)
            }).catch(err => {
                // console.log(err)
            })
        }
    }, [stateId])
    useEffect(() => {
        axios.get(`${BASE_URL}/entities/jobs?title=${jobsInput}&page=1&limit=500`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            },
        }).then(res => {
            if (res.data.data.jobs === null) {
                setJobsList([])
            } else {
                setJobsList(res.data.data.jobs)
            }
        })
    }, [jobsInput])

    function handleOnDragEnd(result) {
        if (!result.destination) return;


        const items = Array.from(forms);

        if (result.type === "forms") {
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            items.forEach((item, index) => {
                item.order = index + 1
            })
            setForms(items);
        } else {
            let formCount = result.destination.droppableId.split('-')
            const formIndex = formCount[formCount.length - 1] - 1;
            const copiedForm = {...forms[formIndex]};
            const copiedInputs = [...copiedForm.inputs];
            const [reorderedInput] = copiedInputs.splice(result.source.index, 1);
            copiedInputs.splice(result.destination.index, 0, reorderedInput);

            copiedInputs.forEach((item, index) => {
                item.order = index + 1
            })
            copiedForm.inputs = copiedInputs;

            items[formIndex] = copiedForm;

            setForms(items);
        }
    }

    const handleEditorUpdate = (html, index) => {
        // Update the data array with the modified HTML
        const newData = [...ratesData];
        newData[index].result = html;
        setRatesData(newData);
        console.log(newData)
    };
    const addPollHandler = () => {
        handleAddNewQuestion(initForm(formCounter + 1))
    }
    const addChoiceHandler = (e, formIndex, type, inputs, formId) => {
        let formType = "RATE"
        if (type.newInputsType) {
            formType = newInputsType.value

        } else {
            formType = type.inputs[0].type
        }
        axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${formId}/choice`, {
            order: type.inputs.length + 1,
            answer: 'No title',
            rate: 0,
            type: formType
        }, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            console.log("RESULT: ------------", res.data.data.poll_question_choice)
            const newAddedChoice = res.data.data.poll_question_choice

            const newFormObjAdd = {
                inputValue: newAddedChoice.answer,
                order: newAddedChoice.order,
                rate: newAddedChoice.rate,
                inputId: `${newAddedChoice.order}-${inputs.length}`,
                id: newAddedChoice.id,
                type: newAddedChoice.type,
                translations: {
                    "6533c65a7b8ccffa90a7344e": "",
                    "656d7dbf9e05b050d04c83bc": ""
                }
            }

            const formIndex = forms.findIndex(obj => obj.formId === formId);
            console.log(formIndex)
// Ensure that you found the formIndex, and it's not -1
            if (formIndex !== -1) {
                // Create a new copy of the forms array
                const newFormsArray = [...forms];

                // Create a new copy of the specific form's inputs and add the new input
                const newInputs = [...newFormsArray[formIndex].inputs, newFormObjAdd];

                // Update the specific form's inputs with the new inputs array
                newFormsArray[formIndex] = {
                    ...newFormsArray[formIndex],
                    inputs: newInputs
                };
                // Update the state with the new forms array
                console.log(newFormsArray)
                setForms([...newFormsArray]);
                // setCurrentInputsQuestion([...inputs, newFormObjAdd])
            }
            getChoicesOfQuestion(pollId, currentQuestionInEdit, langs, currentInputsQuestion, setCurrentQuestionChoicesTranslations, setForms)
            // getPollQuestionTranslations(pollId, currentQuestionInEdit, langs, setQuestionTitleTranslation, forms, setForms)
        }).catch(err => {
            // console.log(err)
        })
    }

    const formInputHandler = (e, formIndex, inputIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputValue = e.target.value;
        setForms(updatedForms);
    };
    const formInputTranslationHandler = (e, formIndex, inputIndex, lang = false) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].inputTranslations[lang.id] = e.target.value;
        setForms(updatedForms);
    };
    const formInputRateHandler = (e, formIndex, inputIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].inputs[inputIndex].rate = +e.target.value;
        setForms(updatedForms);
    };
    const queHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].question = e.target.value;
        setForms(updatedForms);
    };

    const queTranslateHandler = (e, editedTranslationID) => {
        let editedTranslations = questionsTranslations.map(item => {
            item.translations = item.translations.map(translation => {
                if (translation.id === editedTranslationID.id) {
                    translation.question = e.target.value;
                }
                return translation;
            });
            return item;
        });
        setQuestionsTranslations(editedTranslations)
    };

    const ChoicesTranslationHandler = (e, editedChoiceID) => {
        let editedTranslations = choicesTranslations.map(item => {
            item.translations = item.translations.map(translation => {
                if (translation.id === editedChoiceID.id) {
                    translation.choice = e.target.value;
                }
                return translation;
            });
            return item;
        });
        setChoicesTranslations(editedTranslations)
    }

    const scoringFactorHandler = (e, formIndex) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].scoring_factor = e.target.value;
        setForms(updatedForms);
    }
    const queTranslationHandler = (e, formIndex, lang) => {
        const updatedForms = [...forms];
        updatedForms[formIndex].translations[lang.id] = e.target.value;
        setForms(updatedForms);
    };

    function handleChoiceDelete(questionId, ChoiceId) {
        axios.delete(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${questionId}/choice/${ChoiceId}`,
            {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            })
            .then(res => {
                if (res && res.status === 200) {
                    setShowEditedSuccessfully(true)
                    setTimeout(() => {
                        setShowEditedSuccessfully(false)
                    }, 2000)
                }
            })
    }

    function handleAddNewQuestion({order, question, required, scoring_factor, type, inputs, formId}) {
        if (formId === undefined) {
            axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question`, {
                order: order,
                question: question ? question : 'Empty',
                required: required ? required : false,
                scoring_factor: +scoring_factor,
                type: type ? type : 'SINGLE_CHOICE'
            }, {
                headers: {
                    "Accept": "*/*",
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                }
            }).then(res => {
                const addedQuestionId = res.data.data.poll_question.id
                const addedPollQuestionId = res.data.data.poll_question.poll_id


                setForms([...forms, initForm(formCounter + 1, addedQuestionId)])
                setFormCounter(prevState => prevState + 1)
                inputs.map((input) => {
                    axios.post(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${addedQuestionId}/choice`, {
                        answer: input.inputValue,
                        order: +input.order,
                        rate: +input.rate,
                        type: type ? type : 'SINGLE_CHOICE'
                    }, {
                        headers: {
                            "Accept": "*/*",
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        }
                    }).then(res => {
                        if (questionsTranslations) {
                            questionsTranslations.forEach(item => {
                                if (item.translations) {
                                    item.translations.forEach(translation => {
                                        axios.post(`${BASE_URL}/admin/poll/${translation.poll_id}/question/${translation.poll_question_id}/translations`, {
                                            "language_id": translation.language_id,
                                            "question": translation.question
                                        }, {
                                            headers: {
                                                'accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                            },
                                        }).then(res => {

                                        }).catch(err => {
                                            console.log(err)
                                        })
                                    })
                                }
                            })
                        }
                        if (choicesTranslations) {
                            choicesTranslations.forEach(item => {
                                if (item.translations)
                                    item.translations.forEach(translation => {
                                        axios.post(`${BASE_URL}/admin/poll/${translation.poll_id}/question/${translation.poll_question_id}/choice/${translation.poll_question_choice_id}/translations`, {
                                            "language_id": translation.language_id,
                                            "choice": translation.choice
                                        }, {
                                            headers: {
                                                'accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                                            },
                                        }).then(res => {
                                            console.log(res)
                                        }).catch(err => {
                                            console.log(err)
                                        })
                                    })
                            })
                        }
                    }).catch(err => {
                        // console.log(err)
                    })
                })
            }).catch(err => {
                // console.log(err)
            })
        }
    }

    function handleQuestionDelete(question) {
        axios.delete(`${BASE_URL}/admin/poll/${editPollId.editPollId}/question/${question}`, {
            headers: {
                "Accept": "*/*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            setShowEditedSuccessfully(true)
            setTimeout(() => {
                setShowEditedSuccessfully(false)
            }, 2000)
        })
    }

    const titleTranslationHandler = (e, lang) => {
        let tempt = []
        tempt = {...titleTranslations}
        tempt[lang.id] = e.target.value
        setTitleTranslations(tempt)
    }

    return (
        <>
            <div className={`add-user d-block`}>
                <div>
                    <form onSubmit={e => e.preventDefault()}>

                        <div>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <span>{`فارسی:`}</span>
                            </div>
                            <label>
                                <input type="text" name={`title`} value={title}
                                       onChange={(e) => setTitle(e.target.value)}
                                       onFocus={() => setFocusedOnTitle(true)}
                                />
                            </label>
                            {langs.map(lang => {
                                if (lang.id !== "6533c65a7b8ccffa90a73450") {
                                    return (
                                        <label key={lang.id}>
                                            {lang.name}: <input value={titleTranslations[lang.id]} type="text"
                                                                onChange={(e) => {
                                                                    titleTranslationHandler(e, lang)
                                                                }}/>
                                        </label>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            Birthdate:
                            <Select isClearable={true} options={birthdateYear.map(item => ({value: item, label: item}))}
                                    styles={selectStyle}
                                    onChange={(e) => setBirthdate({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    value={birthdate}/>
                        </div>
                        <div>
                            Experience:
                            <Select styles={selectStyle} isClearable={true}
                                    onChange={(e) => setExp({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })} options={
                                [...expYears, {label: 'Over 30 years', value: 'over_30_years'}]
                            } value={exp}/>
                        </div>
                        <div>
                            Job:
                            <Select styles={selectStyle}
                                    onInputChange={handleJobsInputChange}

                                    onChange={(e) => setJobId({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    isClearable={true}
                                    options={
                                        jobsList.map((item) => {
                                            return ({
                                                value: item.id, label: item.title
                                            })
                                        })
                                    } value={jobId}/>

                        </div>
                        <div>
                            Gender:
                            <Select isClearable={true}
                                    options={[{label: 'Male', value: 'MALE'}, {
                                        label: 'Female',
                                        value: 'FEMALE'
                                    }]}
                                    onChange={e => setGender({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    value={gender} styles={selectStyle}/>
                        </div>
                        <div>
                            Marital Status: <Select isClearable={true}
                                                    options={[{label: 'Single', value: 'SINGLE'}, {
                                                        label: 'Married',
                                                        value: 'MARRIED'
                                                    }, {label: 'Divorced', value: 'DIVORCED'}, {
                                                        label: 'Widowed',
                                                        value: 'WIDOWED'
                                                    }]}
                                                    onChange={e => setMaritalStatus({
                                                        value: e === null ? e : e.value,
                                                        label: e === null ? e : e.label
                                                    })}
                                                    value={maritalStatus} styles={selectStyle}/>
                        </div>
                        <div style={{gridRow: 'span 3', gap: '12px', display: 'flex', flexDirection: 'column'}}>
                            <div>
                                Country:
                                <Select isClearable={true}
                                        onChange={(e) => setCountryId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={countries.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={countryId}/>
                            </div>
                            <div>
                                State:
                                <Select className={(states && states.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => setStateId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={states.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={stateId}/>
                            </div>
                            <div>
                                City:
                                <Select className={(cities && cities.length) ? '' : 'select-disabled'}
                                        isClearable={true}
                                        onChange={(e) => setCityId({
                                            value: e === null ? e : e.value,
                                            label: e === null ? e : e.label
                                        })}
                                        options={cities.map(item => ({
                                            value: item.id, label: item.name
                                        }))}
                                        styles={selectStyle} value={cityId}/>
                            </div>
                        </div>
                        {/*<label>*/}
                        {/*    Description:*/}
                        {/*    <textarea name="description" onChange={(e) => setDesc(e.target.value)} value={desc}/>*/}
                        {/*</label>*/}
                        <div>
                            Poll Category:
                            <Select isDisabled={true} isClearable={true}
                                    onChange={(e) => setPollCategoryID({
                                        value: e === null ? e : e.value,
                                        label: e === null ? e : e.label
                                    })}
                                    options={pollCategories.map(item => ({
                                        value: item.id, label: item.name
                                    }))}
                                    styles={selectStyle} value={pollCategoryId}/>
                        </div>
                        <div>
                            Degree:
                            <Select isClearable={true}
                                    onChange={(e) => setDegree(e)}
                                    options={degrees}
                                    styles={selectStyle} value={degree}/>
                        </div>
                        <label>
                            Reward:
                            <input type="text" name={`reward`} onChange={(e) => setReward(parseFloat(e.target.value))}
                                   value={reward}/>
                        </label>
                        <div className={`d-flex`}>
                            <label className={`d-flex align-items-center justify-content-center flex-row`}>
                                <input type="checkbox" checked={activePoll} value={activePoll} onChange={(e) => {
                                    setActivePoll(prevState => !prevState)
                                }}
                                       style={{margin: 0, height: '13px', marginRight: '10px'}}/> <span>Is Active</span>
                            </label>
                            <label className={`d-flex align-items-center justify-content-center flex-row`}>
                                <input type="checkbox" checked={closedPoll} value={closedPoll} onChange={(e) => {
                                    setClosedPoll(prevState => !prevState)
                                }}
                                       style={{margin: 0, height: '13px', marginRight: '10px', marginLeft: '20px'}}/>
                                <span>Is Closed</span>
                            </label>
                        </div>
                        {/*<button className={`add-poll__button`} type={`submit`} onClick={() => addPoll()}>*/}
                        {/*    Edit Poll*/}
                        {/*</button>*/}
                    </form>
                    <button className={`add-poll__button`} style={{width: '50%', minWidth: '170px', marginTop: '1rem'}}
                            type={"submit"}
                            onClick={submitQueHandler}
                            disabled={sending}>Submit
                    </button>
                </div>
            </div>
            <div style={{gridColumn: `1/13`}}>
                {forms.length ? <h2 style={{marginTop: '4rem'}}>Questions</h2> : null}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="forms-container" type={`forms`}>
                        {(provided) => (
                            <ul className="forms" {...provided.droppableProps} ref={provided.innerRef}>
                                {forms.map(({
                                                id,
                                                formId,
                                                question,
                                                order,
                                                inputs,
                                                scoring_factor,
                                                required,
                                                translations,
                                                type
                                            }, index) => {

                                    let convertLetters = (letter) => {
// Convert to lowercase and split by space
                                        let words = letter.toLowerCase().split('_');

// Capitalize each word
                                        for (let i = 0; i < words.length; i++) {
                                            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                                        }

// Join the words back into a string
                                        return words.join(' ')
                                    }
                                    let currentType
                                    if (type) {
                                        currentType = {
                                            value: type,
                                            label: convertLetters(type)
                                        }
                                    } else {
                                        currentType = ''
                                    }
                                    let currentInputType
                                    if (inputs && inputs[0] && inputs[0].type)
                                        currentInputType = {
                                            value: inputs[0].type,
                                            label: convertLetters(inputs[0].type)
                                        }
                                    else
                                        currentInputType = ''
                                    return (
                                        <div key={id}>
                                            <Draggable draggableId={id} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div style={{
                                                            padding: '14px 0 13px',
                                                            borderBottom: '1px solid #555'
                                                        }} className={`d-flex align-items-center`}>
                                                            <div
                                                                className={'d-flex align-items-center flex-column w-100'}>
                                                                <div style={{gap: '0.45rem'}}
                                                                     className={'d-flex align-items-center w-100'}>
                                                            <span {...provided.dragHandleProps}><img
                                                                className={`drag-icon`}
                                                                src={dragIcon}
                                                                alt=""/></span>
                                                                    <span
                                                                        className={`form-que-order`}>{order}</span>
                                                                    {containsHtmlTags(question) ?
                                                                        <div className={`question-container`}
                                                                             dangerouslySetInnerHTML={{__html: question}}></div>
                                                                        : <span>{question}</span>}
                                                                    <div style={{marginLeft: 'auto'}}
                                                                         className="d-flex align-items-center gap-2">
                                                                        <Question type={type} formId={formId}
                                                                                  langs={langs}
                                                                                  newQuestionType={newQuestionType}
                                                                                  newInputType={newInputsType}
                                                                                  allChoicesType={allChoicesType}
                                                                                  setShowEditedSuccessfully={setShowEditedSuccessfully}
                                                                                  handleuestionInEdit={setCurrentQuestionInEdit}
                                                                                  handleInputsInEdit={setCurrentInputsQuestion}
                                                                                  currentQuestionChoices={currentQuestionInEdit}
                                                                                  currentQuestionChoicesTranslations={currentQuestionChoicesTranslations}
                                                                                  questionTitleTranslation={questionTitleTranslation}
                                                                                  editPollId={editPollId}
                                                                                  forms={forms}
                                                                                  setforms={setForms}
                                                                                  question={{
                                                                                      id,
                                                                                      formId,
                                                                                      question,
                                                                                      order,
                                                                                      translations,
                                                                                      inputs,
                                                                                      scoring_factor,
                                                                                      required
                                                                                  }}>
                                                                            <div style={{marginBottom: '2rem'}}
                                                                                 className={'w-100'}>
                                                                                <div>
                                                                                    <label
                                                                                        className={'d-flex align-items-center w-100'}>
                                                                        <span style={{
                                                                            display: 'inline-block',
                                                                            width: '20px'
                                                                        }}>Fa:</span> <input type="text"
                                                                                             value={question}
                                                                                             placeholder={`Question`}
                                                                                             onChange={(e) => queHandler(e, index)}
                                                                                             style={{marginLeft: '10px'}}/>

                                                                                    </label>
                                                                                    {
                                                                                        formId ? (langs.map((lang, indx) => {
                                                                                            if (lang.id !== "6533c65a7b8ccffa90a73450") {
                                                                                                //         if (questionTitleTranslation) {
                                                                                                //             return questionTitleTranslation.map(qtt => {
                                                                                                //                 if (qtt.id === lang.id) {
                                                                                                //                     return (
                                                                                                //                         <label
                                                                                                //                             key={`${lang.id}-${indx}`}
                                                                                                //                             className={'d-flex align-items-center w-100 mt-2'}>
                                                                                                // <span style={{
                                                                                                //     display: 'inline-block',
                                                                                                //     width: '20px'
                                                                                                // }}>{lang.code}:</span>
                                                                                                //                             {/*translations inputs*/}
                                                                                                //                             <input
                                                                                                //                                 style={{marginLeft: '10px'}}
                                                                                                //                                 type="text"
                                                                                                //                                 placeholder={lang.name}
                                                                                                //                                 value={(translations[lang.id] !== undefined) ? translations[lang.id] : ""}
                                                                                                //                                 // value={questionTitleTranslation.find(item => item.id === lang.id)?.question || ""}
                                                                                                //                                 onChange={(e) => {
                                                                                                //                                     questionsTranslationHandler(e, lang.id, formId)
                                                                                                //                                     // setQuestionTitleTranslation(
                                                                                                //                                     //     questionTitleTranslation.map(item =>
                                                                                                //                                     //         item.id === lang.id ? {
                                                                                                //                                     //             ...item,
                                                                                                //                                     //             question: e.target.value
                                                                                                //                                     //         } : item
                                                                                                //                                     //     )
                                                                                                //                                     // );
                                                                                                //                                 }}
                                                                                                //                             />
                                                                                                //
                                                                                                //                         </label>
                                                                                                //                     )
                                                                                                //                 }
                                                                                                //             })
                                                                                                //         }
                                                                                                if (lang.id !== '6533c65a7b8ccffa90a73450') {
                                                                                                    if (translations) {
                                                                                                        return (
                                                                                                            <label
                                                                                                                key={formId + lang.id + index}>
                                                                                                                <input
                                                                                                                    placeholder={lang.name}
                                                                                                                    onChange={(e) => questionsTranslationHandler(e, lang.id, formId)}
                                                                                                                    type="text"
                                                                                                                    value={translations[lang.id]}
                                                                                                                />
                                                                                                            </label>
                                                                                                        )
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })) : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <label style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                marginLeft: `48px`,
                                                                                marginBottom: '10px'
                                                                            }}><input
                                                                                onChange={(e) => requiredHandler(e, index)}
                                                                                type="checkbox"
                                                                                checked={required}
                                                                                style={{
                                                                                    width: '16px',
                                                                                    marginRight: '10px'
                                                                                }}/> Required</label>
                                                                            <div className={`forms__selects-container`}>
                                                                                {/*<input type="number" placeholder={`Scorring Factor`}*/}
                                                                                {/*       value={scoring_factor}*/}
                                                                                {/*       onChange={(e) => scoringFactorHandler(e, index)}*/}
                                                                                {/*       style={{*/}
                                                                                {/*           marginBottom: `20px`,*/}
                                                                                {/*           maxWidth: `150px`*/}
                                                                                {/*       }}/>*/}

                                                                                <div>
                                                                                    <span>Question Type:</span>

                                                                                            <Select
                                                                                                isDisabled={(newInputsType.value === 'INPUT')}
                                                                                                options={[{
                                                                                                    value: 'SINGLE_CHOICE',
                                                                                                    label: 'Single Choice'
                                                                                                }, {
                                                                                                    value: 'MULTI_CHOICE',
                                                                                                    label: 'Multi Choice'
                                                                                                }]}
                                                                                                styles={selectStyle}
                                                                                                onChange={e => setNewQuestionType(e)}
                                                                                                defaultValue={currentType}
                                                                                            />

                                                                                </div>
                                                                                <div>
                                                                                    <span>Inputs Type:</span>
                                                                                    {
                                                                                        //inputType
                                                                                    }
                                                                                    <Select options={[{
                                                                                        value: 'SELECT',
                                                                                        label: 'Select'
                                                                                    }, {
                                                                                        value: 'INPUT',
                                                                                        label: 'Input'
                                                                                    }
                                                                                        , {
                                                                                            value: 'RATE',
                                                                                            label: 'Rate'
                                                                                        }]} styles={selectStyle}
                                                                                            onChange={e => setNewInputsType(e)}
                                                                                            defaultValue={currentInputType}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {inputs && inputs[0] && inputs[0].type === 'SELECT' && !newInputsType || newInputsType && newInputsType.value === 'SELECT' || inputs && inputs[0] && inputs[0].type === 'RATE' && !newInputsType || newInputsType && newInputsType.value === 'RATE' ?
                                                                                <>
                                                                                    <Droppable
                                                                                        droppableId={`inputs-${order}`}
                                                                                        type={`inputs-${order}`}>
                                                                                        {(provided) => {
                                                                                            return (
                                                                                                <ul className={`inputs inputs-${id}`} {...provided.droppableProps}
                                                                                                    ref={provided.innerRef}>
                                                                                                    {
                                                                                                        inputs.map(({
                                                                                                                        inputValue,
                                                                                                                        inputId,
                                                                                                                        rate,
                                                                                                                        inputEn,
                                                                                                                        id,
                                                                                                                        translations
                                                                                                                    }, inputIndex) => {
                                                                                                            return (
                                                                                                                <Draggable
                                                                                                                    key={inputId}
                                                                                                                    draggableId={`input-${inputId}`}
                                                                                                                    index={inputIndex}>
                                                                                                                    {(provided) => {
                                                                                                                        return (
                                                                                                                            <li ref={provided.innerRef} {...provided.draggableProps}
                                                                                                                                className={`d-flex align-items-start`}>
                                                                                                                                <span {...provided.dragHandleProps}><img
                                                                                                                                    className={`drag-icon`}
                                                                                                                                    src={dragIcon}
                                                                                                                                    alt=""/></span>
                                                                                                                                <div
                                                                                                                                    className={'d-flex flex-column w-100'}>
                                                                                                                                    <div
                                                                                                                                        className={'d-flex w-100 flex-column'}>
                                                                                                                                        <div
                                                                                                                                            className={'d-flex'}>
                                                                                                                                            <input
                                                                                                                                                id={inputId}
                                                                                                                                                type="text"
                                                                                                                                                value={inputValue}
                                                                                                                                                placeholder={`Choice ${inputIndex + 1}`}
                                                                                                                                                onChange={(e) => formInputHandler(e, index, inputIndex)} // Pass the correct form index and input index
                                                                                                                                            />
                                                                                                                                            <input
                                                                                                                                                type="text"
                                                                                                                                                value={rate}
                                                                                                                                                placeholder={`Rate`}
                                                                                                                                                onChange={(e) => formInputRateHandler(e, index, inputIndex)}
                                                                                                                                                style={{
                                                                                                                                                    maxWidth: `60px`,
                                                                                                                                                    marginLeft: `10px`
                                                                                                                                                }}/>
                                                                                                                                        </div>
                                                                                                                                        {/*{currentQuestionChoicesTranslations ? currentQuestionChoicesTranslations.map((choiceTranslation, indx) => {*/}
                                                                                                                                        {/*        if (id === choiceTranslation.id) {*/}
                                                                                                                                        {/*            return choiceTranslation.translations.map((cht) => {*/}
                                                                                                                                        {/*                return (*/}
                                                                                                                                        {/*                    <label*/}
                                                                                                                                        {/*                        key={`${id}-${cht.id}-${indx}`} // Ensure 'id' and 'indx' are defined and relevant in your component*/}
                                                                                                                                        {/*                        className={'d-flex align-items-center w-100 mt-2'}>*/}
                                                                                                                                        {/*              <span*/}
                                                                                                                                        {/*                  style={{*/}
                                                                                                                                        {/*                      display: 'inline-block',*/}
                                                                                                                                        {/*                      width: '20px'*/}
                                                                                                                                        {/*                  }}>{langs.find(lang => lang.id === cht.language_id)?.code}:</span> /!* Updated to language_id *!/*/}
                                                                                                                                        {/*                        <input*/}
                                                                                                                                        {/*                            style={{marginLeft: '10px'}}*/}
                                                                                                                                        {/*                            type="text"*/}
                                                                                                                                        {/*                            placeholder={cht.language_name} // This remains as it refers to the language's name*/}
                                                                                                                                        {/*                            value={cht.choice} // This refers to the choice text in the specific language*/}
                                                                                                                                        {/*                            onChange={(e) => {*/}
                                                                                                                                        {/*                                // Update state to reflect the change in the choice*/}
                                                                                                                                        {/*                                setCurrentQuestionChoicesTranslations(*/}
                                                                                                                                        {/*                                    currentQuestionChoicesTranslations.map(poll => {*/}
                                                                                                                                        {/*                                        console.log(poll)*/}
                                                                                                                                        {/*                                        // Check if this is the correct poll based on the id*/}
                                                                                                                                        {/*                                        if (poll.id === id) { // Assuming 'id' corresponds to poll_question_choice.id*/}
                                                                                                                                        {/*                                            return {*/}
                                                                                                                                        {/*                                                ...poll,*/}
                                                                                                                                        {/*                                                translations: poll.translations.map(translation => {*/}
                                                                                                                                        {/*                                                    // Check if this is the correct translation to update*/}
                                                                                                                                        {/*                                                    if (translation.id === cht.id) {*/}
                                                                                                                                        {/*                                                        return {*/}
                                                                                                                                        {/*                                                            ...translation,*/}
                                                                                                                                        {/*                                                            choice: e.target.value // Update the choice value*/}
                                                                                                                                        {/*                                                        };*/}
                                                                                                                                        {/*                                                    }*/}
                                                                                                                                        {/*                                                    return translation; // Return unmodified translation*/}
                                                                                                                                        {/*                                                })*/}
                                                                                                                                        {/*                                            };*/}
                                                                                                                                        {/*                                        }*/}
                                                                                                                                        {/*                                        return poll; // Return unmodified poll*/}
                                                                                                                                        {/*                                    })*/}
                                                                                                                                        {/*                                );*/}
                                                                                                                                        {/*                            }}*/}
                                                                                                                                        {/*                        />*/}
                                                                                                                                        {/*                    </label>*/}
                                                                                                                                        {/*                );*/}
                                                                                                                                        {/*            });*/}

                                                                                                                                        {/*        }*/}
                                                                                                                                        {/*    })*/}
                                                                                                                                        {/*    : null*/}
                                                                                                                                        {/*}*/}
                                                                                                                                        {
                                                                                                                                            langs.map(lang => {
                                                                                                                                                if (lang.id !== "6533c65a7b8ccffa90a73450") {
                                                                                                                                                    return (
                                                                                                                                                        <label
                                                                                                                                                            key={`${lang.id + id}`}>
                                                                                                                                                            {lang.name}
                                                                                                                                                            <input
                                                                                                                                                                type="text"
                                                                                                                                                                value={translations[lang.id]}
                                                                                                                                                                onChange={(e) => choicesTranslationHandler(e, formId, id, lang.id)}
                                                                                                                                                            />
                                                                                                                                                        </label>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <button>
                                                                                                                                    <img
                                                                                                                                        style={{
                                                                                                                                            width: '20px',
                                                                                                                                            filter: 'brightness(0) invert(1)'
                                                                                                                                        }}
                                                                                                                                        src={trashIcon}
                                                                                                                                        onClick={() => {
                                                                                                                                            deleteChoice(index, inputIndex);
                                                                                                                                            handleChoiceDelete(formId, id)
                                                                                                                                        }}
                                                                                                                                        alt=""/>
                                                                                                                                </button>
                                                                                                                            </li>
                                                                                                                        )
                                                                                                                            ;
                                                                                                                    }}
                                                                                                                </Draggable>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                    {provided.placeholder}
                                                                                                </ul>
                                                                                            )
                                                                                        }}
                                                                                    </Droppable>
                                                                                    <button
                                                                                        onClick={(e) => addChoiceHandler(e, index, {
                                                                                            inputs,
                                                                                            newInputsType
                                                                                        }, inputs, formId)}
                                                                                        className={`add-poll__button add-choice-button`}>
                                                                                        Add Choice
                                                                                    </button>
                                                                                </>
                                                                                : null}
                                                                        </Question>

                                                                        <button onClick={() => {
                                                                            deleteQue(index);
                                                                            handleQuestionDelete(formId)
                                                                        }}>
                                                                            <img style={{
                                                                                width: '20px',
                                                                                filter: 'brightness(0) invert(1)'

                                                                            }} src={trashIcon} alt=""/>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                {/*translates*/}


                                                            </div>

                                                        </div>

                                                    </li>
                                                )}
                                            </Draggable>
                                        </div>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )
                        }
                    </Droppable>
                </DragDropContext>
                <div className={'d-flex flex-column align-items-center'}>
                    <button className={`add-poll__button`} style={{width: '50%', minWidth: '170px'}} type={"submit"}
                            onClick={addPollHandler}>Add Question
                    </button>
                    {forms.length ?
                        <button className={`add-poll__button`} style={{width: '50%', minWidth: '170px'}} type={"submit"}
                                onClick={() => submitQueHandler()}
                                disabled={sending}>Save
                        </button>
                        : null}
                    {
                        (pollCategoryId === "64fc313df370ffab069369e6" && ratesData !== [] || pollCategoryId.value === "64fc313df370ffab069369e6" && ratesData !== []) ?
                            <div style={{marginTop: '1rem', marginBottom: '3rem'}} className={'rating-form w-100'}>
                                {ratesData.length ? <h2 style={{
                                    paddingBottom: '18px',
                                    borderBottom: '1px solid rgba(255, 255, 255, 0.7)'
                                }}>Rates</h2> : null}
                                {
                                    ratesData.map((item, i) => {
                                        return (
                                            <div key={i} style={{
                                                gap: '0.45rem',
                                                borderBottom: '1px solid #555',
                                                padding: '12px 0 10px'
                                            }}
                                                 className={'d-flex align-items-center justify-content-between w-100'}>
                                                <div>
                                                    <strong>{item.from}</strong>
                                                    <span> - </span>
                                                    <strong>{item.to}</strong>
                                                </div>
                                                <div className={'d-flex align-items-center'}
                                                     style={{marginLeft: 'auto'}}>
                                                    <Rate rate={item} submitQueHandler={submitQueHandler}>
                                                        <div key={i} style={{marginBottom: '60px'}}>
                                                            <div>
                                                                <label>From: <input type="text"
                                                                                    value={ratesData[i].from}
                                                                                    onChange={(e) => {
                                                                                        const newData = [...ratesData];
                                                                                        newData[i].from = +e.target.value;
                                                                                        setRatesData(newData)
                                                                                    }}/></label>
                                                            </div>
                                                            <div>
                                                                <label>To: <input type="text" value={ratesData[i].to}
                                                                                  onChange={(e) => {
                                                                                      const newData = [...ratesData];
                                                                                      newData[i].to = +e.target.value;
                                                                                      setRatesData(newData)
                                                                                  }}/></label>
                                                            </div>
                                                            <Editor
                                                                key={i}
                                                                placeholder={`Edit result ${i + 1}`}
                                                                initialValue={item.result}
                                                                onUpdate={(html) => handleEditorUpdate(html, i)}
                                                            />
                                                        </div>
                                                    </Rate>
                                                    <button>
                                                        <img
                                                            style={{
                                                                width: '20px',
                                                                filter: 'brightness(0) invert(1)'
                                                            }}
                                                            src={trashIcon}
                                                            onClick={() => {
                                                                deleteRateHandler(i)
                                                            }}
                                                            alt=""/>
                                                    </button>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                            : null
                    }
                    {pollCategoryId === "64fc313df370ffab069369e6" || pollCategoryId.value === "64fc313df370ffab069369e6" ?
                        <div style={{width: '100%'}}>
                            <button className={'add-poll__button'} onClick={addFieldHandler}>Add Rate</button>
                            {ratesData.length ?
                                <button className={`add-poll__button`} style={{width: '50%', minWidth: '170px'}}
                                        type={"submit"}
                                        onClick={() => submitQueHandler()}
                                        disabled={sending}>Save
                                </button>
                                : null}
                        </div>
                        : null}
                </div>
            </div>
            <div style={{zIndex: '99999'}} className={`success-alert ${showEditedSuccessfully ? '' : 'hide'}`}>
                Poll Edited Successfully!
            </div>
        </>
    );

};

export default Poll