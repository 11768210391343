import React, {useEffect, useState} from 'react';
import axios from "axios";
import BASE_URL from "../api/api";
import acceptIcon from '../assets/images/accept-icon.png'
import rejectIcon from '../assets/images/reject-icon.png'


const Document = ({document, handleVerifiedDocsNum, handleSetLightboxImg, handleOpenLightBox}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [doc, setDoc] = useState(null)
    const [getReason, setGetReason] = useState(false)
    const [rejectReason, setRejectReason] = useState('')
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)

    useEffect(() => {
        if (showAlertSuccess) {
            const timeout = setTimeout(() => {
                setShowAlertSuccess(false);
                setDoc(null)
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [showAlertSuccess]);
    useEffect(() => {
        const item = document

        const fetchCertificateMedia = async (userId, mediaId) => {
            try {
                const response = await axios.get(
                    `https://api.globalvalidity.app/admin/users/${userId}/media/${mediaId}`,
                    {
                        responseType: "arraybuffer",
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                );

                console.log('blob', response)

                if (!response || response.status === 404) {
                    handleVerifiedDocsNum()
                    return null;
                }

                const blob = new Blob([response.data], {type: "image/png"});
                const dataURL = URL.createObjectURL(blob);
                return dataURL;
            } catch (error) {
                handleVerifiedDocsNum()
                return null;
            }
        };
        const getDocument = async () => {
            try {
                const blob = await fetchCertificateMedia(item.user_id, item.user_profile_media_id);
                item.imageBlob = blob;
                // setWaitForReq(false)
                // Fetch the document type and update the caption
                const docTypeRes = await axios.get(`${BASE_URL}/entities/document-types/${item.document_type_id}`, {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
                    }
                });

                item.doc_type = docTypeRes.data.data.document_type.name;

                // Update the state with the new item
                if (item.is_verified) {
                    handleVerifiedDocsNum()
                } else {
                    setIsLoading(false)
                    setDoc(item);
                }

            } catch (err) {
                console.log(err)
            }
        }

        getDocument()
    }, [])

    function handleLightBox() {
        handleSetLightboxImg(doc)
        handleOpenLightBox(true)
    }

    const acceptDocHandler = () => {
        axios.patch(`${BASE_URL}/admin/users/${doc.user_id}/profile/document/${doc.id}`, {
            is_verified: true
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`,
            }
        }).then(res => {
            if (res.status === 200) {
                setShowAlertSuccess(true)
                handleVerifiedDocsNum()

                console.log('accepted')

            }
        })
    }


    const rejectDocHandler = () => {
        axios.patch(`${BASE_URL}/admin/users/${doc.user_id}/profile/document/${doc.id}`, {
            is_verified: false,
            reject_reason: rejectReason // Empty string
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('user-token')}`
            }
        }).then(res => {
            console.log(res)
            if (res.status === 200) {
                setShowAlertSuccess(true)
                // setDoc(null)
                handleVerifiedDocsNum()
            }
        })
    }

    if (!isLoading && doc && doc.imageBlob) {
        console.log(doc)
        return (
            <div key={doc.id} className={'col-12 col-sm-6 col-lg-3'}>
                <div style={{
                    // gridColumn: `${(index % 4) + 1}/${(index % 4) + 2}`,
                    marginBottom: '16px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    background: '#2c2d30'
                }}>
                    <div className={'hovered-img-container'}>
                        <div className={'main-img-container'}>
                            <img src={doc.imageBlob} alt="" style={{width: '100%'}}
                                 onClick={handleLightBox}/>
                        </div>
                        {/*<div className={'hovered-img'}>*/}
                        {/*    <img src={item.imageBlob} alt="" onClick={() => setShowImgPopup(item)}/>*/}
                        {/*</div>*/}
                    </div>
                    <span style={{
                        marginTop: '10px',
                        display: 'block',
                        textAlign: 'center'
                    }}>{doc.doc_type}</span>
                    <div className={`d-flex justify-content-around mt-2 mb-1`}>
                        <button onClick={() => {
                            setGetReason(true)
                        }}><img style={{width: '30px'}}
                                src={rejectIcon} alt=""/>
                        </button>
                        <button onClick={() => acceptDocHandler()}>
                            <img style={{width: '30px'}} src={acceptIcon} alt=""/>
                        </button>
                    </div>
                    {
                        getReason ? <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                background: '#2c2d30',
                                zIndex: 5,
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0 0 9px 0 black'
                            }}>
                            <span className={'d-block'}>Rejection Reason:</span>
                            <textarea name="" id="" cols="30" rows="10" className={'reject-text-area'}
                                      onChange={e => setRejectReason(e.target.value)}>

                        </textarea>
                            <button onClick={() => setGetReason(false)}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        transform: 'translate(30%, -30%)'
                                    }}>
                                <img src={rejectIcon} alt=""/>
                            </button>
                            <button className={'d-block add-poll__button mt-2 mb-0'} onClick={rejectDocHandler}>Done
                            </button>
                        </div> : null
                    }
                </div>
                <div className={`success-alert ${showAlertSuccess ? '' : 'hide'}`}>
                    Done!
                </div>
            </div>
        );
    }
};

export default Document;